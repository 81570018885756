import { useAreas } from 'components/api-hooks/use-areas';
import { useContents } from 'components/api-hooks/use-contents';
import Map from 'components/containers/MapWithPinsContainer';
import { useAlert } from 'components/custom/use-alert';
import { useComponents } from 'components/custom/use-components';
import { usePageLink } from 'components/custom/use-page-link';
import { usePostCarousel } from 'components/custom/use-post-carousel';
import { SearchModal } from 'components/molecules/SearchModal';
import { InfinityPostList } from 'components/organisms/InfinityPostList';
import { MyPage as TemplateMyPage } from 'components/Templates/MyPage';
import { Content } from 'models/Content';
import { UserTypes } from 'modules/actions/authenticated-user';
import { UserContexts } from 'modules/contexts';
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';

export const MyPage: React.FC<RouteComponentProps> = ({
  location,
}: RouteComponentProps) => {
  const {
    userState: { isLogin, user, error },
  } = useContext(UserContexts);
  const { getUrl, pushEnter } = usePageLink();
  const { displayErrorAlert } = useAlert();
  const [activeIndex, setActiveIndex] = useState<number>(0);
  const displaySwithingMethods = { activeIndex, handleClick: setActiveIndex };

  const {
    contentsState: { contents, status, hasMore },
    params,
    setParams,
    fetchMyContents,
    fetchMyFavoriteContents,
    resetRequest,
  } = useContents(undefined, 12);
  const {
    areasState: { areas },
  } = useAreas();
  const { createPostListWithComments } = useComponents();
  const { handleClickPost, createImageCarouselModal } = usePostCarousel();

  // locationに合わせfetchメソッド生成
  const fetch = useMemo(() => {
    if (/^\/profile\/favorite/.test(location.pathname)) {
      return fetchMyFavoriteContents;
    } else if (/^\/profile/.test(location.pathname)) {
      return fetchMyContents;
    } else {
      return fetchMyContents;
    }
  }, [location.pathname]);

  // 検索モーダル
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpenModal(true);
  };
  const handleClose = () => {
    setIsOpenModal(false);
  };

  const search = (value: string) => {
    const params = { query: value };
    setParams(params);
    fetch(params, 1);
  };

  const handleResetSearchValue = () => {
    search('');
  };

  const searchFormProps = {
    search,
    handleCancel: handleClose,
  };
  const searchModalProps = {
    searchFormProps: searchFormProps,
    isShow: isOpenModal,
    handleClose: handleClose,
  };
  //############

  const PostList = useMemo(() => {
    if (contents == null) {
      return <></>;
    }

    const PostList =
      contents.length != 0 ? (
        createPostListWithComments(contents, false, handleClickPost)
      ) : (
        <div>スナップが存在しません</div>
      );

    const InfinityPostListProps = {
      PostList,
      fetchMore: fetch,
      hasMore,
      status,
    };

    return <InfinityPostList {...InfinityPostListProps} />;
  }, [contents, status]);

  const _Map = useMemo(() => {
    if (areas == null || contents == null) {
      return <></>;
    }

    const handleMoveZoom = (range: string) => {};
    return (
      <Map
        areas={areas}
        contents={contents}
        handleMoveZoom={handleMoveZoom}
        handleClickMapPin={handleClickPost}
        isArea={false}
      />
    );
  }, [status]);

  // ユーザー設定画面へ遷移するボタン
  const onClickSetting = () => {
    window.location.href = getUrl(`setting`);
  };

  useEffect(() => {
    (async () => {
      resetRequest();
      fetch(undefined, 1);
    })();
  }, [location.pathname]);

  // if api error, show Error Alert
  // fetch only
  useEffect(() => {
    (async () => {
      if (error != null) {
        await displayErrorAlert(error);
        {
          /* resetRequest() */
        }
        return;
      }
    })();
  }, [error]);

  if (!isLogin) {
    pushEnter('/profile');
  }
  if (user == null) {
    return <></>;
  }

  const {
    nickname = '名無し',
    profile_image_url = '',
    content_count = 0,
  } = user as UserTypes;
  const Thumbnail = profile_image_url ? (
    <img src={profile_image_url} alt={'ユーザーサムネイル'} />
  ) : undefined;

  //Carousel
  const imageCarouselModal =
    contents != null
      ? createImageCarouselModal(contents.map((data: Content) => data.id))
      : null;

  return (
    <>
      <TemplateMyPage
        displaySwithingMethods={displaySwithingMethods}
        name={nickname}
        onClickSetting={onClickSetting}
        Thumbnail={Thumbnail}
        postNum={content_count}
        Map={_Map}
        PostList={PostList}
        handleClickFilter={handleOpen}
        handleResetSearchValue={handleResetSearchValue}
        searchValue={params?.query}
      />
      <SearchModal {...searchModalProps} />
      {imageCarouselModal}
    </>
  );
};
