import { ImageCarouselProps } from 'components/organisms/ImageCarousel';
import { ImageCarouselWithModal } from 'components/organisms/ImageCarouselWithModal';
import React from 'react';

import { useMediaQuery } from 'react-responsive';

export interface ImageCarouselContainerProps {
  isShow: boolean;
  handleClickClose: () => void;
  activeIndex: number;
  contentIdList: string[];
  isPostCard?: boolean;
}

export const ImageCarouselContainer: React.FC<ImageCarouselContainerProps> = ({
  isShow,
  handleClickClose,
  activeIndex,
  contentIdList,
  isPostCard = false,
}: ImageCarouselContainerProps) => {
  const isMinWidth576 = useMediaQuery({ query: '(min-width: 576px)' });
  const isMinWidth768 = useMediaQuery({ query: '(min-width: 768px)' });

  let size: ImageCarouselProps['size'] = 'sm';
  if (isMinWidth576) size = 'lg';
  if (isMinWidth768) size = 'xl';

  const imageCarouselProps = {
    size,
    activeIndex,
    contentIdList,
    isPostCard,
  };

  return (
    <ImageCarouselWithModal
      isShow={isShow}
      imageCarouselProps={imageCarouselProps}
      handleClickClose={handleClickClose}
    />
  );
};
