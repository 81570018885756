import { Icon } from 'components/atoms/Icon';
import { useAlert } from 'components/custom/use-alert';
import {
  AreaKeysProps,
  Bounds,
  Position,
  useLeafletUtils
} from 'components/custom/use-leaflet-utils';
import { usePageLink } from 'components/custom/use-page-link';
import { IconButton } from 'components/molecules/IconButton';
import { MapIcon } from 'components/molecules/MapIcon';
import { MapPin } from 'components/molecules/MapPin';
import { SnapIcon } from 'components/molecules/SnapIcon';
import Leaflet, { divIcon } from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { BiMinus as Minus, BiPlus as Plus } from 'react-icons/bi';
import {
  BsChevronDown as ChevronDown,
  BsChevronUp as ChevronUp,
  BsGeoAltFill as GeoAlt,
  BsGeoFill as MapPinIcon,
  BsPinMapFill as AreaIcon,
  BsSearch as SearchIcon
} from 'react-icons/bs';
import { IoHome as HomeIcon } from 'react-icons/io5';
import { MdAutorenew as NewIcon } from 'react-icons/md';
import { MapContainer, Marker, useMapEvents } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-markercluster';
import { useMediaQuery } from 'react-responsive';
import { scroller } from 'react-scroll';
import { geoLocation } from 'services/geoapi/geo-location';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';

import './style.css';

import 'mapbox-gl';
import 'mapbox-gl-leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';

import 'leaflet-gesture-handling/dist/leaflet-gesture-handling.css';
import 'leaflet/dist/leaflet.css';

Leaflet.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);
{
  /* import 'maplibre-gl/dist/maplibre-gl.css'; */
}
{
  /* import 'maplibre-gl' */
}

export interface Pin {
  id?: string;
  Img: JSX.Element;
  lat: number;
  lng: number;
}

export type MapWithPinsProps = {
  params: {
    area: AreaKeysProps;
    pins: Pin[];
  }[];
  bounds?: Bounds;
  center?: Position;
  showSnapButton?: boolean;
  noneAreaPins?: Pin[];
  isAreaLinks?: boolean;
  isScroll?: boolean;
  isArea?: boolean;
  current?: string;
  Thumbnail?: JSX.Element | SVGElement;
  handleClickMapPin?: (id: string) => void;
  handleClickMap?: (lat: number, lng: number) => void;
  handleClickSnap?: () => void;
  handleMoveZoom?: (range: string) => void;
};

interface MapStateProps {
  size: 's' | 'm' | 'l';
  zoom: number;
  bounds?: Leaflet.LatLngBounds;
  center?: Position;
  showIcons: boolean;
}

interface ControlProps {
  position: 'bottomleft' | 'bottomright' | 'topleft' | 'topright';
  children: React.ReactNode;
}

export const MapWithPins: React.FC<MapWithPinsProps> = ({
  params,
  bounds,
  center,
  noneAreaPins,
  isAreaLinks = false,
  isScroll = true,
  current,
  isArea = false,
  Thumbnail,
  handleClickMapPin,
  handleClickSnap,
  handleMoveZoom,
}: MapWithPinsProps) => {
  const cn = 'map-with-pins';
  const mapRef = useRef<Leaflet.Map | undefined>(undefined);
  const mapStyle = process.env.REACT_APP_MAP_STYLE || '';
  {
    /* const mapStyle = "https://tile.dev.diglee.social/a/basic.json" */
  }

  const isMinWidth576 = useMediaQuery({ query: '(min-width: 576px)' });
  const isMinWidth768 = useMediaQuery({ query: '(min-width: 768px)' });
  const isMinWidth1068 = useMediaQuery({ query: '(min-width: 1068px)' });

  const { pushPath, replaceQuery } = usePageLink();
  const {
    memoizedAreaLinkInfo,
    memoizedAreaMarkersInfo,
    iconCreateExFunction,
    createIconMapPin,
    getRange,
    buildBounds,
  } = useLeafletUtils();
  const { displayErrorAlert } = useAlert();

  {
    /* スクロールボタンの表示切り替え */
  }
  const [showToContentButton, setShowToContentButton] = useState(false);
  const [showToMapButton, setShowToMapButton] = useState(false);
  const [forcus, setForcus] = useState<null | Position>(null);

  const defaultBounds = buildBounds({
    northeast: { lat: 50.6412626, lng: 154.0031455 },
    southwest: { lat: 20.3585295, lng: 122.8554688 },
  });
  const initBounds = bounds != null ? buildBounds(bounds) : defaultBounds;

  const [mapState, setMapState] = useState<MapStateProps>({
    size: center ? 'l' : 's',
    zoom: center ? 18 : 5,
    bounds: initBounds,
    showIcons: true,
  });

  const setPosition = (position: Position) => {
    setForcus(position);
    const { lat, lng } = position;
    // positionのURLクエリを書き換え
    replaceQuery('position', `${lat},${lng}`);
  };

  //マップのセンターを移動させるメソッドをwindowに格納
  useEffect(() => {
    const setMapView = (center: Position) => {
      // ピンが表示されない場合があるので、タイミングをずらす
      window.setTimeout(
        () => mapRef.current != null && mapRef.current.setZoom(15),
        10
      );
      window.setTimeout(
        () => mapRef.current != null && mapRef.current.setView(center),
        500
      );
    };
    window.setMapView = setMapView;
  }, [mapRef.current]);

  //show page Scroll button
  useEffect(() => {
    if (isMinWidth1068) {
      return undefined;
    }

    const contentElm = document.getElementById('main-contents');
    const mapElm = document.getElementById('main-map');

    if (!isScroll || contentElm == null || mapElm == null) {
      return undefined;
    }

    const contentTop = contentElm.getBoundingClientRect().top;
    const mapButtom = mapElm.getBoundingClientRect().bottom;
    const scrollAction = () => {
      // 3.
      setShowToContentButton(
        window.scrollY < contentTop - window.innerHeight + 50
      );
      setShowToMapButton(mapButtom < window.scrollY + 200);
    };
    window.addEventListener('scroll', scrollAction, {
      capture: false,
      passive: true,
    });
    scrollAction();

    return () => {
      window.removeEventListener('scroll', scrollAction);
    };
  }, [isMinWidth1068]);

  //マップを現在位置に移動する。
  const setLocation = async () => {
    if (mapRef.current == null) {
      return;
    }
    const { position, error } = await geoLocation();
    if (error != null) {
      await displayErrorAlert(error);
      return;
    }

    if (position == null) {
      return;
    }
    setMapState({ ...mapState, center: position, zoom: 14 });
    console.log(position, 'geopos');
    mapRef.current.setZoom(14);
    mapRef.current.setView(position);

    setPosition(position);
  };

  const minZoom = 5;
  const maxZoom = 18;

  // boundsのpropsが無い時、centerを拡大表示する。
  // スナップの表示時
  const zoom = bounds ? undefined : center ? 18 : 5;

  // マップを移動、Zoomしたときのハンドラー
  const onZoomMove = (e: Leaflet.LeafletEvent) => {
    const target = e.target;
    const bounds = target.getBounds();
    const zoom = target.getZoom();
    const size = zoom < 10 ? 's' : zoom < 14 ? 'm' : 'l';
    console.log(zoom, 'zoom');
    setMapState({ ...mapState, bounds, size, zoom });
  };

  // マップの位置にスクロール
  const scrollTo = (to: string, offset: number) => {
    scroller.scrollTo(to, {
      duration: 0,
      delay: 0,
      smooth: 'easeInOutQuint',
      offset: offset,
    });
  };

  //Mapイベント
  const MapEvent = () => {
    const map = useMapEvents({
      click: (e) => {
        const { latlng } = e;
        setPosition(latlng);
      },
      zoomend: (e) => {
        console.log(e, 'onzoomend');
        onZoomMove(e);
      },
      moveend: (e) => {
        console.log(e, 'onmoveend');
        onZoomMove(e);
        const bounds = e.target.getBounds();
        const range = getRange(bounds);
        handleMoveZoom && handleMoveZoom(range);
      },
      load: (e) => {
        console.log(e, 'load');
        const bounds = e.target.getBounds();
        setMapState({ ...mapState, bounds });
      },
    });
    return null;
  };

  // 「北海道」のテキストピンを生成
  const AreaCluster = mapState.showIcons
    ? params.map((prop, i) => {
        const { area, pins } = prop;
        const linkInfos = isAreaLinks ? memoizedAreaLinkInfo.get(area) : null;
        return (
          // 各地域のリンクテキストを表示するMarkerコンポーネント
          linkInfos &&
          mapState.zoom === minZoom && (
            <Marker
              key={`area-info-${i}`}
              position={linkInfos.position}
              icon={divIcon({
                html: `<a href="${linkInfos.path}">${linkInfos.label}</a>`,
                className: `${cn}__label-icon`,
              })}
              eventHandlers={{
                click: () => {},
              }}
            />
          )
        );
      })
    : null;

  // マップピンを生成
  const MapCluster = useMemo(() => {
    return mapState.showIcons
      ? params.map((prop, i) => {
          const { area, pins } = prop;
          const areaInfos = memoizedAreaMarkersInfo.get(area);

          // エリア単位(北海道、東北...)でクラスターを構築
          return (
            <MarkerClusterGroup
              key={i}
              maxClusterRadius={(zoom: number) => (zoom < 9 ? 5 : 80)}
              showCoverageOnHover
              iconCreateFunction={(cluster: Leaflet.MarkerCluster) =>
                iconCreateExFunction(cluster)
              }
            >
              {pins.map(({ id, Img, lat, lng }, j) => {
                const comp = <MapPin size={mapState.size} Img={Img} />;
                const icon = createIconMapPin(comp, mapState.size);

                return (
                  <Marker
                    key={j}
                    position={{ lat, lng }}
                    icon={icon}
                    eventHandlers={{
                      click: () =>
                        id && handleClickMapPin && handleClickMapPin(id),
                    }}
                  />
                );
              })}
            </MarkerClusterGroup>
          );
        })
      : null;
  }, [params, mapState]);

  // 地域と紐付けられていない、マップピンを生成
  const NoneAreaMapCluster = useMemo(() => {
    return mapState.showIcons && noneAreaPins ? (
      <MarkerClusterGroup
        key={99}
        maxClusterRadius={(zoom: number) => (zoom < 9 ? 5 : 80)}
        showCoverageOnHover
        iconCreateFunction={(cluster: Leaflet.MarkerCluster) =>
          iconCreateExFunction(cluster)
        }
      >
        {noneAreaPins.map(({ id, Img, lat, lng }, j) => {
          const comp = <MapPin size={mapState.size} Img={Img} />;
          const icon = createIconMapPin(comp, mapState.size);

          return (
            <Marker
              key={j + 99}
              position={{ lat, lng }}
              icon={icon}
              eventHandlers={{
                click: () => id && handleClickMapPin && handleClickMapPin(id),
              }}
            />
          );
        })}
      </MarkerClusterGroup>
    ) : null;
  }, [params, mapState]);

  const ForcusPin = forcus ? <Marker position={forcus}></Marker> : null;

  // MapIcon
  const MapPinIconComp = (
    <MapIcon
      onClick={() => {
        const showIcons = !mapState.showIcons;
        setMapState({ ...mapState, showIcons });
      }}
      Icon={MapPinIcon}
      fill={'#FFF'}
      variant={mapState.showIcons ? 'hide' : 'main'}
      iconTooltipProps={
        isMinWidth576
          ? {
              id: '1',
              text: 'マップ上のピンを非表示にします',
              placement: 'right',
            }
          : undefined
      }
    />
  );
  const HomeIconComp = (
    <MapIcon
      onClick={() => {
        if (mapRef.current == null) {
          return;
        }
        mapRef.current.fitBounds(defaultBounds);
      }}
      Icon={HomeIcon}
      fill={'#FFF'}
      iconTooltipProps={
        isMinWidth576
          ? { id: '2', text: '地図全体を表示します', placement: 'right' }
          : undefined
      }
    />
  );
  const GeoIconComp = (
    <MapIcon
      onClick={() => {
        setLocation();
      }}
      Icon={GeoAlt}
      fill={'#FFF'}
      iconTooltipProps={
        isMinWidth576
          ? {
              id: '3',
              text: '現在地 (GPSを使用できない場合、表示位置が正確でない場合があります)',
              placement: 'right',
            }
          : undefined
      }
    />
  );

  const mapInfo = () => {
    const pathname = window.location.pathname;
    return (
      <>
        {current ? (
          <IconButton
            onClick={() => {
              if (mapRef.current == null) {
                return;
              }
              mapRef.current.fitBounds(initBounds);
            }}
            Icon={SearchIcon}
            text={current ? current : 'さがす'}
            variant={current ? 'map' : 'map-white'}
          />
        ) : null}
        <IconButton
          onClick={() => pushPath('')}
          Icon={NewIcon}
          text={'新着'}
          variant={pathname == '/' ? 'map' : 'map-white'}
        />
        <IconButton
          onClick={() => pushPath('area')}
          Icon={AreaIcon}
          text={'地域'}
          variant={pathname == '/area' ? 'map' : 'map-white'}
        />
      </>
    );
  };

  return (
    <div className={`${cn}`}>
      <MapContainer
        maxZoom={maxZoom}
        minZoom={5}
        zoom={zoom}
        bounds={initBounds}
        center={center}
        zoomControl={false}
        scrollWheelZoom={false}
        gestureHandling={true}
        whenCreated={(map: Leaflet.Map) => {
          mapRef.current = map;
          Leaflet.mapboxGL({
            attribution:
              '\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
            style: mapStyle,
          }).addTo(map);

          // マップ最大表示範囲を設定
          // map.setMaxBounds(defaultBounds);
          if (isArea && center) {
            setPosition(center);
          }
        }}
      >
        <MapEvent />
        {/* url='https://{s}.tile.dev.diglee.social/tiles/{z}/{x}/{y}.png' */}
        {ForcusPin}
        {MapCluster}
        {AreaCluster}
        {NoneAreaMapCluster}
      </MapContainer>
      <div className={`${cn}__control-btn ${cn}__control-btn--topright `}>
        <div className={`${cn}__zoom-icons`}>
          <div
            className={`${cn}__zoom-icon`}
            onClick={() => {
              if (mapRef.current == null) {
                return;
              }
              const currentZoom = mapRef.current.getZoom();
              const nextZoom = 15 < currentZoom ? 18 : currentZoom + 3;
              mapRef.current.setZoom(nextZoom);
            }}
          >
            <Icon Icon={Plus} fill={'#000'} />
          </div>
          <div
            className={`${cn}__zoom-icon`}
            onClick={() => {
              if (mapRef.current == null) {
                return;
              }
              const currentZoom = mapRef.current.getZoom();
              const nextZoom = currentZoom < 8 ? 5 : currentZoom - 3;
              mapRef.current.setZoom(nextZoom);
            }}
          >
            <Icon Icon={Minus} fill={'#000'} />
          </div>
        </div>
      </div>
      <div className={`${cn}__control-btn ${cn}__control-btn--topleft`}>
        {MapPinIconComp}
        {HomeIconComp}
        {GeoIconComp}
      </div>
      <div className={`${cn}__control-btn ${cn}__control-btn--top`}>
        {mapInfo()}
      </div>
      {showToMapButton && isScroll ? (
        <div className={`${cn}__scroll-top`}>
          <IconButton
            onClick={() => {
              window.scrollTo(0, 0);
            }}
            Icon={ChevronUp}
            text="マップ"
            variant="map-white"
          />
        </div>
      ) : null}
      {showToContentButton && isScroll ? (
        <div className={`${cn}__scroll-bottom`}>
          <IconButton
            onClick={() => {
              scrollTo('main-contents', -78);
            }}
            Icon={ChevronDown}
            text="スナップ一覧"
            variant="map-white"
          />
        </div>
      ) : null}
      <div className={`${cn}__post`}>
        <SnapIcon
          width={isMinWidth768 ? '120px' : '90px'}
          handleClick={handleClickSnap}
        />
      </div>
    </div>
  );
};
