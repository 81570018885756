import {
  ChakraProvider,
  FormControl,
  FormLabel,
  Input,
  InputGroup,
  InputLeftElement,
} from '@chakra-ui/react';
import { Button } from 'components/atoms/Button';
import React, { useState } from 'react';
import { Modal } from 'react-bootstrap';
import { BsSearch as Search } from 'react-icons/bs';
import './style.css';

export interface SearchFormProps {
  search?: (value: string) => void;
  handleCancel?: () => void;
}

export const SearchForm: React.FC<SearchFormProps> = ({
  handleCancel,
  search,
}: SearchFormProps) => {
  const cn = 'search-form';
  const [searchValue, setSearchValue] = useState('');

  const onClickSearch = () => {
    search && search(searchValue);
    handleCancel && handleCancel();
  };

  return (
    <div className={`${cn}`}>
      <ChakraProvider>
        <div className={`${cn}__controls`}>
          <FormControl>
            <FormLabel>キーワードで絞り込み</FormLabel>
            <InputGroup>
              <InputLeftElement
                pointerEvents="none"
                children={<Search color="gray.300" />}
              />
              <Input
                type="text"
                placeholder="キーワード"
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                onKeyPress={(e) => {
                  if (e.key == 'Enter') {
                    e.preventDefault();
                    onClickSearch();
                  }
                }}
              />
            </InputGroup>
          </FormControl>
        </div>
        <div className={`${cn}__btn-group`}>
          <Button onClick={onClickSearch}>絞り込む</Button>
        </div>
      </ChakraProvider>
    </div>
  );
};

export interface SearchModalProps {
  isShow?: boolean;
  searchFormProps: SearchFormProps;
  handleClose: () => void;
  size?: 's' | 'm';
}

export const SearchModal: React.FC<SearchModalProps> = ({
  searchFormProps,
  handleClose,
  isShow = false,
  size = 'm',
}: SearchModalProps) => {
  const cn = 'search-modal';

  return (
    <Modal
      centered
      show={isShow}
      className={`${cn} ${cn}--${size}`}
      onHide={() => {
        handleClose();
      }}
    >
      <Modal.Body className={`${cn}__body`}>
        <SearchForm {...searchFormProps} />
      </Modal.Body>
    </Modal>
  );
};
