import { Button } from 'components/atoms/Button';
import { SwitchingRouter } from 'components/atoms/SwitchingRouter';
import { Tag } from 'components/atoms/Tag';
import { IconButton } from 'components/molecules/IconButton';
import { UserProfile } from 'components/molecules/UserProfile';
import React from 'react';
import { BiFilterAlt as Filter } from 'react-icons/bi';
import { useRouteMatch } from 'react-router-dom';

import './style.css';

interface DisplaySwitchingMethods {
  activeIndex: number;
  handleClick?: (index: number) => void;
}

export interface MyPageProps {
  Map: JSX.Element;
  displaySwithingMethods: DisplaySwitchingMethods;
  PostList?: JSX.Element;
  Thumbnail?: JSX.Element | SVGElement;
  name: string;
  postNum?: number;
  onClickSetting?: () => void;
  searchValue?: string;
  handleClickFilter?: () => void;
  handleResetSearchValue?: () => void;
}

export const MyPage: React.FC<MyPageProps> = ({
  Map,
  PostList,
  Thumbnail,
  name,
  postNum = 0,
  onClickSetting,
  handleClickFilter,
  handleResetSearchValue,
  searchValue,
}: MyPageProps) => {
  let { path, url } = useRouteMatch();

  const cn = 'my-page';
  const links = [
    { to: `${url}`, text: '投稿' },
    { to: `${url}/favorite`, text: 'お気に入り' },
  ];

  return (
    <div className={`${cn} page`}>
      <div id="main-map" className={`${cn}__map page__map`}>
        {Map}
      </div>
      <div id="main-contents" className={`page__contents`}>
        <div className={`${cn}__user`}>
          <div className={`${cn}__user-profile`}>
            <UserProfile Thumbnail={Thumbnail} name={name} postNum={postNum} />
          </div>
          <div className={`${cn}__setting-btn`}>
            <Button
              variant="outline-main"
              onClick={() => {
                onClickSetting && onClickSetting();
              }}
            >
              アカウント設定
            </Button>
          </div>
        </div>
        <div className={`${cn}__display-switching`}>
          <SwitchingRouter links={links} />
        </div>
        <div className={`${cn}__filter`}>
          <div className={`${cn}__btn-group`}>
            <IconButton
              onClick={() => {
                handleClickFilter && handleClickFilter();
              }}
              Icon={Filter}
              text={'絞り込む'}
              variant="outline-gray"
            />
          </div>
          <div className={`${cn}__tag-group`}>
            {searchValue ? (
              <Tag text={searchValue} handleDelete={handleResetSearchValue} />
            ) : null}
          </div>
        </div>
        <div className={`${cn}__display-items`}>{PostList}</div>
      </div>
    </div>
  );
};
