import React from 'react';
import { KeywordLink } from 'components/atoms/KeywordLink';

import './style.css';

export interface PostProfileProps {
  width?: string | number;
  area: string;
  postNum: number;
  tags: {
    text: string;
    path: string;
  }[];
}

export const PostProfile: React.FC<PostProfileProps> = ({
  width = '100%',
  area,
  postNum,
  tags,
}: PostProfileProps) => {
  const cn = 'post-profile';
  return (
    <div className={cn} style={{ width }}>
      <div className={`${cn}__body`}>
        <p className={`${cn}__area`}>#{area}</p>
        <p className={`${cn}__post-num`}>投稿 {postNum}件</p>
        <div className={`${cn}__tag-filed`}>
          <p className={`${cn}__tag-title`}>関連するハッシュタグ</p>
          <ul className={`${cn}__tag-list`}>
            {tags.map((tag, i) => {
              const { text, path } = tag;
              return (
                <li key={i} className={`${cn}__tag-item`}>
                  <KeywordLink text={`#${text}`} path={path} />
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
