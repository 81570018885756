import { types } from './actionTypes';
import { ActionTypes, action, actionWithAuth } from './action';
import {
  ContentApiFactory,
  Configuration,
  RequestContent as _RequestContent,
} from 'services/api';
import { Content } from 'models/Content';

export type RequestContent = _RequestContent;

interface ApiFetchSuccess {
  type: typeof types.API_FETCH_SUCCESS;
  data?: Content;
}

interface ApiCreateSuccess {
  type: typeof types.API_CREATE_SUCCESS;
  data: Content;
}

interface ApiUpdateSuccess {
  type: typeof types.API_UPDATE_SUCCESS;
  data: Content;
}

interface ApiDeleteSuccess {
  type: typeof types.API_DELETE_SUCCESS;
  deletedId?: string;
}

interface Incriment {
  type: typeof types.LIKE_COUNT_INCRIMENT;
}

interface decriment {
  type: typeof types.LIKE_COUNT_DECRIMENT;
}

export type ContentActionTypes =
  | ActionTypes
  | ApiFetchSuccess
  | ApiCreateSuccess
  | ApiUpdateSuccess
  | ApiDeleteSuccess
  | Incriment
  | decriment;

// 投稿一覧を返す
export const getContent = async (
  contentId: string
): Promise<ContentActionTypes> => {
  const actionCallBack = async () => {
    const res = await ContentApiFactory().getContent(contentId);
    const data = res.data as Content;
    return { type: types.API_FETCH_SUCCESS, data };
  };

  return action<ContentActionTypes>(actionCallBack);
};

// 新規に投稿する(タグも付与)
// TODO 投稿またはアップロードどちらかに失敗した場合の処理
// Actionをそれぞれ分けた方が良さそう。
export const createContent = async (
  data: RequestContent,
  file: File
): Promise<ContentActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const res = await ContentApiFactory(
      configurations as Configuration
    ).createContent(data);
    const resContent = res.data as Content;
    const res2 = await ContentApiFactory(
      configurations as Configuration
    ).uploadContentImage(resContent.id, file);
    const resContent2 = res2.data as Content;

    return { type: types.API_CREATE_SUCCESS, data: resContent2 };
  };

  return actionWithAuth<ContentActionTypes>(actionCallBack);
};

// 新規に投稿する(タグも付与)
export const updateContent = async (
  contentId: string,
  data: RequestContent,
  file?: File
): Promise<ContentActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const res = await ContentApiFactory(
      configurations as Configuration
    ).updateContent(contentId, data);
    const resContent = res.data as Content;

    if (file == null) {
      return { type: types.API_UPDATE_SUCCESS, data: resContent };
    }

    const res2 = await ContentApiFactory(
      configurations as Configuration
    ).uploadContentImage(resContent.id, file);
    const resContent2 = res2.data as Content;

    return { type: types.API_UPDATE_SUCCESS, data: resContent2 };
  };

  return actionWithAuth<ContentActionTypes>(actionCallBack);
};

// 新規に投稿する(タグも付与)
export const deleteContent = async (
  contentId: string
): Promise<ContentActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const res = await ContentApiFactory(
      configurations as Configuration
    ).deleteContent(contentId);
    return { type: types.API_DELETE_SUCCESS };
  };

  return actionWithAuth<ContentActionTypes>(actionCallBack);
};

/* /1* 指定した投稿に対し、画像をアップロード（置き換え）する *1/ */
/* export const uploadContentImage = async (contentId:string ,file: File): Promise<ActionTypes> => { */
/*   const {configurations,error} = await getAuthConfigurations(); */
/*   const res =await ContentApiFactory(configurations as Configuration).uploadContentImage(contentId,file).catch((error) =>{ */
/*     throw new Error("アップロードに失敗しました") */
/*   }) */

/*   const data = res.data as Content */
/*   return { type: types.API_CONTENT_SUCCESS} */
/* } */

/* // 新規に投稿する(タグも付与) */
/* export const createContentNoneUpload = async (data: RequestContent, token: string): Promise<ActionTypes> => { */
/*   const {configurations,error} = await getAuthConfigurations(); */

/*   const _data = data as RequestContent */
/*   const options = { headers: { Authorization: token } } */
/*   await ContentApiFactory(configurations as Configuration).createContent(_data, options) */
/*   // await ContentApiFactory().createContent(_data) */
/*   return { type: types.API_CONTENT_SUCCESS } */
/* } */
