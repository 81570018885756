import { KeywordLink } from 'components/atoms/KeywordLink';
import { MainTitle } from 'components/atoms/MainTitle';
import { KeywordList } from 'components/molecules/KeywordList';
import React from 'react';

import './style.css';

export interface FooterProps {
  width?: string | number;
  height?: string | number;
}

export const Footer: React.FC<FooterProps> = ({
  width = '100%',
  height = 'auto',
}: FooterProps) => {
  const cn = 'footer';

  return (
    <footer className={cn} style={{ width, height }}>
      <div className={`${cn}__main`}>
        <div className={`${cn}__logo`}>
          <MainTitle
            alt="DigLee"
            path={window.location.origin}
            src="/diglee.png"
          />
        </div>
        <div className={`${cn}__body`}>
          <div className={`${cn}__category`}>
            <h3 className={`${cn}__title`}>DigLeeを楽しむ</h3>
            <div className={`${cn}__sub-category`}>
              <p>&gt;投稿</p>
              <KeywordList>
                <KeywordLink
                  text="スナップする"
                  path="/post/create"
                  isReactRouter={false}
                />
              </KeywordList>
            </div>
            {/* <div className={`${cn}__sub-category`}>
              <p>&gt;検索</p>
              <KeywordList>
                <KeywordLink text='マップから検索する' path='./' />
                <KeywordLink text='キーワードで検索する' path='./' />
              </KeywordList>
            </div> */}
          </div>
          <div className={`${cn}__category`}>
            <h3 className={`${cn}__title`}>ヘルプ&amp;ガイド</h3>
            <KeywordList>
              {/* <KeywordLink text='ご利用ガイド' path='./' />
              <KeywordLink text='Q&amp;A' path='./' /> */}
              <KeywordLink
                text="お問い合わせ"
                path="https://forms.gle/NSj2y8X1GyxepUgp6"
              />
            </KeywordList>{' '}
          </div>
          <div className={`${cn}__category`}>
            <h3 className={`${cn}__title`}>プライバシーと利用契約</h3>
            <KeywordList>
              <KeywordLink
                text="プライバシーポリシー"
                path="/privacy"
                isReactRouter={true}
              />
              <KeywordLink text="利用規約" path="/terms" isReactRouter={true} />
              <KeywordLink
                text="ガイドライン"
                path="/guidelines"
                isReactRouter={true}
              />
              {/* <KeywordLink text='特定商取引に基づく表記' path='./' isReactRouter={true}/> */}
              {/* <KeywordLink text='個人情報保護方針' path='/' isReactRouter={true}/> */}
              <KeywordLink
                text="運営団体"
                path="/company"
                isReactRouter={true}
              />
            </KeywordList>
          </div>
        </div>
        <p className={`${cn}__copyright`}>
          &copy;DigLee&nbsp;All&nbsp;Rights&nbsp;Reserved
        </p>
      </div>
    </footer>
  );
};
