import React from 'react';
import {
  Button as BsButton,
  ButtonProps as BsButtonProps,
} from 'react-bootstrap';

import './style.css';

export interface ButtonProps extends BsButtonProps {
  children: React.ReactNode;
  variant?: string;
  onClick?: () => void;
}

export const Button: React.FC<ButtonProps> = ({
  children,
  variant = 'main',
  type,
  onClick,
}: ButtonProps) => {
  return (
    <BsButton variant={variant} type={type} onClick={onClick}>
      {children}
    </BsButton>
  );
};
