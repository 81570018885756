import { ApiError } from 'models/ApiError';
import { ApiStatus } from 'models/ApiStatus';
import { types } from 'modules/actions/actionTypes';
import { SearchTypes, SearchActionTypes } from 'modules/actions/search';
import { Content, Bounds, Position } from 'models/Content';

export type SearchState = {
  contents: Content[] | null;
  bounds: Bounds | null;
  location: Position | null;
  status: ApiStatus;
  error?: ApiError | null;
};

export default (state: SearchState, action: SearchActionTypes): SearchState => {
  switch (action.type) {
    case types.API_START:
      return {
        ...state,
        status: 'loading',
      };
    case types.API_SEARCH_SUCCESS:
      //TODO 型 as SearchTypesとしてしまっているところがきになる。
      const { contents, bounds, location } = action.data as SearchTypes;
      return {
        ...state,
        contents: contents,
        bounds: bounds,
        location: location,
        status: 'completed',
      };

    case types.API_FETCH_SUCCESS:
      return {
        ...state,
        contents: action.data.contents,
        status: 'completed',
      };

    case types.REQUEST_RESET:
      return {
        contents: null,
        bounds: null,
        location: null,
        status: 'init',
        error: null,
      };

    case types.API_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.error,
      };
    default:
      return state;
  }
};
