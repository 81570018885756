import Compressor from 'compressorjs';
import { ApiError, ErrorType } from 'models/ApiError';

type MaxAreaOption = {
  usePromise: boolean;
  useWorker: boolean;
};

interface ICanvasSize {
  maxArea: (maxAreaOption: MaxAreaOption) => Promise<{ height: number }>;
}
const canvasSize = require('canvas-size').default as ICanvasSize;

//import canvasSize from 'canvas-size'

type UploadResult = {
  image?: { url: string; file: File };
  error?: ApiError;
};

export const useImageUpload = () => {
  const mimeType = ['image/jpeg', 'image/png', 'image/gif'];
  const maxSize = 30000000;
  const minPixel = 300;
  const compressPixel = 1920;

  const upload = async (file: File): Promise<UploadResult> => {
    const { type, size } = file;

    if (!mimeType.includes(type)) {
      return {
        error: new ApiError(UPLOAD_INVALID_EXTENSION),
      };
    }
    if (size > maxSize) {
      return {
        error: new ApiError(UPLOAD_LARGE_IMAGE),
      };
    }

    const canvasLimitation = await canvasSize.maxArea({
      usePromise: true,
      useWorker: true,
    });
    console.log(canvasLimitation.height, 'maxCanvasSize');

    // Compress
    // 最大canvas Sizeの計算
    // const maxImageSize = canvasLimitation.height / Math.sqrt(2);

    const normalizedResult = await getNormalizedFile(file, compressPixel)
      .then((file) => {
        return {
          result: file,
          error: null,
        };
      })
      .catch((e) => {
        // console.error('getNormalizedFile Error', e);
        return {
          error: new ApiError(UPLOAD_ILLEGAL_ERROR, e),
        };
      });

    if (normalizedResult.error != null) {
      return {
        error: normalizedResult.error,
      };
    }

    const url = URL.createObjectURL(normalizedResult.result);
    //幅をチェック
    const checkImageSizeResult = await checkImageSize(url)
      .then((isValid) => {
        return {
          isValid: isValid,
          error: null,
        };
      })
      .catch((e) => {
        // console.log('onload error', e);
        return {
          error: new ApiError(UPLOAD_ILLEGAL_ERROR, e),
        };
      });

    if (checkImageSizeResult.error != null) {
      return {
        error: checkImageSizeResult.error,
      };
    }

    if (!checkImageSizeResult.isValid) {
      return {
        error: new ApiError(UPLOAD_SMALL_IMAGE),
      };
    }

    //TODO asを使っている
    return { image: { url, file: normalizedResult.result as File } };
  };

  // 最小幅を超えているかを調べる
  const checkImageSize = async (url: string) => {
    return new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = () => {
        const result =
          minPixel <= img.naturalWidth && minPixel <= img.naturalHeight;
        {
          /* URL.revokeObjectURL(img.src); */
        }
        resolve(result);
      };
      img.onerror = (error) => {
        reject(error);
      };
      img.src = url;
    });
  };

  return { upload };
};

//getNormalizedFile Compressor
const getNormalizedFile = (file: File, size: number) => {
  return new Promise((resolve, reject) => {
    new Compressor(file, {
      maxWidth: size,
      maxHeight: size,
      success(normalizedFile) {
        resolve(normalizedFile);
      },
      error(error) {
        reject(error);
      },
    });
  });
};

const UPLOAD_INVALID_EXTENSION: ErrorType = {
  code: 'upload_invalid_extension',
  message: 'upload invalid extension',
  displayMessageCode: 'upload.errors.invalidExtension',
  level: 'info',
};

const UPLOAD_SMALL_IMAGE: ErrorType = {
  code: 'upload_small_image',
  message: 'upload small image',
  displayMessageCode: 'upload.errors.smallImage',
  level: 'info',
};

const UPLOAD_LARGE_IMAGE: ErrorType = {
  code: 'upload_large_image',
  message: 'upload large image',
  displayMessageCode: 'upload.errors.largeImage',
  level: 'info',
};

const UPLOAD_ILLEGAL_ERROR: ErrorType = {
  code: 'upload_illegal',
  message: 'upload illegal',
  displayMessageCode: 'upload.errors.illegal',
  level: 'fatal',
};
