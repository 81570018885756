/**
 * undefinedなレスポンスデータを除去
 * API経由で取得したデータ型をジェネリクスで抽象化
 * @param data API経由で取得したデータ ex [{id: ...}, {id: ...}]
 */
export const removeUndefined = <T>(data: (any | undefined)[]): T[] => {
  return data.reduce<T[]>((accum, current) => {
    if (!current?.id) return [...accum];
    accum.push(current.id);
    return [...accum];
  }, []);
};

//入力されたタグをバリデーションする。
//タグの重複を削除
//space カンマ,空白が含まれるタグは複数のタグに分割
export const validateTags = (tags: string[]): string[] => {
  let tmp = [];
  for (const tag of tags) {
    const tagSplited = tag.split(/[\s,　]+/);
    tmp.push(...tagSplited);
  }
  return Array.from(new Set(tmp));
};

export const mergeArrayUnique = <T>(arr: T[], arr2: T[]): T[] => {
  const arr3 = arr.concat(arr2);
  return Array.from(new Set(arr3));
};
export const getArrayDiff = <T>(arr: T[], arr2: T[]): T[] => {
  return arr.filter((v) => arr2.indexOf(v) == -1);
};
