import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';

import {
  ImageCarouselContainer,
  ImageCarouselContainerProps,
} from 'components/containers/ImageCarouselContainer';
import { AreaTypes } from 'modules/actions/areas';
import './style.css';

import { useComponents } from 'components/custom/use-components';

export interface TopProps {
  Map: JSX.Element;
  PostList: JSX.Element;
  areas: AreaTypes[];
  imageCarouselContainerProps?: ImageCarouselContainerProps;
}

export const Top: React.FC<TopProps> = ({
  Map,
  PostList,
  areas,
  imageCarouselContainerProps,
}: TopProps) => {
  const { createTags } = useComponents();

  const cn = 'top';
  return (
    <div className={cn}>
      <ChakraProvider>
        <div id="main-map" className={`${cn}__map page__map`}>
          {Map}
        </div>
        <div id="main-contents" className={`${cn}__tabs page__contents`}>
          <div className={`${cn}__post-list`}>{PostList}</div>
        </div>
        {imageCarouselContainerProps && (
          <ImageCarouselContainer {...imageCarouselContainerProps} />
        )}
      </ChakraProvider>
    </div>
  );
};
