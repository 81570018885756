import { ApiError } from 'models/ApiError';
import { ApiStatus } from 'models/ApiStatus';
import { AreaContents } from 'models/Content';
import { types } from 'modules/actions/actionTypes';
import { AreaActionTypes, AreasTypes } from 'modules/actions/areas';

export type AreasState = {
  areas: AreasTypes | null;
  areaContents: AreaContents[] | null;
  status: ApiStatus;
  error?: ApiError | null;
};

export default (state: AreasState, action: AreaActionTypes): AreasState => {
  switch (action.type) {
    case types.API_START:
      return {
        ...state,
        status: 'loading',
      };
    case types.API_FETCH_SUCCESS:
      return {
        ...state,
        areas: Object.assign([], state, action.data),
        areaContents: Object.assign([], state, action.areaContents),
        status: 'completed',
      };

    case types.API_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.error,
      };
    default:
      return state;
  }
};
