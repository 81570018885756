import React, { useState, useCallback } from 'react';
import { Modal } from 'react-bootstrap';
import { Button } from 'components/atoms/Button';
import Cropper from 'react-easy-crop';
import { getCroppedImg, getUrl, getRotatedImage } from './CropImage';
import { ChakraProvider } from '@chakra-ui/react';
import {
  Slider,
  SliderTrack,
  SliderFilledTrack,
  SliderThumb,
} from '@chakra-ui/react';
import './style.css';

export interface ImageCropperProps {
  image?: string;
  cropShape?: 'rect' | 'round';
  size?: 'm' | 's';
  handleCropImage?: (uploadImg: string, file: File) => void;
  handleClickCancel?: () => void;
}

interface Crop {
  x: number;
  y: number;
}
interface CropSize {
  width: number;
  height: number;
}
type CropArea = Crop & CropSize;

export const ImageCropper: React.FC<ImageCropperProps> = ({
  image,
  cropShape = 'rect',
  size = 'm',
  handleCropImage,
  handleClickCancel,
}: ImageCropperProps) => {
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [rotation, setRotation] = useState(0);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState<CropArea | null>(
    null
  );
  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const onClick = useCallback(async () => {
    if (croppedAreaPixels == null) {
      throw new Error('fail cropping');
    }

    try {
      const croppedImageFile = await getCroppedImg(
        image,
        croppedAreaPixels,
        rotation
      );

      const croppedImageUrl = getUrl(croppedImageFile);

      handleCropImage && handleCropImage(croppedImageUrl, croppedImageFile);
    } catch (e) {
      console.error(e);
    }
  }, [image, croppedAreaPixels]);

  const cn = 'crop';

  return (
    <div className={`${cn}`}>
      <ChakraProvider>
        <div className={`${cn}__container ${cn}__container--${size}`}>
          <Cropper
            image={image}
            crop={crop}
            zoom={zoom}
            rotation={rotation}
            aspect={1 / 1}
            cropShape={cropShape}
            onCropChange={setCrop}
            onRotationChange={setRotation}
            onZoomChange={setZoom}
            onCropComplete={onCropComplete}
          />
        </div>
        <div className={`${cn}__controls`}>
          <div className={`${cn}__slider`}>
            <span className={`${cn}__slider-label`}>ズーム</span>
            <span className={`${cn}__slider-slider`}>
              <Slider
                value={zoom}
                min={1}
                max={3}
                step={0.1}
                onChange={(zoom) => setZoom(zoom as number)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </span>
          </div>
          <div className={`${cn}__slider`}>
            <span className={`${cn}__slider-label`}>回転</span>
            <span className={`${cn}__slider-slider`}>
              <Slider
                value={rotation}
                min={0}
                max={360}
                step={1}
                onChange={(rotation) => setRotation(rotation as number)}
              >
                <SliderTrack>
                  <SliderFilledTrack />
                </SliderTrack>
                <SliderThumb />
              </Slider>
            </span>
          </div>
        </div>
        <div className={`${cn}__footer`}>
          <Button onClick={onClick}>トリミング決定</Button>
          <Button onClick={handleClickCancel} variant="outline-main">
            キャンセル
          </Button>
        </div>
      </ChakraProvider>
    </div>
  );
};

export interface CropModalProps {
  isShow?: boolean;
  imageCropperProps: ImageCropperProps;
  size?: 's' | 'm';
}

export const CropModal: React.FC<CropModalProps> = ({
  imageCropperProps,
  isShow = false,
  size = 'm',
}: CropModalProps) => {
  const cn = 'crop-modal';

  return (
    <Modal
      show={isShow}
      backdrop="static"
      keyboard={false}
      className={`${cn} ${cn}--${size}`}
    >
      <Modal.Body className={`${cn}__body`}>
        <ImageCropper {...imageCropperProps} />
      </Modal.Body>
    </Modal>
  );
};
