import React, { useState, useReducer, useEffect } from 'react';
import reducer, { ContentsState } from 'modules/reducers/contents';
import { types } from 'modules/actions/actionTypes';
import {
  getContents,
  getContentsByTag,
  getContentsByUser,
  getContentsByAuthenticatedUser,
  getFavoriteContentsByAuthenticatedUser as getFavoriteContents,
  Params,
} from 'modules/actions/contents';
import { Content } from 'models/Content';

import {
  resetRequest as _resetRequest,
  startRequest as _startRequest,
} from 'modules/actions/action';

//TODO ContentsをContentsとnullのモデルに分ける
export const useContents = (initParams?: Params, pageSize?: number) => {
  const [contentsState, dispatch] = useReducer(reducer, {
    contents: null,
    status: 'loading',
  } as ContentsState);

  const [paramsState, setParamsState] = useState<Params | undefined>(
    initParams
  );

  const fetch = async (params?: Params, page?: number, _pageSize?: number) => {
    const res = await getContents(params, 1, _pageSize);
    return res;
  };

  // 引数にパラメーターが指定された場合、指定されたパラメーターを元にリクエスト
  const fetchNoLoading = async (params?: Params) => {
    const res = await getContents(params, 1, pageSize);
    dispatch(res);
  };

  // 引数にパラメーターが指定された場合、指定されたパラメーターを元にリクエスト
  const fetchTagContents = async (
    tagId: string,
    params?: Params,
    page?: number
  ) => {
    startRequest();
    const res = await getContentsByTag(
      tagId,
      params || paramsState,
      page || contentsState.page,
      pageSize
    );
    res.type = types.API_FETCH_MORE_SUCCESS;
    dispatch(res);
  };

  // 引数にパラメーターが指定された場合、指定されたパラメーターを元にリクエスト
  const fetchTagContentsNoLoading = async (tagId: string, params?: Params) => {
    const res = await getContentsByTag(tagId, params, undefined, pageSize);
    dispatch(res);
  };

  // 引数にパラメーターが指定された場合、指定されたパラメーターを元にリクエスト
  const fetchMyContents = async (params?: Params, page?: number) => {
    startRequest();
    const res = await getContentsByAuthenticatedUser(
      params || paramsState,
      page || contentsState.page,
      pageSize
    );
    res.type = types.API_FETCH_MORE_SUCCESS;
    dispatch(res);
  };

  const fetchMyFavoriteContents = async (params?: Params, page?: number) => {
    startRequest();
    const res = await getFavoriteContents(
      params || paramsState,
      page || contentsState.page,
      pageSize
    );
    res.type = types.API_FETCH_MORE_SUCCESS;
    dispatch(res);
  };

  const fetchUserContents = async (
    username: string,
    params?: Params,
    page?: number
  ) => {
    startRequest();
    const res = await getContentsByUser(
      username,
      params || paramsState,
      page || contentsState.page,
      pageSize
    );
    res.type = types.API_FETCH_MORE_SUCCESS;
    dispatch(res);
  };

  const startRequest = () => {
    dispatch(_startRequest());
  };

  const resetRequest = () => {
    dispatch(_resetRequest());
  };

  useEffect(() => {
    (async () => {
      // parameterが変わったときは、1ページ目からリクエスト
      resetRequest();
    })();
  }, [paramsState]);

  return {
    contentsState,
    params: paramsState,
    setParams: setParamsState,
    fetchNoLoading,
    fetchMyContents,
    fetchMyFavoriteContents,
    fetchTagContentsNoLoading,
    fetchTagContents,
    fetchUserContents,
    resetRequest,
  };
};
