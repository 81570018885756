import { Auth } from 'aws-amplify';
import { Configuration } from 'services/api/configuration';

interface ResultAuthConfigurations {
  configurations: Configuration;
  error: Error | null;
}
//  /user/* 以外のGETメソッドリクエストはトークンは必須ではない、POST系などの書き込みが発生するリクエストはトークン必須
export const getAuthConfigurations =
  async (): Promise<ResultAuthConfigurations> => {
    const { token, error } = await getToken();

    const baseOptions = {
      headers: {
        Authorization: token,
      },
    };
    return { configurations: new Configuration({ baseOptions }), error };
  };

interface ResultGetToken {
  token: string;
  error: Error | null;
}

export const getToken = async (): Promise<ResultGetToken> => {
  try {
    const token = `Bearer ${(await Auth.currentSession())
      .getIdToken()
      .getJwtToken()}`;
    return { token, error: null };
  } catch (error) {
    return { token: '', error };
  }
};
/* export const getToken = async function() { */
/*   return await Auth.currentSession().catch(()=>{return null}) */
/* } */
