import React, { useState, useReducer, useEffect, useContext } from 'react';
import reducer, { UserState } from 'modules/reducers/authenticated-user';
import { getUser as _getUser, UserTypes } from 'modules/actions/user';
import {
  resetRequest as _resetRequest,
  startRequest as _startRequest,
} from 'modules/actions/action';

export const useUser = (initStatus: string = 'init') => {
  const [userState, dispatch] = useReducer(reducer, {
    user: null,
    status: initStatus,
  } as UserState);

  const getUser = async (username: string) => {
    startRequest();
    const res = await _getUser(username);
    dispatch(res);
  };

  const startRequest = () => {
    dispatch(_startRequest());
  };

  const resetRequest = () => {
    dispatch(_resetRequest());
  };

  return {
    userState,
    fetch: getUser,
  };
};
