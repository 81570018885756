export const types = {
  API_START: 'API_START',
  API_SUCCESS: 'API_SUCCESS',
  API_CREATE_SUCCESS: 'API_CREATE_SUCCESS',
  API_UPDATE_SUCCESS: 'API_UPDATE_SUCCESS',
  API_DELETE_SUCCESS: 'API_DELETE_SUCCESS',
  API_ERROR: 'API_ERROR',
  API_NO_SESSION: 'API_NO_SESSION',
  API_FETCH_SUCCESS: 'API_FETCH_SUCCESS',
  API_FETCH_MORE_SUCCESS: 'API_FETCH_MORE_SUCCESS',
  API_SEARCH_SUCCESS: 'API_SEARCH_SUCCESS',
  API_CONTENTS_START: 'API_CONTENTS_START',
  API_CONTENTS_SUCCESS: 'API_CONTENTS_SUCCESS',
  API_CONTENTS_ERROR: 'API_CONTENTS_ERROR',
  API_CONTENT_START: 'API_CONTENT_START',
  API_CONTENT_SUCCESS: 'API_CONTENT_SUCCESS',
  API_CONTENT_ERROR: 'API_CONTENT_ERROR',
  API_MAP_SUCCESS: 'API_MAP_SUCCESS',
  LIKE_COUNT_INCRIMENT: 'LIKE_COUNT_INCRIMENT',
  LIKE_COUNT_DECRIMENT: 'LIKE_COUNT_DECRIMENT',
  API_LIKE_CONTENT: 'API_LIKE_CONTENT',
  API_UNLIKE_CONTENT: 'API_UNLIKE_CONTENT',
  API_CHECK_FAVORITE: 'API_CHECK_FAVORITE',
  API_ADD_FAVORITE: 'API_ADD_FAVORITE',
  API_TAKE_FAVORITE: 'API_TAKE_FAVORITE',
  API_COMMENT: 'API_COMMENT',
  API_CREATE_COMMENT_SUCCESS: 'API_CREATE_COMMENT_SUCCESS',
  API_DELETE_COMMENT_SUCCESS: 'API_DELETE_COMMENT_SUCCESS',
  API_COMMENTS: 'API_COMMENTS',
  API_TAGS: 'API_TAGS',
  API_TAG: 'API_TAG',
  API_AREAS_START: 'API_AREAS_START',
  API_AREAS_SUCCESS: 'API_AREAS_SUCCESS',
  API_CATEGORIES: 'API_CATEGORIES',
  API_USER_START: 'API_USER_START',
  API_LOGIN_SUCCESS: 'API_LOGIN_SUCCESS',
  API_LOGOUT_SUCCESS: 'API_LOGOUT_SUCCESS',
  API_USER_SUCCESS: 'API_USER_SUCCESS',
  REQUEST_RESET: 'REQUEST_RESET',
} as const;
