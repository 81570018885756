import { ApiError } from 'models/ApiError';
import { ApiStatus } from 'models/ApiStatus';
import { types } from 'modules/actions/actionTypes';
import {
  CommentTypes,
  CommentsTypes,
  CommentsActionTypes,
} from 'modules/actions/comments';

export type CommentsState = {
  comments: CommentsTypes;
  page: number;
  hasMore: boolean;
  status: ApiStatus;
  error?: ApiError | null;
};

export default (
  state: CommentsState,
  action: CommentsActionTypes
): CommentsState => {
  const currentComments = state.comments as CommentsTypes;
  switch (action.type) {
    case types.API_START:
      return {
        ...state,
        status: 'loading',
      };
    case types.API_FETCH_SUCCESS:
      const fetchComments = action.data as CommentsTypes;
      return {
        ...state,
        comments:
          currentComments != null
            ? currentComments.concat(fetchComments)
            : fetchComments,
        page: state.page + 1,
        hasMore: fetchComments != null && 20 == fetchComments.length,
        status: 'completed',
      };
    case types.REQUEST_RESET:
      return {
        ...state,
        comments: [] as CommentsTypes,
        page: 1,
        hasMore: true,
        error: null,
      };

    //created comment
    case types.API_CREATE_SUCCESS:
      const comment = action.data as CommentTypes;
      if (currentComments != null) {
        currentComments.unshift(comment);
      }
      return {
        ...state,
        comments: currentComments || ([comment] as CommentsTypes),
        status: 'completed',
      };

    //Deleted comment
    case types.API_DELETE_SUCCESS:
      const nextComments = currentComments.filter(
        (v) => v.id != action.deletedId
      );
      return {
        ...state,
        comments: nextComments,
        status: 'completed',
      };

    case types.API_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.error,
      };

    default:
      return state;
  }
};
