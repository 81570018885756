import * as Sentry from '@sentry/browser';
import { ApiError } from 'models/ApiError';

interface LoggerOption {
  isProducton: boolean;
}

/**
 * 構造化ロギング用データ
 * category - カテゴリ
 * message -  ロギングメッセージ
 * data - ロギングする追加オブジェクト
 */
interface StructLogData {
  category: string;
  message: string;
  data?: any;
}

/**
 * Logger
 * console.infoをlogger.infoでラッピング。
 * @example
 *
 * 構造化ロギングする
 * ```
 * Logger.structInfo({
 *  category: 'content',
 *  message: `Action: ${action.type}`,
 *  data: action.data,
 * );
 * ```
 */
export class Logger {
  private constructor() {}

  private static isProduction: boolean;

  static setOption(option: LoggerOption) {
    this.isProduction = option.isProducton;
  }

  static debug(...args: any[]): void {
    if (this.isProduction) {
      return;
    }
    console.debug(args);
  }

  static info(...args: any[]): void {
    console.info(args);
  }

  static warn(...args: any[]): void {
    console.warn(args);
  }

  static error(...args: any[]): void {
    console.error(args);
  }

  static initialize() {}

  /**
   * 構造化ロギング
   * @param data - StructLogData
   */
  static structInfo(data: StructLogData): void {
    //BreadCrumbsインターフェース
    //https://develop.sentry.dev/sdk/event-payloads/breadcrumbs/
    Sentry.addBreadcrumb({ ...data, level: Sentry.Severity.Info });
  }

  static sendError(error: ApiError): void {
    const scope = {
      level: error.level as Sentry.Severity,
      tags: {
        'error.code': error.code,
        'error.displayMessageCode': error.displayMessageCode,
      },
    };

    if (error.error != null) {
      // 本来のエラーを送信する。
      Sentry.captureException(error.error, scope);
    } else {
      Sentry.captureException(error, scope);
    }
  }

  static sendMessage(message: string): void {
    //https://getsentry.github.io/sentry-javascript/modules/minimal.html#capturemessage
    Sentry.captureMessage(message);
  }
}

export default Logger;
