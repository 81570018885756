import React from 'react';
import { MarkDownPage } from 'components/Templates/MarkDownPage';

const markdown = `
## 趣旨  
本指針は利用者の投稿を通して、その場所の紹介を目的としたサービスです。ピンポイントの場所を紹介することにより、地域の活性化を目指し本サービスを運営しています。  
  
  
## 基本方針  
情報通信技術の発達、PC・携帯型デバイスの普及、SNSの普及により個人が情報を発信することが日常的になりつつあります。  
こうした中、各地方自治体もインターネット上での発信をしています。様々なSNS発信により各自治体が個としてその土地その土地の魅力や情報を発信し、サービスや広報活動としています。  
現状のSNSはすでに興味を持った個人が情報収集するには最適ですが、新規の不特定多数に興味を持ってもらう事には不向きです。  
「個人の発信から個人を見る」のではなく「個人の発信から場所を見る」、「常に更新される人の情報」ではなく「常に更新される場所の情報」を提供する場として当サービスは設立されました。  
  
  
## 許可されている本ウェブサイトの使用法  
個人およびビジネス目的で本ウェブサイトを利用することが出来ます。  
記事の観覧は本ウェブサイトへの登録なしで出来ます。記事の投稿及びコメントは本ウェブサイトへの登録後可能です。  
本ウェブサイトへの投稿は投稿者が著作権及びその他の所有権などを所持している必要があります。そのうえで他人のプライバシーを侵害したり、社会モラルに反する行為、アダルトコンテンツ、何らかの犯罪に関わる行為などの投稿をすることはできません。それら行為や投稿が発覚した場合は投稿者の許可なく記事やコメントの削除やアカウント停止などの処置がおこなわれる場合があります。  
  
  
## 免責事項  
本ウェブサイトのサービスに関するすべてのリスクはお客様にあります。  
本ウェブサイト関係者は、明示黙示を問わず、法定の保証責任を含めいかなる責任も負いません。  
本ウェブサイトの利用を通じて起こった問題は、本フェブサイト関係者は保証いたしません。また本ウェブサイト関係者は原因の如何を問わず、以下の損害に対して一切の責任を負いません。  
(A)本ウェブサイトの利用、非利用、利用の結果など、本ウェブサイトに関連して発生する犯罪や問題、プライバシー侵害や開示などの損害。(B)本ウェブサイトの利用、非利用、利用の結果など、本ウェブサイトに関連して発生する損害。  
  
  
## 本ウェブサイトの変更  
予告なしにいつでも本ウェブサイトを変更、中断、終了することができます。  
本ウェブサイト運営団体は予告なしにいつでも本ウェブサイトのプライバシーポリシー、利用規約を変更することが出来ますが、利用者の不利益が考えられる場合にのみ合意及び通知するものとします。   
`;

export const GuideLinesPage: React.FC = () => {
  return <MarkDownPage title={'ガイドライン'} markdown={markdown} />;
};
