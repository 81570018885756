import { usePageLink } from 'components/custom/use-page-link';

import {
  AreaContentsProps,
  useComponents,
} from 'components/custom/use-components';
import { Bounds, Position } from 'components/custom/use-leaflet-utils';
import { useTimer } from 'components/custom/use-timer';
import { MapWithPins } from 'components/organisms/MapWithPins';
import { Content } from 'models/Content';
import { AreasTypes } from 'modules/actions/areas';

export interface MapWithPinsContainerProps {
  handleMoveZoom?: (range: string) => void;
  areas: AreasTypes;
  isAreaLinks?: boolean;
  isArea?: boolean;
  current?: string;
  Thumbnail?: JSX.Element | SVGElement;
  bounds?: Bounds;
  contents: Content[];
  center?: Position;
  handleClickMapPin?: (id: string) => void;
}

export default (props: MapWithPinsContainerProps) => {
  const { areas, contents, handleClickMapPin, handleMoveZoom } = props;
  const { createMapPins, buildPin } = useComponents();
  const { execCallback, clear } = useTimer(1000);

  const { pushPath } = usePageLink();

  if (!contents || !areas) return <></>;

  // コンテンツをエリア単位で管理できるようにデータを加工
  const areaContentsProps: AreaContentsProps[] = areas.map((area) => {
    // id 親tagid
    const { id } = area;
    const data = contents.reduce<Content[]>((accum, current) => {
      const tmp = current.area;
      if (tmp == null) return [...accum];
      if (tmp.id === id) accum.push(current);
      return [...accum];
    }, []);
    return { area, data };
  });

  // TODO refactoring 後々データの再設計をする
  const noneAreaContents = contents.filter((content) => {
    return content.area == null || content.area.id == null;
  });

  const params = createMapPins(areaContentsProps);

  const noneAreaPins = noneAreaContents.map((content) => {
    return buildPin(content);
  });

  const _handleMoveZoom = (range: string) => {
    handleMoveZoom && execCallback(() => handleMoveZoom(range));
  };

  const _handleClickMapPin = (id: string) => {
    //投稿表示中に投稿リストが読み込まれないようにする。
    clear();
    handleClickMapPin && handleClickMapPin(id);
  };

  const handleClickSnap = () => {
    const query = new URLSearchParams(window.location.search);
    const position = query.get('position') || undefined;
    const positionQuery = position != null ? `?position=${position}` : '';
    pushPath(`post/create${positionQuery}`);
  };

  return (
    <MapWithPins
      {...props}
      params={params}
      noneAreaPins={noneAreaPins}
      showSnapButton={true}
      handleClickMapPin={_handleClickMapPin}
      handleClickSnap={handleClickSnap}
      handleMoveZoom={_handleMoveZoom}
    />
  );
};
