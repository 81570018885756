import React from 'react';
import { CounterCircle } from 'components/atoms/CounterCircle';

import './style.css';

export interface MapPinProps {
  Img: JSX.Element;
  size?: 'l' | 'm' | 's';
  counterVal?: number;
}

export const MapPin: React.FC<MapPinProps> = ({
  Img,
  size = 's',
  counterVal = 1,
}: MapPinProps) => {
  const cn = 'map-pin';
  return (
    <div className={`${cn} ${cn}--${size}`}>
      {Img}
      <div className={`${cn}__counter ${cn}__counter--${size}`}>
        <CounterCircle val={counterVal} variant="main" />
      </div>
    </div>
  );
};
