import { types } from './actionTypes';
import { ActionTypes, action, actionWithAuth } from './action';

import { CommentApiFactory, Comment, RequestComment } from 'services/api/api';
import { Configuration } from 'services/api/configuration';

export type CommentTypes = Comment;
export type CommentsTypes = CommentTypes[];
export type RequestCommentTypes = RequestComment;

interface ApiFetchSuccess {
  type: typeof types.API_FETCH_SUCCESS;
  data?: CommentsTypes;
}

interface ApiCreateSuccess {
  type: typeof types.API_CREATE_SUCCESS;
  data: CommentTypes;
}

interface ApiDeleteSuccess {
  type: typeof types.API_DELETE_SUCCESS;
  deletedId?: string;
}

export type CommentsActionTypes =
  | ActionTypes
  | ApiFetchSuccess
  | ApiCreateSuccess
  | ApiDeleteSuccess;

//指定した投稿に付けられたコメント一覧を取得
export const getCommentsByContent = async (
  contentId: string
): Promise<CommentsActionTypes> => {
  const actionCallBack = async () => {
    const res = await CommentApiFactory().getCommentsByContent(contentId);
    const data = res.data as CommentsTypes;
    return { type: types.API_FETCH_SUCCESS, data };
  };

  return await action<CommentsActionTypes>(actionCallBack);
};

export const createComment = async (
  contentId: string,
  data: RequestCommentTypes
): Promise<CommentsActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const _data = data as RequestComment;
    const res = await CommentApiFactory(
      configurations as Configuration
    ).createComment(contentId, _data);
    const resComment = res.data as CommentTypes;
    return { type: types.API_CREATE_SUCCESS, data: resComment };
  };

  return await actionWithAuth<CommentsActionTypes>(actionCallBack);
};

export const deleteComment = async (
  id: string
): Promise<CommentsActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const res = await CommentApiFactory(
      configurations as Configuration
    ).deleteComment(id);
    return { type: types.API_DELETE_SUCCESS, deletedId: id };
  };
  return await actionWithAuth<CommentsActionTypes>(actionCallBack);
};
