import React, { useState, useCallback, useMemo } from 'react';
import { Carousel } from 'react-bootstrap';
import {
  BsChevronLeft as ChevronLeft,
  BsChevronRight as ChevronRight,
} from 'react-icons/bs';
import { ImageList } from 'components/atoms/ImageList';
import { CommentCardWithImageContainer } from 'components/containers/CommentCardWithImageContainer';
import { useDataStructure } from 'components/custom/use-data-structure';
import { Content } from 'models/Content';

import './style.css';

export type GetCommentsByCarouselIndex = (contentId: string) => void;

export interface ImageCarouselProps {
  size?: 'sm' | 'lg' | 'xl';
  prevIcon?: JSX.Element;
  nextIcon?: JSX.Element;
  activeIndex?: number;
  contentIdList: string[];
  contents?: Content[];
  isPostCard?: boolean;
}

export const ImageCarousel: React.FC<ImageCarouselProps> = ({
  size,
  prevIcon,
  nextIcon,
  activeIndex = 0,
  contentIdList,
  isPostCard = false,
}: ImageCarouselProps) => {
  const [state, setState] = useState({
    // 活性化中のCarousel.Itemコンポーネントのインデックス
    carouselActiveIndex: activeIndex,
  });

  const onSlide = (targetIndex: number) => {
    setState({
      ...state,
      carouselActiveIndex: targetIndex,
    });
  };

  const cn = 'image-carousel';
  const iconSize = size == 'sm' ? 30 : 40;
  const _prevIcon = prevIcon || (
    <ChevronLeft className={`${cn}__icon ${cn}__icon--left`} size={iconSize} />
  );
  const _nextIcon = nextIcon || (
    <ChevronRight
      className={`${cn}__icon ${cn}__icon--right`}
      size={iconSize}
    />
  );
  const showPrevNextIcon = 1 < contentIdList.length;

  return (
    <div className={`${cn} ${cn}--${size}`}>
      <div className={`${cn}__outer`}>
        <div className={`${cn}__main`}>
          <Carousel
            fade
            defaultActiveIndex={activeIndex}
            activeIndex={state.carouselActiveIndex}
            interval={null}
            indicators={false}
            prevIcon={showPrevNextIcon ? _prevIcon : ''}
            nextIcon={showPrevNextIcon ? _nextIcon : ''}
            prevLabel={null}
            nextLabel={null}
            className={`${cn}__carousel`}
            touch={false}
            onSlide={onSlide}
            onSelect={onSlide}
          >
            {contentIdList.map((contentId, i) => {
              // ImageListコンポーネントのサイズと合わせる
              const isActive = i == state.carouselActiveIndex;
              return (
                <Carousel.Item key={i} className={`${cn}__item`}>
                  <CommentCardWithImageContainer
                    size={size}
                    contentId={contentId}
                    isActive={isActive}
                    isPostCard={isPostCard}
                  />
                </Carousel.Item>
              );
            })}
          </Carousel>
        </div>
        {/* <div className={`${cn}__indicators ${cn}__indicators--${size}`}> */}
        {/*   { */}
        {/*     data && */}
        {/*     <ImageList activeIndex={centerIndex}> */}
        {/*       {data} */}
        {/*     </ImageList> */}
        {/*   } */}
        {/* </div> */}
      </div>
    </div>
  );
};
