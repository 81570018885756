import React from 'react';
import { MarkDown } from 'components/atoms/MarkDown';
import './style.css';

export interface MarkDownPageProps {
  title: string;
  markdown: string;
}

export const MarkDownPage: React.FC<MarkDownPageProps> = ({
  title,
  markdown,
}: MarkDownPageProps) => {
  const cn = 'markdown-page';
  return (
    <section className={cn}>
      <div className={`${cn}__title`}>
        <h1>{title}</h1>
      </div>
      <div className={`${cn}__contents`}>
        <MarkDown {...{ markdown }} />
      </div>
    </section>
  );
};
