import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useLocation } from 'react-router-dom';

import { LoginPage as TemplateLoginPage } from 'components/Templates/LoginPage';
import { UserContexts } from 'modules/contexts';
import { usePageLink } from 'components/custom/use-page-link';

export const LoginPage: React.FC = () => {
  const { getUrl } = usePageLink();
  const {
    userState: { isLogin },
    login,
  } = useContext(UserContexts);

  const query = new URLSearchParams(useLocation().search);
  const redirectPath = query.get('redirect_to') || '';

  if (isLogin) {
    window.location.href = getUrl('');
  }

  const loginWithGoogle = () => {
    login(redirectPath);
  };
  const oauthButtonsProps = { loginWithGoogle };

  return <TemplateLoginPage oauthButtonsProps={oauthButtonsProps} />;
};
