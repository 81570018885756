import { ImageCarouselContainer } from 'components/containers/ImageCarouselContainer';
import { useState } from 'react';

export interface ImageCarouselState {
  isShow: boolean;
  activeContentId: string | null;
  isPostCard: boolean;
}

export const usePostCarousel = () => {
  //Carousel State
  const [modalState, setModalState] = useState<ImageCarouselState>({
    isShow: false,
    activeContentId: null,
    isPostCard: false,
  });

  //Carousel
  const handleClickPost = (id: string, isPostCard?: boolean) => {
    setModalState({
      isShow: true,
      activeContentId: id,
      isPostCard: !!isPostCard,
    });
  };

  const handleClickClose = () =>
    setModalState({ isShow: false, activeContentId: null, isPostCard: false });

  // content id listからImageCarouselModalを生成
  // モーダルを開く時、contentIDからcontentをAPIから取得する
  const createImageCarouselModal = (contentIdList: string[]) => {
    if (modalState.activeContentId == null) {
      return undefined;
    }

    const activeIndex = contentIdList.findIndex(
      (id) => id === modalState.activeContentId
    );

    const imageCarouselProps = {
      isShow: modalState.isShow,
      handleClickClose,
      activeIndex,
      contentIdList: contentIdList,
      isPostCard: modalState.isPostCard,
    };

    return <ImageCarouselContainer {...imageCarouselProps} />;
  };

  return { modalState, handleClickPost, createImageCarouselModal };
};
