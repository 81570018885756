import { Content } from 'models/Content';
import { Configuration, ContentApiFactory } from 'services/api';
import { action, ActionTypes, actionWithAuth } from './action';
import { types } from './actionTypes';

interface ApiFetchSuccess {
  type: typeof types.API_FETCH_SUCCESS;
  data: Content[];
}

interface ApiFetchMoreSuccess {
  type: typeof types.API_FETCH_MORE_SUCCESS;
  data: Content[];
}

export type ContentsActionTypes =
  | ActionTypes
  | ApiFetchSuccess
  | ApiFetchMoreSuccess;

export type Params = {
  range?: string;
  sort?: string;
  query?: string;
};

const defaultParams = {
  range: undefined,
  sort: undefined,
  query: undefined,
};

/**
 * 投稿一覧を返す
 * @param  tag string
 * @param  params Params
 * @param  page number
 * @returns  Promise<ContentsActionTypes>
 * @remarks
 * paramsが渡されない場合、デフォルト値が適用される
 */
export const getContents = async (
  params: Params = defaultParams,
  page?: number,
  pageSize?: number
): Promise<ContentsActionTypes> => {
  const actionCallBack = async () => {
    const { range, sort, query } = params;
    const _pageSize = pageSize ? String(pageSize) : undefined;
    const res = await ContentApiFactory().getContents(
      range,
      sort,
      query,
      page,
      _pageSize
    );
    const data = res.data as Content[];

    return { type: types.API_FETCH_SUCCESS, data };
  };

  return action<ContentsActionTypes>(actionCallBack);
};

/**
 * 指定したユーザーが投稿した投稿一覧を返す
 * @param  tag string
 * @param  params Params
 * @param  page number
 * @returns  Promise<ContentsActionTypes>
 * @remarks
 * paramsが渡されない場合、デフォルト値が適用される
 */
export const getContentsByUser = async (
  userId: string,
  params: Params = defaultParams,
  page?: number,
  pageSize?: number
): Promise<ContentsActionTypes> => {
  const actionCallBack = async () => {
    const { range, sort, query } = params;
    const res = await ContentApiFactory().getContentsByUser(
      userId,
      undefined,
      sort,
      query,
      page,
      pageSize
    );
    const data = res.data as Content[];
    return { type: types.API_FETCH_SUCCESS, data };
  };

  return action<ContentsActionTypes>(actionCallBack);
};

/**
 * 指定したタグが付与された投稿一覧を返す
 * @param  tag string
 * @param  params Params
 * @returns  Promise<ContentsActionTypes>
 * @remarks
 * paramsが渡されない場合、デフォルト値が適用される
 */
export const getContentsByTag = async (
  tag: string,
  params: Params = defaultParams,
  page?: number,
  pageSize?: number
): Promise<ContentsActionTypes> => {
  const actionCallBack = async () => {
    const { range, sort, query } = params;
    const res = await ContentApiFactory().getContentsByTag(
      tag,
      undefined,
      query,
      page,
      pageSize,
      range
    );
    const data = res.data as Content[];
    return { type: types.API_FETCH_SUCCESS, data };
  };

  return action<ContentsActionTypes>(actionCallBack);
};

/**
 * 指定したAreaが付与された投稿一覧を返す
 * @param  tag string
 * @param  params Params
 * @returns  Promise<ContentsActionTypes>
 * @remarks
 * paramsが渡されない場合、デフォルト値が適用される
 */
export const getContentsByArea = async (
  areaId: string,
  params: Params = defaultParams,
  page?: number
): Promise<ContentsActionTypes> => {
  const actionCallBack = async () => {
    const { range, sort, query } = params;
    const res = await ContentApiFactory().getContentsByArea(
      areaId,
      undefined,
      undefined,
      undefined,
      8
    );
    const data = res.data as Content[];
    return { type: types.API_FETCH_SUCCESS, data };
  };

  return action<ContentsActionTypes>(actionCallBack);
};

/**
 * 認証済みユーザーが投稿した投稿一覧を返す
 * @param  tag string
 * @param  params Params
 * @returns  Promise<ContentsActionTypes>
 * @remarks
 * 認証トークン付与
 * paramsが渡されない場合、デフォルト値が適用される
 */
export const getContentsByAuthenticatedUser = async (
  params: Params = defaultParams,
  page?: number,
  pageSize?: number
): Promise<ContentsActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const { range, sort, query } = params;
    const res = await ContentApiFactory(
      configurations as Configuration
    ).getContentsByAuthenticatedUser(range, sort, query, page, pageSize);
    const data = res.data as Content[];
    return { type: types.API_FETCH_SUCCESS, data };
  };

  return actionWithAuth<ContentsActionTypes>(actionCallBack);
};

/**
 * 認証済みユーザーが投稿したお気に入り投稿一覧を返す
 * @param  tag string
 * @param  params Params
 * @returns  Promise<ContentsActionTypes>
 * @remarks
 * 認証トークン付与
 * paramsが渡されない場合、デフォルト値が適用される
 */
export const getFavoriteContentsByAuthenticatedUser = async (
  params: Params = defaultParams,
  page?: number,
  pageSize?: number
): Promise<ContentsActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const { range, sort, query } = params;
    const res = await ContentApiFactory(
      configurations as Configuration
    ).getFavoriteContentsByAuthenticatedUser(
      undefined,
      undefined,
      sort,
      query,
      range,
      page,
      pageSize
    );
    const data = res.data as Content[];
    return { type: types.API_FETCH_SUCCESS, data };
  };

  return actionWithAuth<ContentsActionTypes>(actionCallBack);
};
