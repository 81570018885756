import { ChakraProvider } from '@chakra-ui/react';
import React from 'react';

import { AreaContents } from 'models/Content';
import './style.css';

import { useComponents } from 'components/custom/use-components';

export interface AreaProps {
  Map: JSX.Element;
  areaContents: AreaContents[];
  handleClickPost?: (contentId: string, isPostCard?: boolean) => void;
}

export const Area: React.FC<AreaProps> = ({
  Map,
  areaContents,
  handleClickPost,
}: AreaProps) => {
  const { createTags, createPostListWithComments } = useComponents();

  const cn = 'area';
  return (
    <div className={cn}>
      <ChakraProvider>
        <div id="main-map" className={`${cn}__map page__map`}>
          {Map}
        </div>
        <div id="main-contents" className={`${cn}__tabs page__contents`}>
          <div id="content-list" className={`${cn}__contents-list`}>
            {areaContents.map((d, i) => {
              // Tags、PostListWithCommentsコンポーネントの構築
              const { area, contents } = d;
              const Tags = createTags(area);
              const PostList = createPostListWithComments(
                contents,
                false,
                handleClickPost
              );
              return (
                <div key={i} className={`${cn}__contents-list-item`}>
                  <div className={`${cn}__tags`}>{Tags}</div>
                  <div className={`${cn}__post-list`}>{PostList}</div>
                </div>
              );
            })}
          </div>
        </div>
      </ChakraProvider>
    </div>
  );
};
