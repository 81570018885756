import React, { useState, useRef, useEffect } from 'react';
import GoogleButton from 'react-google-button';
import { usePageLink } from 'components/custom/use-page-link';
import './style.css';

export interface OAuthButtonsProps {
  loginWithGoogle?: () => void;
}

export const OAuthButtons: React.FC<OAuthButtonsProps> = ({
  loginWithGoogle,
}: OAuthButtonsProps) => {
  const cn = 'oauth-buttons';

  return (
    <div className={`${cn}__buttons`}>
      <GoogleButton
        type="light"
        onClick={() => {
          loginWithGoogle && loginWithGoogle();
        }}
        style={{ fontWeight: 900, textAlign: 'center' }}
      />
    </div>
  );
};
