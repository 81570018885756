import { KeywordLink } from 'components/atoms/KeywordLink';
import { UserThumbnail } from 'components/atoms/UserThumbnail';
import { KeywordList } from 'components/molecules/KeywordList';
import React from 'react';

import './style.css';

export interface PostingContentProps {
  id?: string;
  width?: string | number;
  Thumbnail?: JSX.Element | SVGElement;
  username?: string;
  nickname: string;
  title: string;
  keywordInfos: {
    text: string;
    path: string;
    variant?: string;
  }[];
  diffDay: number;
  handleClickUser?: () => void;
}

export const PostingContent: React.FC<PostingContentProps> = ({
  id,
  width = '100%',
  Thumbnail,
  username,
  nickname,
  title,
  keywordInfos,
  diffDay,
  handleClickUser,
}: PostingContentProps) => {
  const KeyWordItems = keywordInfos.map((info, i) => {
    const { text, path, variant } = info;
    return <KeywordLink key={i} text={text} path={path} variant={variant} />;
  });

  const cn = 'posting-content';
  const diffDayStr = diffDay > 0 ? diffDay + '日前' : '今日';
  const userLink = username || '';

  return (
    <div className={cn} style={{ width }}>
      <div className={`${cn}__thumbnail`}>
        <UserThumbnail
          Thumbnail={Thumbnail}
          handleClickUser={handleClickUser}
        />
      </div>
      <div className={`${cn}__body`}>
        <p className={`${cn}__name`}>
          <KeywordLink text={nickname} path={userLink} variant="black" />
        </p>
        <p className={`${cn}__title`}>{title}</p>
        <div className={`${cn}__keyword-list`}>
          <KeywordList>{KeyWordItems}</KeywordList>
        </div>
        <p className={`${cn}__posted-diff-day`}>{diffDayStr}</p>
      </div>
    </div>
  );
};
