import React, { useState } from 'react';
import { OAuthButtons, OAuthButtonsProps } from 'components/atoms/OAuthButtons';
import { MainTitle } from 'components/atoms/MainTitle';
import { KeywordLink } from 'components/atoms/KeywordLink';

import './style.css';

export interface LoginPageProps {
  oauthButtonsProps: OAuthButtonsProps;
}

export const LoginPage: React.FC<LoginPageProps> = ({
  oauthButtonsProps,
}: LoginPageProps) => {
  const cn = 'login-page';
  return (
    <section className={cn}>
      <div className={`${cn}__container`}>
        <div className={`${cn}__column ${cn}__column--left`}>
          <div className={`${cn}__inner`}>
            <div className={`${cn}__title`}>
              <MainTitle
                alt="DigLee"
                src="/diglee.png"
                path={'./'}
                isReactRouter={true}
              />
            </div>
            <div className={`${cn}__text-components`}>
              <p>
                利用者の投稿を通して、その場所の紹介を目的としたサービスです。
              </p>
            </div>
            <div className="">
              <KeywordLink
                text="詳しく見る"
                path="./guidelines"
                variant="sub"
                isReactRouter={true}
              />
            </div>
          </div>
        </div>

        <div className={`${cn}__column ${cn}__column--right`}>
          <div className={`${cn}__inner`}>
            <div className={`${cn}__title ${cn}__title--text`}>
              <h1 className={`${cn}__main`}>ログイン</h1>
            </div>
            <div className={`${cn}__text-components`}>
              <p>
                <KeywordLink
                  text="利用規約"
                  path="./terms"
                  variant="sub"
                  isReactRouter={true}
                />
                、
                <KeywordLink
                  text="プライバシーポリシー"
                  path="./privacy"
                  variant="sub"
                  isReactRouter={true}
                />
                に同意の上でログインしてください。
              </p>
            </div>
            <OAuthButtons {...oauthButtonsProps} />
          </div>
        </div>
      </div>
    </section>
  );
};
