import React from 'react';
import { IconType } from 'react-icons';

import './style.css';

export interface IconCounterProps {
  Icon: IconType;
  val?: number;
  fill?: string;
  handleClickCounter?: () => void;
}

export const IconCounter: React.FC<IconCounterProps> = ({
  Icon,
  val = 0,
  fill = '#000',
  handleClickCounter,
}: IconCounterProps) => {
  const cn = 'icon-counter';
  return (
    <div
      className={cn}
      onClick={(e: React.MouseEvent<HTMLInputElement>) => {
        handleClickCounter && handleClickCounter();
      }}
    >
      <div className={`${cn}__icon`}>
        <Icon fill={fill} />
      </div>
      <div className={`${cn}__count`}>{val}</div>
    </div>
  );
};
