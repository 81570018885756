import React from 'react';
import { IconCounter } from 'components/atoms/IconCounter';
import { Icon } from 'components/atoms/Icon';
import {
  BsHeart as Heart,
  BsHeartFill as HeartFill,
  BsStar as Star,
  BsStarFill as StarFill,
} from 'react-icons/bs';
import './style.css';

export interface SocialItemProps {
  counterVal: number;
  isLike?: boolean;
  isFavorite?: boolean;
  isActive?: boolean;
  handleClickLikeCounter?: () => void;
  handleClickFavoriteIcon?: () => void;
}

export const SocialItem: React.FC<SocialItemProps> = ({
  counterVal,
  isLike = false,
  isFavorite = false,
  isActive = true,
  handleClickLikeCounter,
  handleClickFavoriteIcon,
}: SocialItemProps) => {
  const cn = 'social-item';
  const disabled = isActive ? '' : `${cn}__icon--disabled`;

  return (
    <div className={cn}>
      <div
        className={`${cn}__icon ${disabled}`}
        onClick={handleClickLikeCounter}
      >
        {isLike ? (
          <IconCounter Icon={HeartFill} val={counterVal} fill="#e6779c" />
        ) : (
          <IconCounter Icon={Heart} val={counterVal} />
        )}
        <p className={`${cn}__icon-text ${cn}__icon-text--like`}>ここいいね</p>
      </div>
      <div
        className={`${cn}__icon ${disabled}`}
        onClick={handleClickFavoriteIcon}
      >
        {isFavorite ? (
          <Icon Icon={StarFill} fill="#ecea68" />
        ) : (
          <Icon Icon={Star} />
        )}
        <p className={`${cn}__icon-text ${cn}__icon-text--favorite`}>
          お気に入り
        </p>
      </div>
    </div>
  );
};
