import React from 'react';
import { Spinner, SpinnerProps } from 'react-bootstrap';

import './style.css';

export interface LoaderProps {
  variant?: string;
}

export const Loader: React.FC<LoaderProps> = ({
  variant = 'primary',
}: LoaderProps) => {
  const cn = 'loader';
  return (
    <div className={`${cn}`}>
      <Spinner animation="border" variant={variant} />
    </div>
  );
};
