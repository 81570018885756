import React, { useState, useReducer, useEffect, useContext } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Start as StartTemplate } from 'components/Templates/Start';
import { UserTypes } from 'modules/actions/authenticated-user';
import { UserContexts } from 'modules/contexts';
import { useAuthenticatedUser } from 'components/api-hooks/use-authenticated-user';
import { useAlert } from 'components/custom/use-alert';
import { useErrorMessage } from 'components/custom/use-error';
import { usePageLink } from 'components/custom/use-page-link';

export const Start: React.FC = () => {
  const history = useHistory();
  const { getUrl, pushEnter } = usePageLink();
  const { displaySuccessAlert } = useAlert();

  const {
    userState: { isLogin, user },
    setUser,
    logout,
  } = useContext(UserContexts);
  const {
    userState: { user: localUser, status, error },
    updateUser,
    uploadUserImage,
  } = useAuthenticatedUser();

  //show error
  useErrorMessage(error);

  const handleLogout = () => {
    logout();
  };

  // 保存成功後、ContextのUserを更新する
  useEffect(() => {
    (async () => {
      if (status == 'completed' && localUser != null) {
        await displaySuccessAlert('登録が完了しました');
        setUser(localUser);
        history.push(``);
      }
    })();
  }, [status]);

  const upload = async (file: File) => {
    uploadUserImage(file);
  };

  if (!isLogin) {
    pushEnter('/start');
  }

  if (user == null) {
    return <></>;
  }

  const {
    username = '',
    nickname = '名無し',
    profile_image_url = '',
  } = user as UserTypes;
  const values = { username, nickname, profileImageUrl: profile_image_url };

  const startFormProps = {
    submit: updateUser,
    values,
    isLoading: status == 'loading',
    upload,
    logout: handleLogout,
  };

  return <StartTemplate startFormProps={startFormProps} />;
};
