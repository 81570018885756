import React from 'react';

import './style.css';

export interface AnnotationProps {
  height?: string | number;
  text: string;
  variant?: 'danger' | 'main' | 'sub';
}

export const Annotation: React.FC<AnnotationProps> = ({
  height = 15,
  text,
  variant = 'danger',
}: AnnotationProps) => {
  const cn = 'annotation';
  return (
    <p className={`${cn} ${cn}--${variant}`} style={{ height }}>
      {text}
    </p>
  );
};
