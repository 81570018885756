import React from 'react';
import { IconType } from 'react-icons';

import './style.css';

export interface IconProps {
  Icon: IconType;
  handleClickIcon?: () => void;
  fill?: string;
  variant?: string;
}

export const Icon: React.FC<IconProps> = ({
  Icon,
  handleClickIcon,
  fill = '#000',
  variant = 'main',
}: IconProps) => {
  const cn = 'icon';
  return (
    <div
      className={cn}
      onClick={(e: React.MouseEvent<HTMLInputElement>) => {
        handleClickIcon && handleClickIcon();
      }}
    >
      <div className={`${cn}__icon ${cn}__icon--${variant}`}>
        <Icon fill={fill} />
      </div>
    </div>
  );
};
