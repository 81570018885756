import {
  PostingComment,
  PostingCommentProps
} from 'components/molecules/PostingComment';
import {
  PostingContent,
  PostingContentProps
} from 'components/molecules/PostingContent';
import {
  PostingUser,
  PostingUserProps
} from 'components/molecules/PostingUser';
import React, { useState } from 'react';
import { Card, Form } from 'react-bootstrap';
import { BsGeoAltFill as GeoAltIcon } from 'react-icons/bs';
import {
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';

import './style.css';

type Input = React.ChangeEvent<HTMLInputElement>;
export type SubmitComment = (contentId: string, comment: string) => void;

export interface CommentCardProps {
  width?: string | number;
  postingUserParam: PostingUserProps;
  postingContentParam: PostingContentProps;
  postingCommentParams: PostingCommentProps[];
  isLogin?: boolean;
  submitComment?: SubmitComment;
  postMapLink?: string;
}

export const CommentCard: React.FC<CommentCardProps> = ({
  width = '100%',
  postingUserParam,
  postingContentParam,
  postingCommentParams,
  isLogin = true,
  submitComment,
  postMapLink,
}: CommentCardProps) => {
  const cn = 'comment-card';

  const [stateValues, setStateValues] = useState({ comment: '' });

  const onClickSubmitComment = () => {
    if (!postingContentParam.id) return;
    submitComment && submitComment(postingContentParam.id, stateValues.comment);
    setStateValues({ ...stateValues, comment: '' });
  };

  // commentリストを作成
  const commentsComp = postingCommentParams.map((param, i) => (
    <li key={i} className={`${cn}__comment-item`}>
      <PostingComment {...param} />
    </li>
  ));
  const shareButtonSize = 30;

  // ハッシュを抜く。
  // 1丁目や番地のタグが含まれてしまっているのでFilter
  // 正規表現、全角数値を検索。GeoLocationで取得する住所が全角なため。
  const hashtags = postingContentParam.keywordInfos
    .map((param) => param['text'].slice(1))
    .filter((param) => param.search(/[０-９]/g) == -1);
  hashtags.unshift('DigLee');

  const shareTitle = postingContentParam.title;
  const url = postMapLink || window.location.origin;

  return (
    <Card className={cn} style={{ width }}>
      <Card.Header className={`${cn}__header`}>
        <PostingUser {...postingUserParam} isLogin={isLogin} />
      </Card.Header>
      <Card.Body className={`${cn}__body`}>
        <div className={`${cn}__btns`}>
          <a className={`${cn}__map-link`} href={postMapLink || ''}>
            <GeoAltIcon className={`${cn}__map-icon`} />
            <span className={`${cn}__map-label`}>マップで表示</span>
          </a>
          <TwitterShareButton url={url} title={shareTitle} hashtags={hashtags}>
            <TwitterIcon size={shareButtonSize} round />
          </TwitterShareButton>
          <LineShareButton
            url={url}
            title={shareTitle}
            className="Demo__some-network__share-button"
          >
            <LineIcon size={shareButtonSize} round />
          </LineShareButton>
        </div>
        <div className={`${cn}__content`}>
          <PostingContent {...postingContentParam} />
        </div>
        {postingCommentParams.length > 0 ? <hr /> : ''}
        <ul className={`${cn}__comment-list`}>{commentsComp}</ul>
      </Card.Body>
      <Card.Footer className={`${cn}__footer`}>
        <Form
          className={`${cn}__form`}
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          {isLogin && (
            <>
              <Form.Control
                type="text"
                className={`${cn}__input`}
                size="sm"
                value={stateValues.comment}
                onChange={(e: Input) => {
                  setStateValues({ ...stateValues, comment: e.target.value });
                }}
                placeholder="コメントを追加..."
              />
              <p onClick={onClickSubmitComment} className={`${cn}__post`}>
                投稿する
              </p>
            </>
          )}
        </Form>
      </Card.Footer>
    </Card>
  );
};
