import { KeywordLink } from 'components/atoms/KeywordLink';
import React from 'react';
import { BsThreeDots as ThreeDots } from 'react-icons/bs';
import './style.css';

export interface UserMenuProps {
  width?: string | number;
  username?: string;
  nickname: string;
  followLink: string;
  handleMenuBarClick?: () => void;
}

export const UserMenu: React.FC<UserMenuProps> = ({
  width = '100%',
  nickname,
  username,
  followLink,
  handleMenuBarClick,
}: UserMenuProps) => {
  const cn = 'user-menu';

  const userLink = `/${username}` || '';
  return (
    <div className={cn} style={{ width }}>
      <div className={`${cn}__user`}>
        <KeywordLink text={nickname} path={userLink} variant="black" />
        {/* <a href={followLink} className={`${cn}__follow`}>フォロー</a> */}
      </div>
      <div className={`${cn}__menu`}>
        <ThreeDots className={`${cn}__menu-bar`} onClick={handleMenuBarClick} />
      </div>
    </div>
  );
};
