import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';

export interface KeywordLinkProps {
  text: string;
  path: string;
  variant?: string;
  isReactRouter?: boolean;
}

export const KeywordLink: React.FC<KeywordLinkProps> = ({
  text,
  path,
  variant = 'main',
  isReactRouter = false,
}: KeywordLinkProps) => {
  const cn = 'keyword-link';
  if (isReactRouter)
    return (
      <Link to={path} className={`${cn} ${cn}--${variant}`}>
        {text}
      </Link>
    );
  return (
    <a href={path} className={`${cn} ${cn}--${variant}`}>
      {text}
    </a>
  );
};
