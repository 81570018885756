import React, {
  useCallback,
  useContext,
  useEffect,
  useReducer,
  useState,
} from 'react';

import { useComponents } from 'components/custom/use-components';
import { usePageLink } from 'components/custom/use-page-link';
import { PostingCommentProps } from 'components/molecules/PostingComment';
import { CommentsTypes } from 'modules/actions/comments';
import { deleteContent } from 'modules/actions/content';
import {
  addFavorite,
  checkFavorite,
  checkLikeContent,
  likeContent,
  takeFavorite,
  unlikeContent,
} from 'modules/actions/extension';
import {
  favoriteReducer,
  likeContentReducer,
} from 'modules/reducers/content-extension';

import { CommentCardWithImage } from 'components/molecules/CommentCardWithImage';
import { CommentMenuModal } from 'components/organisms/CommentMenuModal';
import { PostMenuModal } from 'components/organisms/PostMenuModal';

import { useComments } from 'components/api-hooks/use-comments';
import { useContent } from 'components/api-hooks/use-content';
import { Loader } from 'components/atoms/Loader';
import { useAlert } from 'components/custom/use-alert';
import { UserContexts } from 'modules/contexts';
import { useTranslation } from 'react-i18next';

export interface CommentCardWithImageContainer {
  size?: 'sm' | 'lg' | 'xl';
  alt?: string;
  contentId: string;
  isActive?: boolean;
  isPostCard?: boolean;
}

interface CommentMenuModalState {
  isShow: boolean;
  commentId: string | null;
  isLoginUserComment: boolean;
}

export const CommentCardWithImageContainer: React.FC<CommentCardWithImageContainer> =
  ({
    size = 'xl',
    alt = '',
    contentId: propsContentId,
    isActive,
    isPostCard = false,
  }: CommentCardWithImageContainer) => {
    const { t } = useTranslation();
    const { userState } = useContext(UserContexts);
    const { user, isLogin } = userState;

    const {
      getPostingUserProps,
      getPostingContentProps,
      getPostingCommentProps,
    } = useComponents();
    const { displayReportAlert, displayDeleteAlert } = useAlert();

    //content state
    const {
      contentState: { content, status: contentStatus, error },
      get: getContent,
      incrimentLikeCount,
      decrimentLikeCount,
    } = useContent('loading');

    // comment state
    const {
      commentsState: { comments, status: commentsStatus, error: commentsError },
      fetch: fetchComment,
      createComment,
      deleteComment,
    } = useComments();

    const { getUrl, pushEnter } = usePageLink();

    // メニューバーボタンの活性・非活性を管理
    const [contentMenuModalState, setContentMenuModalState] = useState(false);
    const [commentMenuModalState, setCommentMenuModalState] =
      useState<CommentMenuModalState>({
        isShow: false,
        commentId: null,
        isLoginUserComment: false,
      });

    // メニューバーボタン押下時のイベントハンドラー
    const handleClickContentMenu = useCallback(() => {
      setContentMenuModalState(true);
    }, []);
    const handleCloseContentMenu = useCallback(() => {
      setContentMenuModalState(false);
    }, []);

    // メニューバーボタン押下時のイベントハンドラー
    const handleClickCommentMenu = useCallback(
      (commentId: string, isLoginUserComment: boolean) => {
        setCommentMenuModalState({
          isShow: true,
          commentId,
          isLoginUserComment,
        });
      },
      []
    );

    const handleCloseCommentMenu = useCallback(() => {
      setCommentMenuModalState({
        ...commentMenuModalState,
        isShow: false,
        commentId: null,
      });
    }, []);

    const [isLikeState, isLikeDispatch] = useReducer(likeContentReducer, false);
    const [isFavoriteState, isFavoriteDispatch] = useReducer(
      favoriteReducer,
      false
    );

    useEffect(() => {
      (async () => {
        if (isActive) {
          const res = await Promise.all([
            getContent(propsContentId),
            fetchComment(propsContentId),
            checkLikeContent(propsContentId),
            checkFavorite(propsContentId),
          ]);
          isLikeDispatch(res[2]);
          isFavoriteDispatch(res[3]);
          console.log('getCommentsByContent useEffect');
        }
      })();
    }, [isActive]);

    // スナップが選択されたとき、マップでそのスナップにフォーカスを当てるため、URLを書き換える
    useEffect(() => {
      if (content != null && window.setMapView != null && isPostCard) {
        const { latitude: lat, longitude: lng } = content;
        const center = { lat, lng };
        window.setMapView(center);
      }
    }, [content]);

    if (!isActive) {
      return <></>;
    }

    if (
      content == null ||
      (content == null &&
        (contentStatus == 'loading' || commentsStatus == 'loading'))
    ) {
      return <Loader variant={'light'} />;
    }

    const canEdit = isLogin && user?.id == content.user.id;

    const submitLike = async (contentId: string) => {
      const res = await likeContent(contentId);
      isLikeDispatch(res);
      incrimentLikeCount();
    };

    const submitUnLike = async (contentId: string) => {
      const res = await unlikeContent(contentId);
      isLikeDispatch(res);
      decrimentLikeCount();
    };

    const handleClickLikeCounter = () => {
      if (isLikeState) {
        submitUnLike(content.id);
      } else {
        submitLike(content.id);
      }
    };

    const submitAddFavorite = async (contentId: string) => {
      const res = await addFavorite(contentId);
      isFavoriteDispatch(res);
    };

    const submitTakeFavorite = async (contentId: string) => {
      const res = await takeFavorite(contentId);
      isFavoriteDispatch(res);
    };

    const handleClickFavoriteIcon = () => {
      if (isFavoriteState) {
        submitTakeFavorite(content.id);
      } else {
        submitAddFavorite(content.id);
      }
    };

    //コメント投稿
    const submitComment = async (contentId: string, comment: string) => {
      await createComment(String(contentId), { body: comment });
    };

    const handleEditContent = () => {
      window.location.href = getUrl(`post/edit?id=${content.id}`);
    };

    // 投稿の削除
    const handleDeleteContent = async () => {
      const deleteContentCallback = async () => {
        const res = await deleteContent(content.id);
        return res;
      };

      const isConfirmed = await displayDeleteAlert(deleteContentCallback);
      if (isConfirmed) {
        handleCloseContentMenu();
        window.location.href = getUrl(`profile/`);
      }
    };

    // 投稿のリンクをコピー
    const handleCopyLink = () => {
      navigator.clipboard.writeText(
        `${window.location.origin}?post=${content.id}`
      );
      handleCloseContentMenu();
    };

    // 投稿を報告
    const handleReportContent = async () => {
      if (!isLogin) {
        pushEnter(`?post=${content.id}`);
        return;
      }

      const isConfirmed = await displayReportAlert(content.id, 2);
      if (isConfirmed) {
        handleCloseContentMenu();
      }
    };

    const handleDeleleComment = async () => {
      if (commentMenuModalState.commentId == null) {
        return;
      }
      await deleteComment(commentMenuModalState.commentId);
      //TODO error handling
      //TODO loading
      handleCloseCommentMenu();
    };

    const handleReportComment = async () => {
      if (commentMenuModalState.commentId == null) {
        return;
      }
      if (!isLogin) {
        pushEnter(`?post=${content.id}`);
        return;
      }

      // comment Object Type is 3
      const isConfirmed = await displayReportAlert(
        commentMenuModalState.commentId,
        3
      );
      if (isConfirmed) {
        handleCloseCommentMenu();
      }
    };

    //propsを構築
    const { content_image_url = '' } = content;

    //TODO stateとhandleをまとめてもよいかも
    const postingUserParam = getPostingUserProps(
      content,
      handleClickContentMenu,
      handleClickLikeCounter,
      handleClickFavoriteIcon,
      isLikeState,
      isFavoriteState
    );
    const postingContentParam = getPostingContentProps(content);
    // is users comment
    const postingCommentParams: PostingCommentProps[] = getPostingCommentProps(
      comments as CommentsTypes,
      handleClickCommentMenu,
      user?.id
    );

    const postMapLink = getUrl(`?post=${content.id}`);

    const commentCardProps = {
      postingUserParam,
      postingContentParam,
      postingCommentParams,
      isLogin,
      submitComment,
      postMapLink,
    };

    const commentCardWithImageProps = {
      size,
      img: content_image_url,
      alt,
      commentCardProps,
    };

    //投稿メニュー
    const postMenuModalProps = {
      isShow: contentMenuModalState,
      canEdit,
      handleEditContent,
      handleDelete: handleDeleteContent,
      handleCopyLink,
      handleReport: handleReportContent,
      handleCloseMenu: handleCloseContentMenu,
    };

    //コメントメニュー
    const commentMenuModalProps = {
      isShow: commentMenuModalState.isShow,
      canEdit: commentMenuModalState.isLoginUserComment,
      handleDelete: handleDeleleComment,
      handleReport: handleReportComment,
      handleCloseMenu: handleCloseCommentMenu,
    };

    return (
      <>
        <CommentCardWithImage {...commentCardWithImageProps} />
        <PostMenuModal {...postMenuModalProps} />
        <CommentMenuModal {...commentMenuModalProps} />
      </>
    );
  };
