import { useTranslation } from 'react-i18next';
import {
  LineIcon,
  LineShareButton,
  TwitterIcon,
  TwitterShareButton
} from 'react-share';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';

import Logger from 'lib/logger';
import { ApiError } from 'models/ApiError';
import { Content } from 'models/Content';
import { AbstractActionTypes } from 'modules/actions/action';
import { types } from 'modules/actions/actionTypes';
import { createReport } from 'modules/actions/report';

const SPAM = 1;
const NSFW = 2;
const ABUSIVE = 3;

export const useAlert = () => {
  const { t } = useTranslation();
  const classNameConfirm = `app-alert__confirm-btn`;
  const classNameRadio = `app-alert__radio`;
  const classNameContainer = `app-alert__container--small`;

  // 削除確認アラート
  const displayReportAlert = async (
    targetId: string,
    targetType: number
  ): Promise<boolean> => {
    {
      /* const className = `${cn}__btn-alert` */
    }

    const inputOptions = new Promise((resolve) => {
      setTimeout(() => {
        resolve({
          '1': 'スパムである',
          '2': '公序良俗に反する',
          '3': '誹謗中傷な内容が含まれている',
        });
      }, 0);
    });

    const _swal = Swal.mixin({
      customClass: {
        confirmButton: `${classNameConfirm}`,
        input: `${classNameRadio}`,
        container: `${classNameContainer}`,
      },
    });

    const { isConfirmed } = await _swal.fire({
      text: '報告する理由',
      input: 'radio',
      inputOptions: inputOptions,
      inputValidator: (inputValue: string) => {
        if (!inputValue) {
          return '報告する理由を選択してください';
        }
        return null;
      },
      showCancelButton: true,
      confirmButtonText: '報告する',
      cancelButtonText: 'キャンセル',
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        const _reportType = Number(value);
        const data = {
          object_id: targetId,
          object_type: targetType,
          spam: _reportType == SPAM,
          nsfw: _reportType == NSFW,
          abusive: _reportType == ABUSIVE,
        };
        return createReport(data).then((response) => {
          if (response.type == types.API_ERROR) {
            Swal.showValidationMessage(`Request failed: ${response.error}`);
          }
          return response;
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });
    if (isConfirmed) {
      await _swal.fire({
        icon: 'success',
        text: 'ご報告ありがとうございました。',
        timer: 5000,
      });
    }
    return isConfirmed;
  };

  // 削除確認アラート
  const displayDeleteAlert = async (
    callback: () => Promise<AbstractActionTypes>
  ): Promise<boolean> => {
    const _swal = Swal.mixin({
      customClass: {
        confirmButton: `${classNameConfirm}`,
      },
    });

    const { isConfirmed } = await _swal.fire({
      text: t('ConfirmDelete'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: '削除する',
      cancelButtonText: 'キャンセル',
      reverseButtons: true,
      showLoaderOnConfirm: true,
      preConfirm: (value) => {
        return callback().then((response) => {
          if (response.type == types.API_ERROR) {
            Swal.showValidationMessage(`Request failed: ${response.error}`);
          }
          return response;
        });
      },
      allowOutsideClick: () => !Swal.isLoading(),
    });

    if (isConfirmed) {
      await _swal.fire({
        icon: 'success',
        text: t('PostDeleted'),
        timer: 5000,
      });
    }

    return isConfirmed;
  };

  // 投稿時に表示するアラート
  const displaySuccessAlert = async (title: string) => {
    const _swal = Swal.mixin({
      customClass: {
        confirmButton: `${classNameConfirm}`,
      },
    });

    await _swal.fire({
      icon: 'success',
      title: title,
      timer: 5000,
      willClose: () => {},
    });
  };

  // 投稿時に表示するアラート
  const displayPostSuccessAlert = async (title: string, content: Content) => {
    const _swal = withReactContent(
      Swal.mixin({
        customClass: {
          confirmButton: `${classNameConfirm}`,
        },
      })
    );
    const { body = '', content_image_url = '', tags = [] } = content;

    const shareButtonSize = 30;
    // ハッシュを抜く。
    // 1丁目や番地のタグが含まれてしまっているのでFilter
    // 正規表現、全角数値を検索。GeoLocationで取得する住所が全角なため。
    const hashtags = tags
      .map((param) => param.name)
      .filter((param) => param.search(/[０-９]/g) == -1);
    hashtags.unshift('DigLee');
    const shareTitle = body;
    const originURL = window.location.origin;
    const url = content_image_url
      ? `${originURL}?post=${content.id}`
      : originURL;

    const shareButtons = (
      <div className="footer-share-buttons">
        <TwitterShareButton url={url} title={shareTitle} hashtags={hashtags}>
          <TwitterIcon size={shareButtonSize} round />
        </TwitterShareButton>
        <LineShareButton
          url={url}
          title={shareTitle}
          className="Demo__some-network__share-button"
        >
          <LineIcon size={shareButtonSize} round />
        </LineShareButton>
      </div>
    );

    await _swal.fire({
      icon: 'success',
      title: title,
      // timer: 5000,
      willClose: () => {},
      footer: shareButtons,
    });
  };

  // Error時に表示するアラート
  const displayErrorAlert = async (err: ApiError, message?: string) => {
    Logger.sendError(err);
    const _swal = Swal.mixin({
      customClass: {
        confirmButton: `${classNameConfirm}`,
      },
    });
    const { displayMessageCode } = err;
    const displayMessage = t(displayMessageCode);
    const text = message || displayMessage;

    await _swal.fire({
      icon: 'warning',
      text,
    });
  };

  return {
    displayReportAlert,
    displayDeleteAlert,
    displaySuccessAlert,
    displayPostSuccessAlert,
    displayErrorAlert,
  };
};
