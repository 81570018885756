import { useAreas } from 'components/api-hooks/use-areas';
import { useContents } from 'components/api-hooks/use-contents';
import { Loader } from 'components/atoms/Loader';
import Map from 'components/containers/MapWithPinsContainer';
import { useAlert } from 'components/custom/use-alert';
import { useComponents } from 'components/custom/use-components';
import { useLeafletUtils } from 'components/custom/use-leaflet-utils';
import { usePageLink } from 'components/custom/use-page-link';
import { usePostCarousel } from 'components/custom/use-post-carousel';
import { useSearchModal } from 'components/custom/use-search-modal';
import { InfinityPostList } from 'components/organisms/InfinityPostList';
import { TagPage as TagPageTemplate } from 'components/Templates/Tag';
import { Content } from 'models/Content';
import { getTag } from 'modules/actions/tag';
import tagReducer from 'modules/reducers/tag';
import React, { useEffect, useMemo, useReducer } from 'react';
import { useParams } from 'react-router-dom';
import { Tag } from 'services/api';

export const TagPage: React.FC = () => {
  const { tag = '' } = useParams<{ tag: string }>();
  const { displayErrorAlert } = useAlert();
  const { getUrl } = usePageLink();
  const { isNullBounds, isNullPosition } = useLeafletUtils();

  const {
    areasState: { areas },
  } = useAreas();

  const {
    contentsState: { contents, hasMore, status, error },
    params,
    setParams,
    fetchTagContents: fetch,
  } = useContents(undefined, 12);
  const fetchMore = () => {
    fetch(name);
  };

  const [tagState, tagDispatch] = useReducer(tagReducer, null);
  const { createPostListWithComments } = useComponents();
  const { handleClickPost, createImageCarouselModal } = usePostCarousel();
  // 検索モーダル
  const { createSearchModal, handleOpen } = useSearchModal();
  const search = (value: string) => {
    const params = { query: value };
    setParams(params);
    fetch(name, params, 1);
  };
  const handleResetSearchValue = () => {
    search('');
  };
  const searchModal = createSearchModal(search);
  // #####

  const PostList = useMemo(() => {
    if (contents == null) {
      return <></>;
    }

    const PostList =
      contents.length != 0 ? (
        createPostListWithComments(contents, false, handleClickPost)
      ) : (
        <div>スナップが存在しません</div>
      );

    const InfinityPostListProps = {
      PostList,
      fetchMore,
      hasMore,
      status,
    };

    return <InfinityPostList {...InfinityPostListProps} />;
  }, [contents, status]);

  useEffect(() => {
    (async () => {
      const res = await Promise.all([getTag(tag)]);
      tagDispatch(res[0]);
      fetch(tag);
    })();
  }, [tag]);

  // if api error, show Error Alert
  // fetch only
  useEffect(() => {
    (async () => {
      if (error != null) {
        await displayErrorAlert(error);
        {
          /* resetRequest() */
        }
        return;
      }
    })();
  }, [error]);

  // tagのstatusは未実装
  if (areas == null && status == 'loading') {
    return <Loader />;
  }

  // TODO
  // // notfoundのときはエラーページを表示
  // if (error != null && isNotFoundError(error)) {
  //   return <ErrorTemplate error={error} />;
  // }

  if (areas == null || contents == null || tagState == null) {
    return <></>;
  }

  //Tagを展開
  const {
    name = 'エリアなし',
    child_tags = [],
    contents_count: contentCount = 0,
  } = tagState;
  const tagNames = child_tags.map((tag) => {
    const { id = -1, name } = tag as Tag;
    const path = getUrl(`tags/${id}`);
    // Todo: パスの指定は変更予定 ex. parent_id → name
    return { text: name || 'タグなし', path };
  });

  //Image Carousel のモーダル
  const imageCarouselModal =
    contents != null
      ? createImageCarouselModal(contents.map((data: Content) => data.id))
      : null;

  // boundsのバリデート
  // null, 0,0のときundefinedとする
  // → デフォルト(日本表示)表示
  const { bounds, location } = tagState;
  const _bounds =
    bounds == null ? undefined : isNullBounds(bounds) ? undefined : bounds;
  const _location =
    location == null
      ? undefined
      : isNullPosition(location)
      ? undefined
      : location;

  const handleMoveZoom = (range: string) => {};
  const _Map = (
    <Map
      areas={areas}
      center={_location}
      bounds={_bounds}
      contents={contents}
      current={name}
      isArea={tagState.is_area}
      handleMoveZoom={handleMoveZoom}
      handleClickMapPin={handleClickPost}
    />
  );
  return (
    <>
      <TagPageTemplate
        Map={_Map}
        PostList={PostList}
        area={name}
        postNum={contentCount}
        tags={tagNames}
        handleClickFilter={handleOpen}
        handleResetSearchValue={handleResetSearchValue}
        searchValue={params?.query}
      />
      {searchModal}
      {imageCarouselModal}
    </>
  );
};
