import React from 'react';
import { PostForm, PostFormProps } from 'components/organisms/PostForm';

import './style.css';

export interface PostPageProps {
  mode?: 'create' | 'edit';
  submit?: PostFormProps['submit'];
  content?: PostFormProps['content'];
  position?: PostFormProps['position'];
  isLoading?: boolean;
}

export const PostPage: React.FC<PostPageProps> = ({
  mode = 'create',
  submit,
  content,
  position,
  isLoading,
}: PostPageProps) => {
  const cn = 'post-page';
  const title = mode == 'create' ? 'スナップ' : '編集';

  return (
    <div className={cn}>
      <div className="post-page__title">
        <h1>スナップ編集画面</h1>
      </div>

      <PostForm
        mode={mode}
        submit={submit}
        content={content}
        position={position}
        isLoading={isLoading}
      />
    </div>
  );
};
