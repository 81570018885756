import { ApiError } from 'models/ApiError';
import { ApiStatus } from 'models/ApiStatus';
import { Content } from 'models/Content';
import { types } from 'modules/actions/actionTypes';
import { ContentsActionTypes } from 'modules/actions/contents';

export type ContentsState = {
  contents: Content[] | null;
  page: number;
  hasMore: boolean;
  status: ApiStatus;
  error?: ApiError | null;
};

export default (
  state: ContentsState,
  action: ContentsActionTypes
): ContentsState => {
  switch (action.type) {
    case types.API_START:
      return {
        ...state,
        status: 'loading',
      };
    case types.API_FETCH_MORE_SUCCESS:
      //TODO  refactoring!
      const currentAtFetch = state.contents;
      const nextAtFetch = action.data;
      // TODO 12 is default infinity loading page size
      // リクエスト時のpage sizeと合わせたい
      // 一致しているときは、まだ投稿があるとみなす。
      return {
        ...state,
        contents:
          currentAtFetch != null
            ? currentAtFetch.concat(nextAtFetch)
            : Object.assign([], nextAtFetch),
        page: state.page + 1,
        hasMore: action.data != null && 12 == action.data.length,
        status: 'completed',
      };

    case types.API_FETCH_SUCCESS:
      //TODO  refactoring!
      const f = action.data;
      return {
        ...state,
        contents: Object.assign([], f),
        status: 'completed',
      };

    case types.REQUEST_RESET:
      return {
        contents: null,
        page: 1,
        hasMore: true,
        status: 'loading',
        error: null,
      };

    case types.API_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.error,
      };

    default:
      return state;
  }
};
