import { types } from './actionTypes';
import { ActionTypes, action, actionWithAuth } from './action';
import { ContentApiFactory, Bounds, Position } from 'services/api/api';
import { Configuration } from 'services/api/configuration';
import { getAuthConfigurations } from './auth';
import { Content } from 'models/Content';

type BoundsTypes = Bounds;
type PositionTypes = Position;

interface SearchResult {
  contents: Content[];
  bounds: Bounds;
  location: Position;
}
export type SearchTypes = SearchResult;

interface ApiFetchSuccess {
  type: typeof types.API_FETCH_SUCCESS;
  data: SearchTypes;
}

interface ApiFetchMoreSuccess {
  type: typeof types.API_FETCH_MORE_SUCCESS;
  data: SearchTypes;
}

interface RequestSearchSuccess {
  type: typeof types.API_SEARCH_SUCCESS;
  data?: SearchTypes;
}

export type SearchActionTypes =
  | ActionTypes
  | ApiFetchSuccess
  | ApiFetchMoreSuccess
  | RequestSearchSuccess;

export type Params = {
  range?: string;
  sort?: string;
  query?: string;
};

const initParams = {
  range: undefined,
  sort: undefined,
  query: undefined,
};

// 投稿一覧を返す
export const search = async (
  query?: string,
  page?: number
): Promise<SearchActionTypes> => {
  const actionCallBack = async () => {
    const res = await ContentApiFactory().search(query, page);
    const data = res.data as SearchTypes;
    return { type: types.API_SEARCH_SUCCESS, data };
  };

  return action<SearchActionTypes>(actionCallBack);
};

// 投稿一覧を返す
export const getContents = async (
  params?: Params,
  page?: number,
  pageSize?: number
): Promise<SearchActionTypes> => {
  const actionCallBack = async () => {
    const { range, sort, query } = params || initParams;
    const _pageSize = pageSize ? String(pageSize) : undefined;
    const res = await ContentApiFactory().getContents(
      range,
      sort,
      query,
      page,
      _pageSize
    );
    const contents = res.data as Content[];
    const data = { contents } as SearchTypes;
    return { type: types.API_FETCH_SUCCESS, data };
  };

  return action<SearchActionTypes>(actionCallBack);
};
