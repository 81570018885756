import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { PostPage } from 'components/Templates/PostPage';

import { useContent } from 'components/api-hooks/use-content';
import { useAlert } from 'components/custom/use-alert';
import { usePageLink } from 'components/custom/use-page-link';
import { UserContexts } from 'modules/contexts';

export const NewPostPage: React.FC = () => {
  const history = useHistory();

  const { pushEnter } = usePageLink();
  const { displayPostSuccessAlert, displayErrorAlert } = useAlert();

  const {
    userState: { isLogin },
  } = useContext(UserContexts);
  // クエリパラメータの指定が無ければ、東京都の座標を初期値に設定
  const query = new URLSearchParams(useLocation().search);
  const position = query.get('position') || undefined;

  const {
    contentState: { content, status, error },
    create,
    resetRequest,
  } = useContent();

  // if api error, show Error Alert
  useEffect(() => {
    (async () => {
      if (error != null) {
        await displayErrorAlert(error);
        resetRequest();
        return;
      }
    })();
  }, [error]);

  // リクエスト結果の処理
  useEffect(() => {
    (async () => {
      if (status == 'completed' && content?.id != null) {
        await displayPostSuccessAlert('スナップされました', content);
        resetRequest();
        history.push(`/?post=${content.id}`);
      }
    })();
  }, [status]);

  if (!isLogin) {
    pushEnter('/post/create');
  }

  return (
    <PostPage
      mode={'create'}
      submit={create}
      position={position}
      isLoading={status == 'loading'}
    />
  );
};
