import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';

import './style.css';

export interface PostListProps {
  width?: string | number;
  Cards: JSX.Element[];
}

export const PostList: React.FC<PostListProps> = ({
  width = '100%',
  Cards,
}: PostListProps) => {
  const cn = 'post-list';
  return (
    <div className={cn} style={{ width }}>
      <Container className={`${cn}__image-grid`} fluid>
        <Row xs={1} sm={3} md={4} className={`${cn}__image-grid-row`}>
          {Cards.map((Card, i) => (
            <Col key={i} className={`${cn}__image-grid-col`}>
              {Card}
            </Col>
          ))}
        </Row>
      </Container>
    </div>
  );
};
