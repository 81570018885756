import React from 'react';
import ReactMarkdown from 'react-markdown';

import './style.css';

export interface MarkDownProps {
  markdown: string;
}

export const MarkDown: React.FC<MarkDownProps> = ({
  markdown,
}: MarkDownProps) => {
  const cn = 'markdown';
  return (
    <div className={cn}>
      <ReactMarkdown children={markdown} />
    </div>
  );
};
