import { types } from 'modules/actions/actionTypes';
import { ContentActionTypes } from 'modules/actions/content';
import { ApiStatus } from 'models/ApiStatus';
import { ApiError } from 'models/ApiError';
import { Content } from 'models/Content';

export type ContentState = {
  content: Content | null;
  status: ApiStatus;
  error?: ApiError | null;
};

export default (
  state: ContentState,
  action: ContentActionTypes
): ContentState => {
  switch (action.type) {
    case types.API_START:
      return {
        ...state,
        status: 'loading',
      };

    case types.API_FETCH_SUCCESS:
      return {
        ...state,
        content: Object.assign([], state.content, action.data),
        status: 'init',
      };

    case types.API_CREATE_SUCCESS:
    case types.API_UPDATE_SUCCESS:
      return {
        ...state,
        content: Object.assign([], state.content, action.data),
        status: 'completed',
      };

    case types.API_DELETE_SUCCESS:
      return {
        ...state,
        content: null,
        status: 'completed',
      };

    case types.LIKE_COUNT_INCRIMENT:
      const _i = state.content as Content;
      return {
        ...state,
        content: {
          ..._i,
          likes_count: _i.likes_count != null ? _i.likes_count + 1 : 1,
        },
      };

    case types.LIKE_COUNT_DECRIMENT:
      const _d = state.content as Content;
      return {
        ...state,
        content: {
          ..._d,
          likes_count:
            _d.likes_count != null && 0 < _d.likes_count
              ? _d.likes_count - 1
              : 0,
        },
      };

    case types.REQUEST_RESET:
      return {
        ...state,
        status: 'init',
        error: null,
      };

    case types.API_ERROR:
      return {
        ...state,
        status: 'error',
        error: action.error,
      };

    default:
      return state;
  }
};
