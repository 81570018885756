import React, { useState, useReducer, useEffect, useContext } from 'react';
import reducer, { UserState } from 'modules/reducers/authenticated-user';
import {
  getAuthenticatedUser,
  updateAuthenticatedUser,
  uploadAuthenticatedUserImage,
  setAuthenticatedUser,
  UserTypes,
  RequestUserTypes,
} from 'modules/actions/authenticated-user';
import {
  resetRequest as _resetRequest,
  startRequest as _startRequest,
} from 'modules/actions/action';

export const useAuthenticatedUser = (initStatus: string = 'init') => {
  // initにするとLoadingの前に一瞬表示されてしまうため,loadingにしておく
  const [userState, dispatch] = useReducer(reducer, {
    user: null,
    isLogin: false,
    status: initStatus,
  } as UserState);

  const updateUser = async (data: RequestUserTypes) => {
    startRequest();
    const res = await updateAuthenticatedUser(data);
    dispatch(res);
  };

  const uploadUserImage = async (file: File) => {
    startRequest();
    const res = await uploadAuthenticatedUserImage(file);
    dispatch(res);
  };

  const getUser = async () => {
    startRequest();
    const res = await getAuthenticatedUser();
    dispatch(res);
  };

  const setUser = (data: UserTypes) => {
    const res = setAuthenticatedUser(data);
    dispatch(res);
  };

  const startRequest = () => {
    dispatch(_startRequest());
  };

  const resetRequest = () => {
    dispatch(_resetRequest());
  };

  return {
    userState,
    getUser,
    setUser,
    updateUser,
    uploadUserImage,
  };
};
