import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
} from 'react-router-dom';

import { SwitchingRouter } from 'components/atoms/SwitchingRouter';
import {
  SettingForm,
  SettingFormValues,
  SettingFormProps,
} from 'components/organisms/SettingForm';

import './style.css';

interface DisplaySwitchingMethods {
  activeIndex: number;
  handleClick?: (index: number) => void;
}

export interface SettingPageProps {
  displaySwithingMethods: DisplaySwitchingMethods;
  profileSettingFormProps: SettingFormProps;
}

export const SettingPage: React.FC<SettingPageProps> = ({
  displaySwithingMethods,
  profileSettingFormProps,
}: SettingPageProps) => {
  let { path, url } = useRouteMatch();

  const links = [
    { to: `${url}`, text: 'アカウント' },
    { to: `${url}/privacy`, text: 'プライバシー' },
  ];

  const cn = 'setting-page';
  return (
    <div className={cn}>
      <div className="app__title">
        <h1>アカウント設定</h1>
      </div>

      <div className={`${cn}__contents`}>
        <div className={`${cn}__display-switching`}>
          <SwitchingRouter links={links} />
        </div>
        <div className={`${cn}__display-items`}>
          <Switch>
            <Route exact path={`${path}`}>
              <SettingForm {...profileSettingFormProps} />
            </Route>
            <Route path={`${path}/privacy`}>coming soon</Route>
          </Switch>
        </div>
      </div>
    </div>
  );
};
