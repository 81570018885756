import { Tag } from 'components/atoms/Tag';
import { IconButton } from 'components/molecules/IconButton';
import { UserProfile } from 'components/molecules/UserProfile';
import React from 'react';
import { BiFilterAlt as Filter } from 'react-icons/bi';

import './style.css';

export interface UserPageProps {
  Map: JSX.Element;
  PostList: JSX.Element;
  Thumbnail?: JSX.Element | SVGElement;
  name: string;
  postNum: number;
  searchValue?: string;
  handleClickFilter?: () => void;
  handleResetSearchValue?: () => void;
}

export const UserPage: React.FC<UserPageProps> = ({
  Map,
  PostList,
  Thumbnail,
  name,
  postNum,
  handleClickFilter,
  handleResetSearchValue,
  searchValue,
}: UserPageProps) => {
  const cn = 'user-page';
  return (
    <div className={cn}>
      <div id="main-map" className={`${cn}__map page__map`}>
        {Map}
      </div>
      <div id="main-contents" className={`page__contents`}>
        <div className={`${cn}__user-profile`}>
          <UserProfile Thumbnail={Thumbnail} name={name} postNum={postNum} />
        </div>
        <div className={`page__filter`}>
          <div className={`page__btn-group`}>
            <IconButton
              onClick={() => {
                handleClickFilter && handleClickFilter();
              }}
              Icon={Filter}
              text={'絞り込む'}
              variant="outline-gray"
            />
          </div>
          <div className={`page__tag-group`}>
            {searchValue ? (
              <Tag text={searchValue} handleDelete={handleResetSearchValue} />
            ) : null}
          </div>
        </div>
        <div id="content-list" className={`${cn}__post-list`}>
          {PostList}
        </div>
      </div>
    </div>
  );
};
