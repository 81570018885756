import { types } from 'modules/actions/actionTypes';
import {
  LikeActionTypes,
  FavoriteActionTypes,
} from 'modules/actions/extension';

type State = boolean;

export const likeContentReducer = (
  state: boolean,
  action: LikeActionTypes
): State => {
  const { type } = action;
  switch (type) {
    case types.API_LIKE_CONTENT:
      return true;
    case types.API_UNLIKE_CONTENT:
      return false;
    default:
      return state;
  }
};

export const favoriteReducer = (
  state: boolean,
  action: FavoriteActionTypes
): State => {
  switch (action.type) {
    case types.API_ADD_FAVORITE:
      return true;
    case types.API_TAKE_FAVORITE:
      return false;
    case types.API_CHECK_FAVORITE:
      return action.isFavorite;
    default:
      return state;
  }
};
