import { useAreas } from 'components/api-hooks/use-areas';
import { useContent } from 'components/api-hooks/use-content';
import { useContents } from 'components/api-hooks/use-contents';
import { Loader } from 'components/atoms/Loader';
import Map from 'components/containers/MapWithPinsContainer';
import { useAlert } from 'components/custom/use-alert';
import { useComponents } from 'components/custom/use-components';
import { usePostCarousel } from 'components/custom/use-post-carousel';
import { Top as TemplateTop } from 'components/Templates/Top';
import { Content } from 'models/Content';
import React, { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const TopPage: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  // マップで表示対象のスナップ (ターゲットスナップ)
  // スナップページ (マップ)
  const contentId = query.get('post');

  const { displayErrorAlert } = useAlert();
  const { createPostListWithComments } = useComponents();

  const {
    contentsState: { contents: _contents, hasMore, status, error },
    params,
    fetchNoLoading: fetch,
    resetRequest,
  } = useContents(undefined, 100);
  const {
    areasState: { areas, status: areaStatus },
  } = useAreas();
  const { contentState: targetContentState, get: getTargetContent } =
    useContent();
  // 対象のスナップ
  const { content: targetContent, status: targetContentStatus } =
    targetContentState;

  //Carousel State
  const { modalState, handleClickPost, createImageCarouselModal } =
    usePostCarousel();

  //マップで表示するスナップリストに、ターゲットスナップを追加
  const concatContent = (contents: Content[], target: Content) => {
    const _contents = contents.filter((v) => v.id != target.id);
    return Object.assign([], _contents, [targetContent]);
  };

  //TODO refactoring
  const contents =
    _contents != null && targetContent != null
      ? concatContent(_contents, targetContent) // 両方あるとき
      : _contents != null //contentsだけのとき
      ? _contents
      : ([] as Content[]);

  const handleMoveZoom = (range: string) => {
    //新着スナップリストのモーダルが開いている時、fetchさせない。スナップリストが変更されないようにするため。
    if (modalState.isShow) {
      return;
    }
    fetch({ ...params, range });
  };

  useEffect(() => {
    (async () => {
      //TODO Validate
      if (contentId) {
        getTargetContent(contentId);
      }
      fetch();
    })();
  }, []);

  // if api error, show Error Alert
  // fetch only
  // TODO only content
  useEffect(() => {
    (async () => {
      if (error != null) {
        await displayErrorAlert(error);
        return;
      }
    })();
  }, [error]);

  // target contentのcenterを取得するまえに、mapをレンダリングしないようにする。
  if (targetContentState.status == 'loading' || status == 'loading') {
    return (
      <div className={`page__loading`}>
        <Loader />
      </div>
    );
  }
  if (!areas || !contents) return <></>;

  //Carousel
  const imageCarouselModal = createImageCarouselModal(
    contents.map((data: Content) => data.id)
  );

  const center = targetContent
    ? { lat: targetContent.latitude, lng: targetContent.longitude }
    : undefined;

  const _Map = (
    <Map
      areas={areas}
      contents={contents}
      handleMoveZoom={handleMoveZoom}
      isAreaLinks={true}
      center={center}
      handleClickMapPin={handleClickPost}
    />
  );

  const PostList = createPostListWithComments(contents, false, handleClickPost);
  return (
    <>
      <TemplateTop Map={_Map} PostList={PostList} areas={areas} />
      {imageCarouselModal}
    </>
  );
};
