import React, { useEffect } from 'react';
import { useTranslation, Trans } from 'react-i18next';
import Logger from 'lib/logger';
import { ApiError, hasProblem, isNotFoundError } from 'models/ApiError';
// import { KeywordLink } from 'components/atoms/KeywordLink'
import './style.css';

export interface ErrorTemplateProps {
  error: ApiError;
}

export const ErrorTemplate: React.FC<ErrorTemplateProps> = ({
  error,
}: ErrorTemplateProps) => {
  const { t } = useTranslation();
  const cn = 'error-page';

  // Errorをロギング
  useEffect(() => {
    Logger.sendError(error);
  }, []);

  const buildHeaderMessage = (): string => {
    if (isNotFoundError(error)) {
      return 'ページが見つかりません。';
    }
    return 'ページの表示に失敗してしまいました。';
  };

  const buildMainMessage = () => {
    return <Trans>{t(error.displayMessageCode)}</Trans>;
  };

  const buildAdditionalMessage = () => {
    if (hasProblem(error)) {
      return (
        <span>
          <br />
          時間をおいても改善されない場合、お手数ですが
          <a href="https://forms.gle/NSj2y8X1GyxepUgp6">こちら</a>
          からお問い合わせください。
        </span>
      );
    }
    return <></>;
  };

  return (
    <div className={cn}>
      <div className={`${cn}__contents`}>
        <div className={`${cn}__header`}>{buildHeaderMessage()}</div>
        <div className={`${cn}__message`}>
          <p>
            {buildMainMessage()}
            {buildAdditionalMessage()}
          </p>
        </div>
        <div className={`${cn}__image`}></div>
      </div>
    </div>
  );
};
