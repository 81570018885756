import React from 'react';
import { Nav } from 'react-bootstrap';
import { NavLink, RouteComponentProps } from 'react-router-dom';

import './style.css';

type link = {
  to: string;
  text: string;
};
export interface SwitchingRouterProps {
  links: link[];
}

export const SwitchingRouter: React.FC<SwitchingRouterProps> = ({
  links,
}: SwitchingRouterProps) => {
  const cn = 'switching-router';

  const isActive = (
    match: RouteComponentProps['match'] | null,
    location: RouteComponentProps['location']
  ) => {
    if (!match) {
      return false;
    }
    {
      /* return location.pathname.match(match.path) != null */
    }
    return (
      location.pathname == match.url || location.pathname + '/' == match.url
    );
  };

  return (
    <Nav variant="tabs">
      {links.map((link, i) => {
        const { to, text } = link;
        return (
          <Nav.Item key={i}>
            <NavLink
              to={to}
              className={`${cn}__tab nav-link`}
              isActive={isActive}
            >
              {text}
            </NavLink>
          </Nav.Item>
        );
      })}
    </Nav>
  );
};
