import { useAreas } from 'components/api-hooks/use-areas';
import { useSearch } from 'components/api-hooks/use-search';
import { Loader } from 'components/atoms/Loader';
import Map from 'components/containers/MapWithPinsContainer';
import { useComponents } from 'components/custom/use-components';
import { useLeafletUtils } from 'components/custom/use-leaflet-utils';
import { usePostCarousel } from 'components/custom/use-post-carousel';
import { SearchPage as TemplateSearch } from 'components/Templates/SearchPage';
import React from 'react';
import { useLocation } from 'react-router-dom';

export const SearchPage: React.FC = () => {
  const query = new URLSearchParams(useLocation().search);
  const _query = query.get('query');

  const {
    searchState: { contents: _contents, bounds, location, status },
    search,
    getContents,
    searchQuery,
  } = useSearch(_query);
  const {
    areasState: { areas },
  } = useAreas();
  //Carousel State
  const { isNullBounds, isNullPosition } = useLeafletUtils();
  const { handleClickPost, createImageCarouselModal } = usePostCarousel();

  const { createPostListWithComments } = useComponents();

  if (!areas) return <></>;
  if (status == 'loading') {
    return (
      <div className={`loading`}>
        <Loader />
      </div>
    );
  }

  // contentが無い場合でもマップ自体を表示するため
  const contents = _contents != null ? _contents : [];

  //Carousel
  const imageCarouselModal = createImageCarouselModal(
    contents.map((data) => data.id)
  );

  // boundsのバリデート
  // null, 0,0のときundefinedとする
  const _bounds =
    bounds == null ? undefined : isNullBounds(bounds) ? undefined : bounds;
  const _location =
    location == null
      ? undefined
      : isNullPosition(location)
      ? undefined
      : location;

  const handleMoveZoom = (range: string) => {
    getContents(range);
  };
  const _Map = (
    <Map
      areas={areas}
      contents={contents}
      handleClickMapPin={handleClickPost}
      isArea={_location != null}
      bounds={_bounds}
      center={_location}
      handleMoveZoom={handleMoveZoom}
      current={searchQuery}
    />
  );

  const PostList =
    contents.length != 0 ? (
      createPostListWithComments(contents, false, handleClickPost)
    ) : (
      <div>スナップが存在しません</div>
    );
  // 対象地域の緯度、経度情報を取得・加工
  return (
    <>
      <TemplateSearch Map={_Map} areas={areas} PostList={PostList} />
      {imageCarouselModal}
    </>
  );
};
