import React from 'react';
import { PostSummary } from 'components/atoms/PostSummary';

import './style.css';

export interface CommentBalloonProps {
  style?: React.CSSProperties;
  variant?: string;
  text: string;
  dateTime: string;
  name: string;
}

export const CommentBalloon: React.FC<CommentBalloonProps> = ({
  style = {},
  variant = 'gray',
  text,
  dateTime,
  name,
}: CommentBalloonProps) => {
  const cn = 'comment-balloon';
  return (
    <div className={`${cn} ${cn}--${variant}`} style={style}>
      <p className={`${cn}__text`}>{text}</p>
      <div className={`${cn}__post-info`}>
        <PostSummary dateTime={dateTime} name={name} />
      </div>
    </div>
  );
};
