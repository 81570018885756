import React from 'react';
import { UserThumbnail } from 'components/atoms/UserThumbnail';
import { UserMenu } from 'components/atoms/UserMenu';
import { SocialItem } from 'components/molecules/SocialItem';
import './style.css';

export interface PostingUserProps {
  width?: string | number;
  Thumbnail?: JSX.Element | SVGElement;
  nickname: string;
  username?: string;
  counterVal: number;
  followLink: string;
  handleMenuBarClick?: () => void;
  handleClickLikeCounter?: () => void;
  handleClickFavoriteIcon?: () => void;
  handleClickUser?: () => void;
  isLike?: boolean;
  isFavorite?: boolean;
  isLogin?: boolean;
}

export const PostingUser: React.FC<PostingUserProps> = ({
  width = '100%',
  Thumbnail,
  nickname,
  username,
  counterVal,
  followLink,
  handleMenuBarClick,
  handleClickLikeCounter,
  handleClickFavoriteIcon,
  handleClickUser,
  isLike,
  isFavorite,
  isLogin = true,
}: PostingUserProps) => {
  const cn = 'posting-user';

  return (
    <div className={cn} style={{ width }}>
      <div className={`${cn}__thumbnail`}>
        <UserThumbnail
          Thumbnail={Thumbnail}
          handleClickUser={handleClickUser}
        />
      </div>
      <div className={`${cn}__body`}>
        <UserMenu
          nickname={nickname}
          username={username}
          followLink={followLink}
          handleMenuBarClick={handleMenuBarClick}
        />
      </div>
      <div className={`${cn}__icons`}>
        <SocialItem
          counterVal={counterVal}
          isLike={isLike}
          isFavorite={isFavorite}
          isActive={isLogin}
          handleClickLikeCounter={handleClickLikeCounter}
          handleClickFavoriteIcon={handleClickFavoriteIcon}
        />
      </div>
    </div>
  );
};
