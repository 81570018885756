import { AreaTypes } from 'modules/actions/areas';
import React from 'react';
import './style.css';

export interface SearchPageProps {
  Map: JSX.Element;
  PostList?: JSX.Element;
  areas: AreaTypes[];
}

export const SearchPage: React.FC<SearchPageProps> = ({
  Map,
  PostList,
  areas,
}: SearchPageProps) => {
  const cn = 'search';
  return (
    <div className={`${cn} page`}>
      <div id="main-map" className={`${cn}__map page__map`}>
        {Map}
      </div>
      <div id="main-contents" className={`page__contents`}>
        {PostList != null ? (
          <div id="content-list" className={`${cn}__contents-list`}>
            {PostList}
          </div>
        ) : null}
      </div>
    </div>
  );
};
