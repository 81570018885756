import React, { useState } from 'react';
import { SearchModal, SearchFormProps } from 'components/molecules/SearchModal';

export const useSearchModal = () => {
  const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
  const handleOpen = () => {
    setIsOpenModal(true);
  };
  const handleClose = () => {
    setIsOpenModal(false);
  };

  const createSearchModal = (search: (value: string) => void) => {
    const searchFormProps = {
      search,
      handleCancel: handleClose,
    };

    const searchModalProps = {
      searchFormProps: searchFormProps,
      isShow: isOpenModal,
      handleClose: handleClose,
    };

    return <SearchModal {...searchModalProps} />;
  };

  return { createSearchModal, handleOpen };
};
