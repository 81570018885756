import React from 'react';
import { Modal } from 'react-bootstrap';

import './style.css';

export interface MenuModalProps {
  children: JSX.Element[];
  isShow?: boolean;
  onHide?: () => void;
}

export const MenuModal: React.FC<MenuModalProps> = ({
  children,
  onHide,
  isShow = false,
}: MenuModalProps) => {
  const cn = 'menu-modal';
  return (
    <Modal
      show={isShow}
      keyboard={false}
      className={cn}
      centered
      onHide={() => {
        onHide && onHide();
      }}
    >
      <Modal.Body className={`${cn}__body`}>
        <ul className={`${cn}__list`}>
          {children.map((Comp, i) => (
            <li key={i} className={`${cn}__item`}>
              {Comp}
            </li>
          ))}
        </ul>
      </Modal.Body>
    </Modal>
  );
};
