import Geocode from 'react-geocode';
Geocode.setApiKey(process.env.REACT_APP_GEOCODING_API_KEY);

// set response language. Defaults to english.
Geocode.setLanguage('ja');
Geocode.setRegion('jp');

// set response region. Its optional.
// A Geocoding request with region=es (Spain) will return the Spanish city.
/* Geocode.setRegion("es"); */

// Enable or disable logs. Its optional.
Geocode.enableDebug();

interface AddressComponent {
  short_name: string;
  long_name: string;
  postcode_localities?: string[];
  types: string[];
}

const level1Type = 'administrative_area_level_1';
const level2Type = 'administrative_area_level_2';
const localityType = 'locality';
const sublocalityType = 'sublocality';
const political = 'political';
// Get address from latitude & longitude.

/* const getLongName = (address_components: AddressComponent[],type: string): string =>{ */
/*   const i = address_components.findIndex((data: AddressComponent)=> data.types.includes(type) ) */
/*   return address_components[i].long_name */
/* } */

// Addressの配列を取得
export const getAddress = async (
  lat: string,
  lnd: string
): Promise<string[]> => {
  //TODO error処理を行う。
  const res = await Geocode.fromLatLng(lat, lnd).catch((error) => {
    return error.response;
  });
  if (res == null) {
    return [];
  }

  const results = res.results;

  if (results < 1) {
    return [];
  }

  const address_components = results[0].address_components;

  if (address_components.length < 3) {
    return [];
  }

  //国などを除外するため、-2
  const _address_components = address_components.slice(0, -2).reverse();

  let addresses = [];
  for (const component of _address_components) {
    if (component.types.includes(political)) {
      addresses.push(component.long_name);
    }
  }
  return addresses;

  /* .then( */
  /*  response => { */
  /*    const address = response.results[0].formatted_address; */
  /*    console.log(address); */
  /*  }, */
  /*  error => { */
  /*    console.error(error); */
  /*  } */
  /* ); */
};

export type Position = {
  lat: number;
  lng: number;
};

/**
 *
 * @export
 * @interface Bounds
 */
export interface Bounds {
  /**
   *
   * @type {Position}
   * @memberof Bounds
   */
  northeast?: Position;
  /**
   *
   * @type {Position}
   * @memberof Bounds
   */
  southwest?: Position;
}
export type GeoCodeResult = {
  location?: Position;
  bounds?: Bounds;
  ok: boolean;
};

export const getPlace = async (address: string): Promise<GeoCodeResult> => {
  // Get latitude & longitude from address.
  const res = await Geocode.fromAddress(address).catch((error) => {
    return false;
  });
  if (!res) {
    return { ok: false };
  }
  console.log(res.results[0].geometry, 'geometory');

  const location = res.results[0].geometry.location;
  const bounds = res.results[0].geometry.viewport;
  return { location, bounds, ok: true };
};
