import ReactTagInput from '@pathofdev/react-tag-input';
import '@pathofdev/react-tag-input/build/index.css';
import { Annotation } from 'components/atoms/Annotation';
import { Button } from 'components/atoms/Button';
import { FileUpload } from 'components/atoms/FileUpload';
import { FormValuesProps, useForm } from 'components/custom/use-form';
import Datetime from 'react-datetime';

import {
  Bounds,
  Position,
  useLeafletUtils,
} from 'components/custom/use-leaflet-utils';
import { CropModal } from 'components/molecules/CropModal';
import { LoadingButton } from 'components/molecules/LoadingButton';
import Leaflet from 'leaflet';
import { GestureHandling } from 'leaflet-gesture-handling';
import { Content } from 'models/Content';
import { RequestContent } from 'modules/actions/content';
import React, { useEffect, useRef, useState } from 'react';
import { Button as InputButton, Form, InputGroup } from 'react-bootstrap';
import {
  MapContainer,
  Marker,
  useMap,
  useMapEvents,
  ZoomControl,
} from 'react-leaflet';

import { usePageLink } from 'components/custom/use-page-link';
import { validateTags } from 'modules/util';
import { getAddress, getPlace } from 'services/geoapi';

import 'leaflet/dist/leaflet.css';
import 'react-leaflet-markercluster/dist/styles.min.css';
import './style.css';

import 'mapbox-gl';
import 'mapbox-gl-leaflet';
import 'mapbox-gl/dist/mapbox-gl.css';
import 'react-datetime/css/react-datetime.css';

Leaflet.Icon.Default.imagePath =
  '//cdnjs.cloudflare.com/ajax/libs/leaflet/1.3.1/images/';
Leaflet.Map.addInitHook('addHandler', 'gestureHandling', GestureHandling);

export interface PostFormProps {
  mode?: 'create' | 'edit';
  values?: FormValuesProps;
  position?: string | null;
  submit?: (data: RequestContent, file: any) => void;
  content?: Content;
  isLoading?: boolean;
}

interface State {
  mode: PostFormProps['mode'];
  values: FormValuesProps;
  zoom: number;
  center?: Position;
  bounds?: Bounds;
  address: string;
  cropImage: string;
  isValid: boolean;
  modal: {
    isSignIn: boolean;
    isSuccess: boolean;
    isCropping: boolean;
  };
}

type Input = React.ChangeEvent<HTMLInputElement>;

interface GetNewStateProps {
  latitude: string;
  longitude: string;
  mapCode?: string;
}

interface ChangeViewProps {
  center?: Position;
  zoom?: number;
  bounds?: Bounds;
}

export const PostForm: React.FC<PostFormProps> = (props: PostFormProps) => {
  const mapStyle = process.env.REACT_APP_MAP_STYLE || '';

  const { replaceQuery } = usePageLink();

  const {
    mode = 'create',
    values,
    position,
    submit,
    content,
    isLoading,
  } = props;

  const {
    makePostFormData,
    makeMapCode,
    getInitMapCode,
    mapCodeRegStr,
    mapCodeReg,
    getInitValues,
    changeToInitValues,
  } = useForm();

  const { buildBounds } = useLeafletUtils();

  const cn = 'post-form';

  const initValues =
    mode == 'create'
      ? getInitValues(position)
      : changeToInitValues(content as Content);

  const [state, setState] = useState<State>({
    mode,
    values: initValues,
    zoom: 5,
    address: '',
    cropImage: '',
    isValid: false,
    modal: {
      isSignIn: false,
      isSuccess: false,
      isCropping: false,
    },
  });

  const formEl = useRef<HTMLFormElement | null>(null);

  const getNewState = async ({
    latitude = '',
    longitude = '',
    mapCode,
  }: GetNewStateProps) => {
    const { tags } = state.values;
    const _mapCode = mapCode != null ? mapCode : state.values.mapCode;
    const addresses = await getAddress(latitude, longitude);
    const values = {
      ...state.values,
      latitude,
      longitude,
      mapCode: _mapCode,
      tags: tags,
      areaTags: addresses,
    };
    return { ...state, values };
  };

  const _getPlace = async (address: string) => {
    const result = await getPlace(address);
    return result;
  };

  const handleChangeImage = (uploadImg: string = '', file?: File) => {
    const values = { ...state.values, uploadImg, file };
    const modal = { ...state.modal, isCropping: false };
    setState({ ...state, values, modal });
    {
      /* const modal = { ...state.modal, isCropping: true }; */
    }
    {
      /* setState({ ...state, modal, cropImage:uploadImg }); */
    }
  };

  const handleCropImage = (uploadImg: string = '', file?: File) => {
    const values = { ...state.values, uploadImg, file };
    const modal = { ...state.modal, isCropping: false };
    setState({ ...state, values, modal });
  };

  const handleClickCancel = () => {
    const modal = { ...state.modal, isCropping: false };
    setState({ ...state, modal });
  };

  useEffect(() => {
    (async () => {
      if (mode === 'create') {
        const { latitude, longitude } = getInitMapCode(position);
        const newState = await getNewState({ latitude, longitude });
        const center = {
          lat: Number(latitude),
          lng: Number(longitude),
        };
        setState({ ...newState, center });
      }
    })();
  }, []);

  // 入力必須項目を表すコンポーネント
  const AnnotationComp = <Annotation text="必須" />;

  // TagInputに渡すtagsを生成
  const { tags, areaTags } = state.values;
  const imageCropperProps = {
    image: state.cropImage,
    handleCropImage,
    handleClickCancel,
  };

  //Mapイベント
  const MapEvent = () => {
    const map = useMapEvents({
      click: async (e) => {
        const { lat, lng } = e.latlng;
        const { latitude, longitude, mapCode } = makeMapCode(`${lat}, ${lng}`);
        const newState = await getNewState({ latitude, longitude, mapCode });
        setState({ ...newState, bounds: undefined });
        mode === 'create' && replaceQuery('position', `${lat},${lng}`);
      },
    });
    return null;
  };
  const ChangeView = ({ bounds }: ChangeViewProps) => {
    const map = useMap();
    bounds && map.fitBounds(buildBounds(bounds));
    return null;
  };

  const pinPosition =
    state.values.latitude && state.values.longitude
      ? {
          lat: Number(state.values.latitude),
          lng: Number(state.values.longitude),
        }
      : undefined;

  const zoom = pinPosition ? 14 : 5;

  const defaultCenter = {
    lat: 35.676191,
    lng: 139.65031,
  };

  // 新規作成・更新の共通処理
  const onClickSubmit = () => {
    const form = formEl.current;
    if (!form) throw new Error('Form要素のRef参照を確認');
    // 各入力項目のバリデーション
    if (!form.checkValidity() || pinPosition == null) {
      setState({ ...state, isValid: true });
      return;
    }

    if (!submit) return;
    const data = makePostFormData(state.values);
    submit(data, state.values.file);
  };

  const handleSearchPlace = async () => {
    const { ok, location, bounds } = await _getPlace(state.address);
    if (!ok) return;
    const { lat, lng } = location as Position;
    const { latitude, longitude, mapCode } = makeMapCode(`${lat}, ${lng}`);
    const newState = await getNewState({ latitude, longitude, mapCode });
    setState({ ...newState, bounds });
  };

  const SubmitButton = isLoading ? (
    <LoadingButton text={mode === 'create' ? 'スナップ中' : '更新中'} />
  ) : (
    <Button onClick={onClickSubmit}>
      {mode === 'create' ? 'スナップする' : '更新する'}
    </Button>
  );

  return (
    <section className={cn}>
      <CropModal
        imageCropperProps={imageCropperProps}
        isShow={state.modal.isCropping}
      />
      <Form
        ref={formEl}
        noValidate
        validated={state.isValid}
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <Form.Group className={`${cn}__group`}>
          <Form.Label className={`${cn}__label`}>
            <span className={`${cn}__label-text`}>スナップ場所</span>
            {AnnotationComp}
          </Form.Label>
          <div className={`${cn}__map`}>
            <MapContainer
              center={pinPosition || defaultCenter}
              maxZoom={18}
              minZoom={5}
              zoom={zoom}
              zoomControl={false}
              gestureHandling={true}
              whenCreated={(map: Leaflet.Map) => {
                console.log(map, 'map');
                var gl = Leaflet.mapboxGL({
                  attribution:
                    '\u003ca href="https://www.maptiler.com/copyright/" target="_blank"\u003e\u0026copy; MapTiler\u003c/a\u003e \u003ca href="https://www.openstreetmap.org/copyright" target="_blank"\u003e\u0026copy; OpenStreetMap contributors\u003c/a\u003e',
                  style: mapStyle,
                }).addTo(map);

                //日本の範囲
                const defaultBounds = buildBounds({
                  northeast: { lat: 50.6412626, lng: 154.0031455 },
                  southwest: { lat: 20.3585295, lng: 122.8554688 },
                });
                map.setMaxBounds(defaultBounds);
              }}
            >
              <MapEvent />
              <ChangeView bounds={state.bounds} />
              <ZoomControl position="topright" />
              {pinPosition ? <Marker position={pinPosition} /> : null}
            </MapContainer>
            <InputGroup className={`${cn}__search`}>
              <Form.Control
                type="text"
                name="address"
                className={`${cn}__search-box`}
                value={state.address}
                placeholder="ex. 東京都"
                onChange={(e: Input) =>
                  setState({
                    ...state,
                    address: e.target.value,
                    bounds: undefined,
                  })
                }
                onKeyUp={(e: React.KeyboardEvent<HTMLInputElement>) => {
                  if (e.keyCode === 13) {
                    handleSearchPlace();
                  }
                }}
              />
              <InputGroup.Append>
                <InputButton
                  className={`${cn}__search-btn`}
                  onClick={handleSearchPlace}
                >
                  検索
                </InputButton>
              </InputGroup.Append>
            </InputGroup>
          </div>
          {state.isValid && pinPosition == null && (
            <div className={`${cn}__error`}>
              スナップ場所を指定してください。
            </div>
          )}
        </Form.Group>

        <Form.Group className={`${cn}__group ${cn}__group--disable`}>
          <Form.Label className={`${cn}__label`}>地域</Form.Label>
          <ReactTagInput
            tags={areaTags}
            placeholder="スナップ場所を指定すると地域情報が入力されます"
            maxTags={20}
            editable={false}
            readOnly={true}
            removeOnBackspace={true}
            onChange={(tags) => {
              const nextTags = validateTags(tags);
              const values = { ...state.values, areaTags: nextTags };
              setState({ ...state, values });
            }}
          />
        </Form.Group>
        <Form.Group className={`${cn}__group`}>
          <Form.Label className={`${cn}__label`}>
            <span className={`${cn}__label-text`}>スナップ画像</span>
            {AnnotationComp}
          </Form.Label>
          <FileUpload
            url={state.values.uploadImg}
            handleChange={handleChangeImage}
          />
        </Form.Group>
        <Form.Group className={`${cn}__group`}>
          <Form.Label className={`${cn}__label`}>コメント</Form.Label>
          <Form.Control
            type="text"
            name="comment"
            placeholder="50文字まで"
            value={state.values.comment}
            maxLength={50}
            onChange={(e: Input) => {
              const values = { ...state.values, comment: e.target.value };
              setState({ ...state, values });
            }}
          />
        </Form.Group>
        <Form.Group className={`${cn}__group`}>
          <Form.Label className={`${cn}__label`}>スナップ日時</Form.Label>
          <Datetime
            value={new Date(state.values.postedTime)}
            dateFormat="yyyy/MM/DD"
            onChange={(value) => {
              // momentオブジェクトが渡される
              // 直接入力は対応しない。複雑になるし、逆にそれを使おうとすると混乱する。
              // しかし、年の部分は修正できてしまうが一旦このままとする。
              if (typeof value === 'string') {
                return;
              }
              const nextTime = value.format();
              const values = { ...state.values, postedTime: nextTime };
              setState({ ...state, values });
            }}
          />
        </Form.Group>
        <Form.Group className={`${cn}__group`}>
          <Form.Label className={`${cn}__label`}>タグ(任意)</Form.Label>
          <ReactTagInput
            tags={tags}
            placeholder="入力後、エンターで確定"
            maxTags={20}
            editable={true}
            readOnly={false}
            removeOnBackspace={true}
            onChange={(tags) => {
              const nextTags = validateTags(tags);
              const values = { ...state.values, tags: nextTags };
              setState({ ...state, values });
            }}
          />
        </Form.Group>

        {/* 投稿同意チェックボックス */}
        <Form.Group className={`${cn}__group`}>
          <Form.Check className={`${cn}__checkbox`}>
            <Form.Check.Input
              required
              type="checkbox"
              checked={state.values.checked}
              onChange={(e: Input) => {
                const checked = !state.values.checked;
                const values = { ...state.values, checked };
                setState({ ...state, values });
              }}
            />
            <Form.Check.Label
              onClick={(e: React.MouseEvent<HTMLLabelElement>) => {
                const checked = !state.values.checked;
                const values = { ...state.values, checked };
                setState({ ...state, values });
              }}
            >
              {mode === 'create'
                ? '利用規約に同意し、プライバシーなどの問題がないことを確認した上でスナップする'
                : '利用規約に同意し、プライバシーなどの問題がないことを確認した上で更新する'}
            </Form.Check.Label>
          </Form.Check>
        </Form.Group>
        <div className={`${cn}__btn-group`}>
          <div className={`${cn}__btn`}>
            {SubmitButton}
            {state.isValid && (
              <div className={`${cn}__error ${cn}__error--center`}>
                エラー項目を確認して下さい。
              </div>
            )}
          </div>
          {/* <div className={`${cn}__btn`}> */}
          {/*   <Button variant='gray'>下書きに保存</Button> */}
          {/* </div> */}
        </div>
      </Form>
    </section>
  );
};
