import { AxiosError, AxiosStatic } from 'axios';

import { types } from './actionTypes';
import { Configuration } from 'services/api/configuration';
import { ActionTypes, actionWithAuth } from './action';
import { ReportApiFactory, Report, RequestReport } from 'services/api/api';

export type ReportTypes = Report;
export type RequestReportTypes = RequestReport;

interface ApiStart {
  type: typeof types.API_START;
}

interface ApiCreateSuccess {
  type: typeof types.API_CREATE_SUCCESS;
  data: ReportTypes;
}

export type ReportActionTypes = ActionTypes | ApiStart | ApiCreateSuccess;

// 新規に投稿する(タグも付与)
export const createReport = async (
  data: RequestReportTypes
): Promise<ReportActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const _data = data as RequestReport;
    const res = await ReportApiFactory(
      configurations as Configuration
    ).createReport(_data);
    const resReport = res.data as ReportTypes;
    return { type: types.API_CREATE_SUCCESS, data: resReport };
  };

  return await actionWithAuth<ReportActionTypes>(actionCallBack);
};
