import {
  AreaKeysProps,
  AreaNameProps,
  useLeafletUtils,
} from 'components/custom/use-leaflet-utils';
import { usePageLink } from 'components/custom/use-page-link';
import { CommentBalloon } from 'components/molecules/CommentBalloon';
import { PostCard } from 'components/molecules/PostCard';
import { PostingCommentProps } from 'components/molecules/PostingComment';
import { PostingContentProps } from 'components/molecules/PostingContent';
import { PostingUserProps } from 'components/molecules/PostingUser';
import { Tags } from 'components/molecules/Tags';
import { PostList } from 'components/organisms/PostList';
import { PostListWithComments } from 'components/organisms/PostListWithComments';
import format from 'date-fns/format';
import { Content } from 'models/Content';
import { AreaTypes } from 'modules/actions/areas';
import { UserTypes } from 'modules/actions/authenticated-user';
import { CategorieTypes } from 'modules/actions/categories';
import { CommentsTypes } from 'modules/actions/comments';
import { Tag } from 'services/api/api';

export interface AreaContentsProps {
  area: AreaTypes;
  data: Content[];
}

export interface MakeKeyWordProps {
  tags?: Tag[];
  category?: string;
}

export interface MakeKeyWordLinksProps {
  tags?: Tag[];
  category?: CategorieTypes;
}

// API経由で取得したデータを使用して、コンポーネントを構築
export const useComponents = () => {
  const { getUrl } = usePageLink();
  const { convertAreaIntoKey } = useLeafletUtils();

  // MapWithPinsコンポーネントの構築
  const createMapPins = (data: AreaContentsProps[]) => {
    const params = data.map((d) => {
      const { area, data } = d;
      const pins = data.map((pin) => {
        return buildPin(pin);
      });
      const name = area.name as AreaNameProps;
      const key = convertAreaIntoKey(name);
      const areaKey = key as AreaKeysProps;
      return { area: areaKey, pins };
    });
    return params;
  };

  const buildPin = (content: Content) => {
    const {
      id,
      title = '',
      content_image_url = '',
      latitude,
      longitude,
    } = content;
    const smallImageURL = `${content_image_url}?s=small`;
    const Img = <img src={smallImageURL} alt={title} />;
    return { id, Img, lat: latitude, lng: longitude };
  };

  const buildUserThumnail = (url: string) => {
    return <img src={url} alt="ユーザーサムネイル" />;
  };

  // PostingUserコンポーネントのプロパティを取得
  const getPostingUserProps = (
    content: Content,
    handleMenuBarClick?: () => void,
    handleClickLikeCounter?: () => void,
    handleClickFavoriteIcon?: () => void,
    isLike?: boolean,
    isFavorite?: boolean
  ): PostingUserProps => {
    const { likes_count = 0, user } = content;
    const {
      nickname = '名無し',
      username = '',
      profile_image_url = '',
    } = user as UserTypes;
    const thumnail = profile_image_url
      ? buildUserThumnail(profile_image_url)
      : undefined;
    const handleClickUser = () => {
      window.location.href = getUrl(username);
    };

    return {
      Thumbnail: thumnail,
      nickname: nickname,
      username: username,
      counterVal: likes_count,
      followLink: '#',
      handleMenuBarClick: handleMenuBarClick,
      handleClickLikeCounter: handleClickLikeCounter,
      handleClickFavoriteIcon: handleClickFavoriteIcon,
      handleClickUser: handleClickUserFactory(username),
      isLike,
      isFavorite,
    };
  };

  const handleClickUserFactory = (username: string) => {
    return () => {
      // window.location.href = getUrl(username);
    };
  };

  // PostingContentコンポーネントのプロパティを取得
  const getPostingContentProps = (content: Content): PostingContentProps => {
    const {
      id,
      body = 'タイトルなし',
      tags,
      category,
      user,
      posted_time,
    } = content;
    const {
      nickname = '名無し',
      username = '',
      profile_image_url = '',
    } = user as UserTypes;
    const thumnail = profile_image_url
      ? buildUserThumnail(profile_image_url)
      : undefined;
    const handleClickUser = () => {
      window.location.href = getUrl(username);
    };
    const keywordInfos = makeKeyWordLinks({ tags, category });
    // 現在日から投稿日の差分を日数単位で求める
    const today = new Date().getTime();
    const postDay = new Date(posted_time).getTime();
    const diffDay = Math.floor((today - postDay) / 86400000);

    return {
      id: id,
      username: username,
      nickname: nickname,
      Thumbnail: thumnail,
      title: body,
      keywordInfos,
      diffDay: diffDay,
      handleClickUser: handleClickUserFactory(username),
    };
  };

  // PostingCommentコンポーネントのプロパティを取得
  const getPostingCommentProps = (
    comments: CommentsTypes,
    handleClickMenu?: (id: string, isLoginUserComment: boolean) => void,
    loginUserId?: string
  ): PostingCommentProps[] => {
    const _comments = comments;
    // コメント登録日の昇順に並び替え
    const sortedComments = _comments.sort((x, y) => {
      const xTime = x.created_time || '';
      const yTime = y.created_time || '';
      if (xTime > yTime) return 1;
      else if (xTime < yTime) return -1;
      return 0;
    });
    const res = sortedComments.map((comment) => {
      const { id, body, created_time = '', user } = comment;
      const user_id = user.id;
      const {
        nickname = '名無し',
        username = '',
        profile_image_url = '',
      } = user as UserTypes;

      const thumnail = profile_image_url
        ? buildUserThumnail(profile_image_url)
        : undefined;
      // 投稿作成日ではなく、現在日からコメント登録日の差分を日数単位で求める
      // const postDay = new Date(postTime).getTime()
      const today = new Date().getTime();
      const commentDay = new Date(created_time).getTime();
      const diffDay = Math.floor((today - commentDay) / 86400000);

      const isLoginUserComment = loginUserId === user_id;
      return {
        id,
        username,
        nickname,
        Thumbnail: thumnail,
        comment: body,
        diffDay: diffDay,
        handleClickMenu,
        isLoginUserComment,
        handleClickUser: handleClickUserFactory(username),
      };
    });
    return res;
  };

  // Tagsコンポーネントの構築
  const createTags = ({ name = 'No Name', tags = [] }: AreaTypes) => (
    <Tags
      title={name}
      tags={tags.map((tag) => {
        const { name } = tag as Tag;
        const path = `tags/${name}`;
        return { text: name, path };
      })}
      isReactRouter={true}
    />
  );

  // PostCardコンポーネントの構築
  const createPostCard = (
    {
      id,
      title = '',
      content_image_url = '',
      tags,
      category,
      likes_count = 0,
      comments = [],
      user,
    }: Content,
    isUserName: boolean = false,
    handleClickCard?: (contentId: string, isPostCard?: boolean) => void
  ) => {
    const { keywords, path } = makeKeywords({ tags, category: category?.name });
    let userName: string | undefined;
    if (isUserName) userName = user ? user.nickname : '名無し';

    const smallImageURL = `${content_image_url}?s=small`;

    return (
      // keywords配列の先頭は必ずエリアが格納される
      <a onClick={() => handleClickCard && handleClickCard(id, true)}>
        <PostCard
          img={smallImageURL}
          title={title}
          width="100%"
          keywords={keywords}
          userName={userName}
          niceCount={likes_count}
          commentsCount={comments.length}
        />
      </a>
    );
  };

  // CommentBalloonコンポーネントの構築
  const createPostBalloon = ({ body, posted_time = '0', user }: Content) => {
    const nickname = user?.nickname || '名無し';
    const localDate = format(new Date(posted_time), 'yyyy/MM/dd HH:mm');
    return (
      <CommentBalloon
        style={{ width: '100%', marginTop: '1rem', fontSize: '12px' }}
        text={body}
        dateTime={localDate}
        name={nickname}
        variant="gray-lt"
      />
    );
  };

  // CommentBalloonコンポーネントの構築
  const createCommentBalloon = ({ comments = [] }: Content) => {
    if (comments.length == 0) {
      return <></>;
    }
    const { body, updated_time, nickname } = getLatestComment(comments);
    return (
      <CommentBalloon
        style={{ width: '100%', marginTop: '1rem', fontSize: '.6rem' }}
        text={body}
        dateTime={updated_time}
        name={nickname}
      />
    );
  };

  // PostListコンポーネントの構築
  const createPostList = (
    contents: Content[],
    isUserName?: boolean,
    handleClickCard?: (contentId: string, isPostCard?: boolean) => void
  ) => {
    const Cards = contents.map((content) =>
      createPostCard(content, isUserName, handleClickCard)
    );
    return <PostList Cards={Cards} />;
  };

  // PostListWithCommentsコンポーネントの構築
  const createPostListWithComments = (
    contents: Content[],
    isUserName?: boolean,
    handleClickCard?: (contentId: string, isPostCard?: boolean) => void
  ) => {
    const Cards = contents.map((content) => {
      const PostCard = createPostCard(content, isUserName, handleClickCard);
      const CommentBalloon = createPostBalloon(content);
      return { PostCard, CommentBalloon };
    });
    return <PostListWithComments Cards={Cards} />;
  };

  // タグ情報を昇順にソート
  function ascTagSort(tags: Tag[] = []) {
    return tags.sort((x, y) => {
      const _x = x.created_time as string;
      const _y = y.created_time as string;
      return _x < _y ? -1 : _x > _y ? 1 : 0;
    });
  }

  // 写真をマウスオーバー時に表示するキーワード一覧を構築
  // ex. ['#北海道', '#網走', '#風景', ....]
  function makeKeywords({ tags = [], category = '' }: MakeKeyWordProps) {
    const sortedTags = ascTagSort(tags);
    const res = sortedTags.map((tag) => `#${tag.name}`);
    {
      /* res.push(`#${category}`) */
    }
    // Todo: パスの指定は変更予定 ex. id → name
    const path = sortedTags.length > 0 ? sortedTags[0].name : '';
    return { keywords: res, path };
  }

  // 写真をマウスオーバー時に表示するキーワード一覧を構築
  // ex. [{ text: '#北海道', path: 'tags/1'}, ....]
  function makeKeyWordLinks({ tags = [], category }: MakeKeyWordLinksProps) {
    const sortedTags = ascTagSort(tags);
    const res = sortedTags.map((tag) => {
      const { name, is_area } = tag;
      // const path = getUrl(`tags/${name}`);
      const path = `/tags/${name}`;
      return { text: `#${name}`, path, variant: is_area ? 'main' : '' };
    });
    {
      /* if (category) { */
    }
    {
      /*   // Todo: パスの指定は変更予定 ex. id → name */
    }
    {
      /*   const { name = '', id } = category */
    }
    {
      /*   res.push({ text: `#${name}`, path: `tags/${id}` }) */
    }
    {
      /* } */
    }
    return res;
  }

  // 対象コンテンツのコメント一覧より、更新日時が最新のコメントを取得
  function getLatestComment(comments: Content['comments'] = []): {
    body: string;
    updated_time: string;
    nickname: string;
  } {
    const comment = comments.sort((x, y) => {
      const x_updated = x.updated_time as String;
      const y_updated = y.updated_time as String;
      return x_updated < y_updated ? 1 : x_updated > y_updated ? -1 : 0;
    })[0];
    const { body, updated_time = '', user } = comment;
    const localDate = new Date(updated_time).toLocaleString();
    const nickname = user?.nickname || '名無し';
    return { body, updated_time: localDate, nickname };
  }

  return {
    createMapPins,
    buildPin,
    createTags,
    createPostCard,
    createCommentBalloon,
    createPostList,
    createPostListWithComments,
    getPostingUserProps,
    getPostingContentProps,
    getPostingCommentProps,
  };
};
