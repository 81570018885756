import { MarkDownPage } from 'components/Templates/MarkDownPage';
import React from 'react';

const markdown = `
## ThreePs(スリーピース)とは  
2008年に映像制作を目的として活動を開始しました。  
2018年「地方活性化の力になりたい」を形にするためにインターネットサービス制作をスタートすることといたしました。  
  
  
## なぜインターネットサービスなのか  
近年様々な地方自治体が活性化に力を入れています。観光客誘致のため地元のイベントだけではなくインターネットを駆使した活動も活発になっています。  
しかしながら各地方自治体が「個」として活動していることが大半で、行動や熱量ほどの成果があがっていないのが現状です。  
映像制作を通じ様々な地方と接するうちに「頑張っている地方のために活性化のお手伝いをすることが出来ないものか？」そう考え、数ある地方自治体を巡る時間を省略できるインターネットサービスを使い、情報を発信するお手伝いをしたいと考えています。  
  
  
## 「個を見る」現状から「衆を見る」習慣を  
現在のインターネットサービスは孤立化が中心となっています。PCやスマホなど各種デバイスの普及によって個人個人で好きな時間に好きなものを見る習慣が定着してきました。さらにはSNSではインフルエンサーと呼ばれる個人の発信を個人がバラバラで見ると言った状況となり、同じ人を見ていても見るタイミングによって違う情報が入るようになってきました。  
昔のように映画館や集まってみるテレビ番組のように群で同じものを見ると言う状況が少なくなっています。  
個ばかりが見られる現状を変えたい。  
そんな想いから誰が発信したかよりもどこで何を発信したかを大切にして、さらに誰が見ても同じタイミングであれば皆同じものが表示されるウェブサービス｢DigLee｣を展開しています。  
「どこで何を発信したか」が大切なサービスは地方と非常に相性が良く、地方活性化に力を入れている人たちにも使っていただけるウェブサービスです。  
「行政」「企業」「地域」とくみ、映像やウェブサービスに限らず地域を盛り上げることを後押しする。ThreePsはそんな団体です。  
　

ThreePs　代表　庄司達哉  
〒120-0002　東京都足立区中川4-33-2　サンモール502 
`;

export const CompanyPage: React.FC = () => {
  return <MarkDownPage title={'運営団体情報'} markdown={markdown} />;
};
