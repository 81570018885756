import { getAreaContents, getAreas } from 'modules/actions/areas';
import reducer, { AreasState } from 'modules/reducers/areas';
import { useEffect, useReducer } from 'react';

import { startRequest as _startRequest } from 'modules/actions/action';

export const useAreaContents = () => {
  const [areasState, dispatch] = useReducer(reducer, {
    areas: null,
    status: 'loading',
  } as AreasState);

  const fetch = async () => {
    startRequest();
    const res = await getAreaContents();
    dispatch(res);
  };

  const startRequest = () => {
    dispatch(_startRequest());
  };

  useEffect(() => {
    (async () => {
      await fetch();
    })();
  }, []);

  return {
    areasState,
  };
};

export const useAreas = () => {
  const [areasState, dispatch] = useReducer(reducer, {
    areas: null,
    status: 'loading',
  } as AreasState);

  const fetch = async () => {
    startRequest();
    const res = await getAreas();
    dispatch(res);
  };

  const startRequest = () => {
    dispatch(_startRequest());
  };

  useEffect(() => {
    (async () => {
      await fetch();
    })();
  }, []);

  return {
    areasState,
  };
};
