import React from 'react';
import { IconType } from 'react-icons';
import { Button } from '../../atoms/Button';

import './style.css';

export interface IconButtonProps {
  Icon: IconType;
  text: string;
  variant?: string;
  onClick?: () => void;
}

export const IconButton: React.FC<IconButtonProps> = ({
  Icon,
  text,
  variant = 'main',
  onClick,
}: IconButtonProps) => {
  const cn = 'icon-button';

  return (
    <Button variant={variant} onClick={onClick}>
      <span className={cn}>
        <span className={`${cn}__icon`}>
          <Icon />
        </span>
        <span className={`${cn}__text`}>{text}</span>
      </span>
    </Button>
  );
};
