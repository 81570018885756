/* tslint:disable */
/* eslint-disable */
/**
 * Swagger MapSNS
 * map sns api
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: apiteam@swagger.io
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import globalAxios, { AxiosInstance, AxiosPromise } from 'axios';
import { Configuration } from './configuration';
// Some imports not used depending on template conditions
// @ts-ignore
import { assertParamExists, createRequestFunction, DUMMY_BASE_URL, serializeDataIfNeeded, setSearchParams, toPathString } from './common';
// @ts-ignore
import { BaseAPI, BASE_PATH, RequestArgs } from './base';

/**
 * 
 * @export
 * @interface Area
 */
export interface Area {
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Area
     */
    contents_count?: number;
    /**
     * 
     * @type {string}
     * @memberof Area
     */
    created_time?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Area
     */
    tags: Array<Tag>;
}
/**
 * 
 * @export
 * @interface Area1
 */
export interface Area1 {
    /**
     * 
     * @type {Area}
     * @memberof Area1
     */
    id?: Area;
}
/**
 * 
 * @export
 * @interface Bounds
 */
export interface Bounds {
    /**
     * 
     * @type {Position}
     * @memberof Bounds
     */
    northeast?: Position;
    /**
     * 
     * @type {Position}
     * @memberof Bounds
     */
    southwest?: Position;
}
/**
 * 
 * @export
 * @interface Category
 */
export interface Category {
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof Category
     */
    name?: string;
    /**
     * 
     * @type {number}
     * @memberof Category
     */
    contents_count?: number;
}
/**
 * 
 * @export
 * @interface Comment
 */
export interface Comment {
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    body: string;
    /**
     * 
     * @type {number}
     * @memberof Comment
     */
    content_id: number;
    /**
     * 
     * @type {User}
     * @memberof Comment
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    created_time: string;
    /**
     * 
     * @type {string}
     * @memberof Comment
     */
    updated_time?: string;
}
/**
 * A single content.
 * @export
 * @interface Content
 */
export interface Content {
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    id: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof Content
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    body: string;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    title?: string;
    /**
     * 
     * @type {User}
     * @memberof Content
     */
    user: User;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    posted_time: string;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    created_time: string;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    updated_time?: string;
    /**
     * 
     * @type {string}
     * @memberof Content
     */
    content_image_url?: string;
    /**
     * 
     * @type {number}
     * @memberof Content
     */
    likes_count?: number;
    /**
     * 
     * @type {number}
     * @memberof Content
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof Content
     */
    longitude: number;
    /**
     * 
     * @type {Area}
     * @memberof Content
     */
    area?: Area;
    /**
     * 
     * @type {Category}
     * @memberof Content
     */
    category?: Category;
    /**
     * 
     * @type {Array<Comment>}
     * @memberof Content
     */
    comments?: Array<Comment>;
}
/**
 * 
 * @export
 * @interface InlineResponse200
 */
export interface InlineResponse200 {
    /**
     * 
     * @type {Array<Content>}
     * @memberof InlineResponse200
     */
    contents?: Array<Content>;
    /**
     * 
     * @type {Bounds}
     * @memberof InlineResponse200
     */
    bounds?: Bounds;
    /**
     * 
     * @type {Position}
     * @memberof InlineResponse200
     */
    location?: Position;
}
/**
 * 
 * @export
 * @interface Position
 */
export interface Position {
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    lat: number;
    /**
     * 
     * @type {number}
     * @memberof Position
     */
    lng: number;
}
/**
 * 
 * @export
 * @interface Report
 */
export interface Report {
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    object_id: string;
    /**
     * 
     * @type {number}
     * @memberof Report
     */
    object_type?: number;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    reported_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    spam?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    nsfw?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Report
     */
    abusive?: boolean;
    /**
     * 
     * @type {User}
     * @memberof Report
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    created_time?: string;
    /**
     * 
     * @type {string}
     * @memberof Report
     */
    updated_time?: string;
}
/**
 * 
 * @export
 * @interface Report1
 */
export interface Report1 {
    /**
     * 
     * @type {string}
     * @memberof Report1
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Report1
     */
    object_id: string;
    /**
     * 
     * @type {number}
     * @memberof Report1
     */
    object_type?: number;
    /**
     * 
     * @type {string}
     * @memberof Report1
     */
    reported_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof Report1
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Report1
     */
    spam?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Report1
     */
    nsfw?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof Report1
     */
    abusive?: boolean;
    /**
     * 
     * @type {User}
     * @memberof Report1
     */
    user?: User;
    /**
     * 
     * @type {string}
     * @memberof Report1
     */
    created_time?: string;
    /**
     * 
     * @type {string}
     * @memberof Report1
     */
    updated_time?: string;
}
/**
 * 
 * @export
 * @interface RequestComment
 */
export interface RequestComment {
    /**
     * 
     * @type {string}
     * @memberof RequestComment
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestComment
     */
    body?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestComment
     */
    content_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestComment
     */
    created_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestComment
     */
    updated_time?: string;
}
/**
 * 
 * @export
 * @interface RequestComment1
 */
export interface RequestComment1 {
    /**
     * 
     * @type {string}
     * @memberof RequestComment1
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestComment1
     */
    body?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestComment1
     */
    content_id?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestComment1
     */
    created_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestComment1
     */
    updated_time?: string;
}
/**
 * A single content.
 * @export
 * @interface RequestContent
 */
export interface RequestContent {
    /**
     * 
     * @type {string}
     * @memberof RequestContent
     */
    id?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof RequestContent
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof RequestContent
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent
     */
    posted_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent
     */
    updated_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent
     */
    created_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent
     */
    content_image_url?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestContent
     */
    likes_count?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestContent
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof RequestContent
     */
    longitude: number;
    /**
     * 
     * @type {Category}
     * @memberof RequestContent
     */
    category?: Category;
}
/**
 * A single content.
 * @export
 * @interface RequestContent1
 */
export interface RequestContent1 {
    /**
     * 
     * @type {string}
     * @memberof RequestContent1
     */
    id?: string;
    /**
     * 
     * @type {Array<Tag>}
     * @memberof RequestContent1
     */
    tags?: Array<Tag>;
    /**
     * 
     * @type {string}
     * @memberof RequestContent1
     */
    body?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent1
     */
    title?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent1
     */
    posted_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent1
     */
    updated_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent1
     */
    created_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestContent1
     */
    content_image_url?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestContent1
     */
    likes_count?: number;
    /**
     * 
     * @type {number}
     * @memberof RequestContent1
     */
    latitude: number;
    /**
     * 
     * @type {number}
     * @memberof RequestContent1
     */
    longitude: number;
    /**
     * 
     * @type {Category}
     * @memberof RequestContent1
     */
    category?: Category;
}
/**
 * 
 * @export
 * @interface RequestReport
 */
export interface RequestReport {
    /**
     * 
     * @type {string}
     * @memberof RequestReport
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestReport
     */
    object_id: string;
    /**
     * 
     * @type {number}
     * @memberof RequestReport
     */
    object_type?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestReport
     */
    reported_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestReport
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestReport
     */
    spam?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestReport
     */
    nsfw?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestReport
     */
    abusive?: boolean;
}
/**
 * 
 * @export
 * @interface RequestReport1
 */
export interface RequestReport1 {
    /**
     * 
     * @type {string}
     * @memberof RequestReport1
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestReport1
     */
    object_id: string;
    /**
     * 
     * @type {number}
     * @memberof RequestReport1
     */
    object_type?: number;
    /**
     * 
     * @type {string}
     * @memberof RequestReport1
     */
    reported_user_id?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestReport1
     */
    message?: string;
    /**
     * 
     * @type {boolean}
     * @memberof RequestReport1
     */
    spam?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestReport1
     */
    nsfw?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof RequestReport1
     */
    abusive?: boolean;
}
/**
 * 
 * @export
 * @interface RequestUser
 */
export interface RequestUser {
    /**
     * 
     * @type {string}
     * @memberof RequestUser
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser
     */
    profile_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser
     */
    mail?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser
     */
    created_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser
     */
    updated_time?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestUser
     */
    content_count?: number;
}
/**
 * 
 * @export
 * @interface RequestUser1
 */
export interface RequestUser1 {
    /**
     * 
     * @type {string}
     * @memberof RequestUser1
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser1
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser1
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser1
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser1
     */
    profile_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser1
     */
    mail?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser1
     */
    created_time?: string;
    /**
     * 
     * @type {string}
     * @memberof RequestUser1
     */
    updated_time?: string;
    /**
     * 
     * @type {number}
     * @memberof RequestUser1
     */
    content_count?: number;
}
/**
 * 
 * @export
 * @interface Tag
 */
export interface Tag {
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    name: string;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    contents_count?: number;
    /**
     * 
     * @type {number}
     * @memberof Tag
     */
    parent_id?: number;
    /**
     * 
     * @type {string}
     * @memberof Tag
     */
    created_time?: string;
    /**
     * 
     * @type {Bounds}
     * @memberof Tag
     */
    bounds?: Bounds;
    /**
     * 
     * @type {Position}
     * @memberof Tag
     */
    location?: Position;
    /**
     * 
     * @type {boolean}
     * @memberof Tag
     */
    is_area?: boolean;
}
/**
 * 
 * @export
 * @interface User
 */
export interface User {
    /**
     * 
     * @type {string}
     * @memberof User
     */
    id: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    username?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    nickname?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    profile_image_url?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    mail?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    created_time?: string;
    /**
     * 
     * @type {string}
     * @memberof User
     */
    updated_time?: string;
    /**
     * 
     * @type {number}
     * @memberof User
     */
    content_count?: number;
}

/**
 * CommentApi - axios parameter creator
 * @export
 */
export const CommentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した投稿に対して新しいコメントをつける
         * @param {string} contentId 
         * @param {RequestComment} [requestComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment: async (contentId: string, requestComment?: RequestComment, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('createComment', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}/comments`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestComment, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したコメントを削除
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment: async (commentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('deleteComment', 'commentId', commentId)
            const localVarPath = `/comments/{comment-id}`
                .replace(`{${"comment-id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したコメントを返す
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComment: async (commentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('getComment', 'commentId', commentId)
            const localVarPath = `/comments/{comment-id}`
                .replace(`{${"comment-id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証済みユーザーのコメント履歴を返す
         * @summary Get user\'s comment
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByAuthenticatedUser: async (sort?: string, query?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/comments`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿に付けられたコメント一覧を取得
         * @summary Your GET endpoint
         * @param {string} contentId 
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByContent: async (contentId: string, sort?: string, query?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getCommentsByContent', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}/comments`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したコメントを更新
         * @param {string} commentId 
         * @param {RequestComment1} [requestComment1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment: async (commentId: string, requestComment1?: RequestComment1, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'commentId' is not null or undefined
            assertParamExists('updateComment', 'commentId', commentId)
            const localVarPath = `/comments/{comment-id}`
                .replace(`{${"comment-id"}}`, encodeURIComponent(String(commentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestComment1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * CommentApi - functional programming interface
 * @export
 */
export const CommentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = CommentApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した投稿に対して新しいコメントをつける
         * @param {string} contentId 
         * @param {RequestComment} [requestComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createComment(contentId: string, requestComment?: RequestComment, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createComment(contentId, requestComment, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したコメントを削除
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteComment(commentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteComment(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したコメントを返す
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getComment(commentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getComment(commentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 認証済みユーザーのコメント履歴を返す
         * @summary Get user\'s comment
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByAuthenticatedUser(sort?: string, query?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByAuthenticatedUser(sort, query, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した投稿に付けられたコメント一覧を取得
         * @summary Your GET endpoint
         * @param {string} contentId 
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCommentsByContent(contentId: string, sort?: string, query?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Comment>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCommentsByContent(contentId, sort, query, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したコメントを更新
         * @param {string} commentId 
         * @param {RequestComment1} [requestComment1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateComment(commentId: string, requestComment1?: RequestComment1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Comment>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateComment(commentId, requestComment1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * CommentApi - factory interface
 * @export
 */
export const CommentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = CommentApiFp(configuration)
    return {
        /**
         * 指定した投稿に対して新しいコメントをつける
         * @param {string} contentId 
         * @param {RequestComment} [requestComment] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createComment(contentId: string, requestComment?: RequestComment, options?: any): AxiosPromise<Comment> {
            return localVarFp.createComment(contentId, requestComment, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したコメントを削除
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteComment(commentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したコメントを返す
         * @param {string} commentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getComment(commentId: string, options?: any): AxiosPromise<Comment> {
            return localVarFp.getComment(commentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証済みユーザーのコメント履歴を返す
         * @summary Get user\'s comment
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByAuthenticatedUser(sort?: string, query?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Comment>> {
            return localVarFp.getCommentsByAuthenticatedUser(sort, query, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿に付けられたコメント一覧を取得
         * @summary Your GET endpoint
         * @param {string} contentId 
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCommentsByContent(contentId: string, sort?: string, query?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Comment>> {
            return localVarFp.getCommentsByContent(contentId, sort, query, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したコメントを更新
         * @param {string} commentId 
         * @param {RequestComment1} [requestComment1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateComment(commentId: string, requestComment1?: RequestComment1, options?: any): AxiosPromise<Comment> {
            return localVarFp.updateComment(commentId, requestComment1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * CommentApi - object-oriented interface
 * @export
 * @class CommentApi
 * @extends {BaseAPI}
 */
export class CommentApi extends BaseAPI {
    /**
     * 指定した投稿に対して新しいコメントをつける
     * @param {string} contentId 
     * @param {RequestComment} [requestComment] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public createComment(contentId: string, requestComment?: RequestComment, options?: any) {
        return CommentApiFp(this.configuration).createComment(contentId, requestComment, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したコメントを削除
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public deleteComment(commentId: string, options?: any) {
        return CommentApiFp(this.configuration).deleteComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したコメントを返す
     * @param {string} commentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getComment(commentId: string, options?: any) {
        return CommentApiFp(this.configuration).getComment(commentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証済みユーザーのコメント履歴を返す
     * @summary Get user\'s comment
     * @param {string} [sort] 
     * @param {string} [query] 
     * @param {number} [page] ページ位置
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getCommentsByAuthenticatedUser(sort?: string, query?: string, page?: number, pageSize?: number, options?: any) {
        return CommentApiFp(this.configuration).getCommentsByAuthenticatedUser(sort, query, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿に付けられたコメント一覧を取得
     * @summary Your GET endpoint
     * @param {string} contentId 
     * @param {string} [sort] 
     * @param {string} [query] 
     * @param {number} [page] ページ位置
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public getCommentsByContent(contentId: string, sort?: string, query?: string, page?: number, pageSize?: number, options?: any) {
        return CommentApiFp(this.configuration).getCommentsByContent(contentId, sort, query, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したコメントを更新
     * @param {string} commentId 
     * @param {RequestComment1} [requestComment1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof CommentApi
     */
    public updateComment(commentId: string, requestComment1?: RequestComment1, options?: any) {
        return CommentApiFp(this.configuration).updateComment(commentId, requestComment1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ContentApi - axios parameter creator
 * @export
 */
export const ContentApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 指定した記事をお気に入りに追加する
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavorite: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('addFavorite', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}/favorite`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿にお気に入りに追加済みかを取得
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFavoriteContent: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('checkFavoriteContent', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}/favorite`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿に「いいね」を付けたかを取得
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLikeContent: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('checkLikeContent', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}/like`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 新規に投稿する(タグも付与)
         * @param {RequestContent1} [requestContent1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContent: async (requestContent1?: RequestContent1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestContent1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿を削除
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('deleteContent', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリアを取得する。
         * @summary Your GET endpoint
         * @param {string} areaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArea: async (areaId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('getArea', 'areaId', areaId)
            const localVarPath = `/areas/{area-id}`
                .replace(`{${"area-id"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * エリア一覧を返す
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreas: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/areas`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カテゴリー一覧を返す
         * @summary Your GET endpoint
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories: async (query?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/categories`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * カテゴリを取得する。
         * @summary Your GET endpoint
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory: async (categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getCategory', 'categoryId', categoryId)
            const localVarPath = `/categories/{category-id}`
                .replace(`{${"category-id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿を返す
         * @summary Your GET endpoint
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('getContent', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} [range] 座標範囲をカンマ区切りで指定 例　range&#x3D;36.56234723572857,138.0301165403568,35.608792611587035,139.4455926280639
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {string} [pageSize] 
         * @param {number} [category] (実装しない)
         * @param {string} [tags] tag_idをカンマ区切りで指定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContents: async (range?: string, sort?: string, query?: string, page?: number, pageSize?: string, category?: number, tags?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したエリアに含まれる投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} areaId 
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByArea: async (areaId: string, sort?: string, query?: string, page?: number, pageSize?: number, range?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'areaId' is not null or undefined
            assertParamExists('getContentsByArea', 'areaId', areaId)
            const localVarPath = `/areas/{area-id}/contents`
                .replace(`{${"area-id"}}`, encodeURIComponent(String(areaId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証済みユーザーが投稿した投稿一覧を返す
         * @summary get-user-contents
         * @param {string} [range] 座標範囲をカンマ区切りで指定
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {number} [category] (実装しない)
         * @param {string} [tags] tag_idをカンマ区切りで設定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByAuthenticatedUser: async (range?: string, sort?: string, query?: string, page?: number, pageSize?: number, category?: number, tags?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したカテゴリが付与された投稿一覧を返す（実装保留）
         * @summary Your GET endpoint
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByCategory: async (categoryId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'categoryId' is not null or undefined
            assertParamExists('getContentsByCategory', 'categoryId', categoryId)
            const localVarPath = `/categories/{category-id}/contents`
                .replace(`{${"category-id"}}`, encodeURIComponent(String(categoryId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したタグが付与された投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} tagName 
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByTag: async (tagName: string, sort?: string, query?: string, page?: number, pageSize?: number, range?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagName' is not null or undefined
            assertParamExists('getContentsByTag', 'tagName', tagName)
            const localVarPath = `/tags/{tag-name}/contents`
                .replace(`{${"tag-name"}}`, encodeURIComponent(String(tagName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したユーザーが投稿した投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} username 
         * @param {string} [range] 座標範囲をカンマ区切りで指定
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {number} [category] (実装しない)
         * @param {string} [tags] tag_idをカンマ区切りで設定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByUser: async (username: string, range?: string, sort?: string, query?: string, page?: number, pageSize?: number, category?: number, tags?: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getContentsByUser', 'username', username)
            const localVarPath = `/users/{username}/contents`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証済みユーザーがお気に入りに追加した投稿一覧を返す
         * @summary Your GET endpoint
         * @param {number} [category] (実装しない)
         * @param {string} [tags] (実装しない)
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteContentsByAuthenticatedUser: async (category?: number, tags?: string, sort?: string, query?: string, range?: string, page?: number, pageSize?: number, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/favorite-contents`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (category !== undefined) {
                localVarQueryParameter['category'] = category;
            }

            if (tags !== undefined) {
                localVarQueryParameter['tags'] = tags;
            }

            if (sort !== undefined) {
                localVarQueryParameter['sort'] = sort;
            }

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (range !== undefined) {
                localVarQueryParameter['range'] = range;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * タグを取得する。
         * @summary Your GET endpoint
         * @param {string} tagName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTag: async (tagName: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'tagName' is not null or undefined
            assertParamExists('getTag', 'tagName', tagName)
            const localVarPath = `/tags/{tag-name}`
                .replace(`{${"tag-name"}}`, encodeURIComponent(String(tagName)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * タグ一覧を返す
         * @summary Your GET endpoint
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags: async (query?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/tags`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿に「いいね」をつける
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeContent: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('likeContent', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}/like`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * Search Place and Contents
         * @summary Your GET endpoint
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {string} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search: async (query?: string, page?: number, pageSize?: string, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/search`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;

            if (query !== undefined) {
                localVarQueryParameter['query'] = query;
            }

            if (page !== undefined) {
                localVarQueryParameter['page'] = page;
            }

            if (pageSize !== undefined) {
                localVarQueryParameter['page_size'] = pageSize;
            }


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した記事をお気に入りから外す
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeFavorite: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('takeFavorite', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}/favorite`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿から「いいね」を外す
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeContent: async (contentId: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('unlikeContent', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}/like`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'DELETE', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿を更新
         * @param {string} contentId 
         * @param {RequestContent} [requestContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContent: async (contentId: string, requestContent?: RequestContent, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('updateContent', 'contentId', contentId)
            const localVarPath = `/contents/{content-id}`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestContent, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定した投稿に対し、画像をアップロード（置き換え）する
         * @param {string} contentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContentImage: async (contentId: string, file: any, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'contentId' is not null or undefined
            assertParamExists('uploadContentImage', 'contentId', contentId)
            // verify required parameter 'file' is not null or undefined
            assertParamExists('uploadContentImage', 'file', file)
            const localVarPath = `/contents/{content-id}/image`
                .replace(`{${"content-id"}}`, encodeURIComponent(String(contentId)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ContentApi - functional programming interface
 * @export
 */
export const ContentApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ContentApiAxiosParamCreator(configuration)
    return {
        /**
         * 指定した記事をお気に入りに追加する
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async addFavorite(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.addFavorite(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿にお気に入りに追加済みかを取得
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkFavoriteContent(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkFavoriteContent(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿に「いいね」を付けたかを取得
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async checkLikeContent(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.checkLikeContent(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 新規に投稿する(タグも付与)
         * @param {RequestContent1} [requestContent1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createContent(requestContent1?: RequestContent1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createContent(requestContent1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した投稿を削除
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async deleteContent(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.deleteContent(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * エリアを取得する。
         * @summary Your GET endpoint
         * @param {string} areaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getArea(areaId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Area1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getArea(areaId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * エリア一覧を返す
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAreas(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Area1>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAreas(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カテゴリー一覧を返す
         * @summary Your GET endpoint
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategories(query?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Category>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategories(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * カテゴリを取得する。
         * @summary Your GET endpoint
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getCategory(categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Category>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getCategory(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した投稿を返す
         * @summary Your GET endpoint
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContent(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContent(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} [range] 座標範囲をカンマ区切りで指定 例　range&#x3D;36.56234723572857,138.0301165403568,35.608792611587035,139.4455926280639
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {string} [pageSize] 
         * @param {number} [category] (実装しない)
         * @param {string} [tags] tag_idをカンマ区切りで指定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContents(range?: string, sort?: string, query?: string, page?: number, pageSize?: string, category?: number, tags?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Content>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContents(range, sort, query, page, pageSize, category, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したエリアに含まれる投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} areaId 
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentsByArea(areaId: string, sort?: string, query?: string, page?: number, pageSize?: number, range?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Content>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentsByArea(areaId, sort, query, page, pageSize, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 認証済みユーザーが投稿した投稿一覧を返す
         * @summary get-user-contents
         * @param {string} [range] 座標範囲をカンマ区切りで指定
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {number} [category] (実装しない)
         * @param {string} [tags] tag_idをカンマ区切りで設定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentsByAuthenticatedUser(range?: string, sort?: string, query?: string, page?: number, pageSize?: number, category?: number, tags?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Content>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentsByAuthenticatedUser(range, sort, query, page, pageSize, category, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したカテゴリが付与された投稿一覧を返す（実装保留）
         * @summary Your GET endpoint
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentsByCategory(categoryId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Content>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentsByCategory(categoryId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したタグが付与された投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} tagName 
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentsByTag(tagName: string, sort?: string, query?: string, page?: number, pageSize?: number, range?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Content>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentsByTag(tagName, sort, query, page, pageSize, range, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したユーザーが投稿した投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} username 
         * @param {string} [range] 座標範囲をカンマ区切りで指定
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {number} [category] (実装しない)
         * @param {string} [tags] tag_idをカンマ区切りで設定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getContentsByUser(username: string, range?: string, sort?: string, query?: string, page?: number, pageSize?: number, category?: number, tags?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Content>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getContentsByUser(username, range, sort, query, page, pageSize, category, tags, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 認証済みユーザーがお気に入りに追加した投稿一覧を返す
         * @summary Your GET endpoint
         * @param {number} [category] (実装しない)
         * @param {string} [tags] (実装しない)
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getFavoriteContentsByAuthenticatedUser(category?: number, tags?: string, sort?: string, query?: string, range?: string, page?: number, pageSize?: number, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Content>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getFavoriteContentsByAuthenticatedUser(category, tags, sort, query, range, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * タグを取得する。
         * @summary Your GET endpoint
         * @param {string} tagName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTag(tagName: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Tag>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTag(tagName, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * タグ一覧を返す
         * @summary Your GET endpoint
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getTags(query?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Array<Tag>>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getTags(query, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した投稿に「いいね」をつける
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async likeContent(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.likeContent(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * Search Place and Contents
         * @summary Your GET endpoint
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {string} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async search(query?: string, page?: number, pageSize?: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<InlineResponse200>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.search(query, page, pageSize, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した記事をお気に入りから外す
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async takeFavorite(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.takeFavorite(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した投稿から「いいね」を外す
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async unlikeContent(contentId: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<void>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.unlikeContent(contentId, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した投稿を更新
         * @param {string} contentId 
         * @param {RequestContent} [requestContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateContent(contentId: string, requestContent?: RequestContent, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateContent(contentId, requestContent, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定した投稿に対し、画像をアップロード（置き換え）する
         * @param {string} contentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadContentImage(contentId: string, file: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Content>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadContentImage(contentId, file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ContentApi - factory interface
 * @export
 */
export const ContentApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ContentApiFp(configuration)
    return {
        /**
         * 指定した記事をお気に入りに追加する
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        addFavorite(contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.addFavorite(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿にお気に入りに追加済みかを取得
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkFavoriteContent(contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkFavoriteContent(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿に「いいね」を付けたかを取得
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        checkLikeContent(contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.checkLikeContent(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 新規に投稿する(タグも付与)
         * @param {RequestContent1} [requestContent1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createContent(requestContent1?: RequestContent1, options?: any): AxiosPromise<Content> {
            return localVarFp.createContent(requestContent1, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿を削除
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        deleteContent(contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.deleteContent(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * エリアを取得する。
         * @summary Your GET endpoint
         * @param {string} areaId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getArea(areaId: string, options?: any): AxiosPromise<Area1> {
            return localVarFp.getArea(areaId, options).then((request) => request(axios, basePath));
        },
        /**
         * エリア一覧を返す
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAreas(options?: any): AxiosPromise<Array<Area1>> {
            return localVarFp.getAreas(options).then((request) => request(axios, basePath));
        },
        /**
         * カテゴリー一覧を返す
         * @summary Your GET endpoint
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategories(query?: string, options?: any): AxiosPromise<Array<Category>> {
            return localVarFp.getCategories(query, options).then((request) => request(axios, basePath));
        },
        /**
         * カテゴリを取得する。
         * @summary Your GET endpoint
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getCategory(categoryId: string, options?: any): AxiosPromise<Category> {
            return localVarFp.getCategory(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿を返す
         * @summary Your GET endpoint
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContent(contentId: string, options?: any): AxiosPromise<Content> {
            return localVarFp.getContent(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} [range] 座標範囲をカンマ区切りで指定 例　range&#x3D;36.56234723572857,138.0301165403568,35.608792611587035,139.4455926280639
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {string} [pageSize] 
         * @param {number} [category] (実装しない)
         * @param {string} [tags] tag_idをカンマ区切りで指定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContents(range?: string, sort?: string, query?: string, page?: number, pageSize?: string, category?: number, tags?: string, options?: any): AxiosPromise<Array<Content>> {
            return localVarFp.getContents(range, sort, query, page, pageSize, category, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したエリアに含まれる投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} areaId 
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByArea(areaId: string, sort?: string, query?: string, page?: number, pageSize?: number, range?: string, options?: any): AxiosPromise<Array<Content>> {
            return localVarFp.getContentsByArea(areaId, sort, query, page, pageSize, range, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証済みユーザーが投稿した投稿一覧を返す
         * @summary get-user-contents
         * @param {string} [range] 座標範囲をカンマ区切りで指定
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {number} [category] (実装しない)
         * @param {string} [tags] tag_idをカンマ区切りで設定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByAuthenticatedUser(range?: string, sort?: string, query?: string, page?: number, pageSize?: number, category?: number, tags?: string, options?: any): AxiosPromise<Array<Content>> {
            return localVarFp.getContentsByAuthenticatedUser(range, sort, query, page, pageSize, category, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したカテゴリが付与された投稿一覧を返す（実装保留）
         * @summary Your GET endpoint
         * @param {string} categoryId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByCategory(categoryId: string, options?: any): AxiosPromise<Array<Content>> {
            return localVarFp.getContentsByCategory(categoryId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したタグが付与された投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} tagName 
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByTag(tagName: string, sort?: string, query?: string, page?: number, pageSize?: number, range?: string, options?: any): AxiosPromise<Array<Content>> {
            return localVarFp.getContentsByTag(tagName, sort, query, page, pageSize, range, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したユーザーが投稿した投稿一覧を返す
         * @summary Your GET endpoint
         * @param {string} username 
         * @param {string} [range] 座標範囲をカンマ区切りで指定
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {number} [category] (実装しない)
         * @param {string} [tags] tag_idをカンマ区切りで設定(実装しない)
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getContentsByUser(username: string, range?: string, sort?: string, query?: string, page?: number, pageSize?: number, category?: number, tags?: string, options?: any): AxiosPromise<Array<Content>> {
            return localVarFp.getContentsByUser(username, range, sort, query, page, pageSize, category, tags, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証済みユーザーがお気に入りに追加した投稿一覧を返す
         * @summary Your GET endpoint
         * @param {number} [category] (実装しない)
         * @param {string} [tags] (実装しない)
         * @param {string} [sort] 
         * @param {string} [query] 
         * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
         * @param {number} [page] ページ位置
         * @param {number} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getFavoriteContentsByAuthenticatedUser(category?: number, tags?: string, sort?: string, query?: string, range?: string, page?: number, pageSize?: number, options?: any): AxiosPromise<Array<Content>> {
            return localVarFp.getFavoriteContentsByAuthenticatedUser(category, tags, sort, query, range, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * タグを取得する。
         * @summary Your GET endpoint
         * @param {string} tagName 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTag(tagName: string, options?: any): AxiosPromise<Tag> {
            return localVarFp.getTag(tagName, options).then((request) => request(axios, basePath));
        },
        /**
         * タグ一覧を返す
         * @summary Your GET endpoint
         * @param {string} [query] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getTags(query?: string, options?: any): AxiosPromise<Array<Tag>> {
            return localVarFp.getTags(query, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿に「いいね」をつける
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        likeContent(contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.likeContent(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * Search Place and Contents
         * @summary Your GET endpoint
         * @param {string} [query] 
         * @param {number} [page] ページ位置
         * @param {string} [pageSize] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        search(query?: string, page?: number, pageSize?: string, options?: any): AxiosPromise<InlineResponse200> {
            return localVarFp.search(query, page, pageSize, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した記事をお気に入りから外す
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        takeFavorite(contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.takeFavorite(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿から「いいね」を外す
         * @param {string} contentId 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        unlikeContent(contentId: string, options?: any): AxiosPromise<void> {
            return localVarFp.unlikeContent(contentId, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿を更新
         * @param {string} contentId 
         * @param {RequestContent} [requestContent] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateContent(contentId: string, requestContent?: RequestContent, options?: any): AxiosPromise<Content> {
            return localVarFp.updateContent(contentId, requestContent, options).then((request) => request(axios, basePath));
        },
        /**
         * 指定した投稿に対し、画像をアップロード（置き換え）する
         * @param {string} contentId 
         * @param {any} file 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadContentImage(contentId: string, file: any, options?: any): AxiosPromise<Content> {
            return localVarFp.uploadContentImage(contentId, file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ContentApi - object-oriented interface
 * @export
 * @class ContentApi
 * @extends {BaseAPI}
 */
export class ContentApi extends BaseAPI {
    /**
     * 指定した記事をお気に入りに追加する
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public addFavorite(contentId: string, options?: any) {
        return ContentApiFp(this.configuration).addFavorite(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿にお気に入りに追加済みかを取得
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public checkFavoriteContent(contentId: string, options?: any) {
        return ContentApiFp(this.configuration).checkFavoriteContent(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿に「いいね」を付けたかを取得
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public checkLikeContent(contentId: string, options?: any) {
        return ContentApiFp(this.configuration).checkLikeContent(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 新規に投稿する(タグも付与)
     * @param {RequestContent1} [requestContent1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public createContent(requestContent1?: RequestContent1, options?: any) {
        return ContentApiFp(this.configuration).createContent(requestContent1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿を削除
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public deleteContent(contentId: string, options?: any) {
        return ContentApiFp(this.configuration).deleteContent(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリアを取得する。
     * @summary Your GET endpoint
     * @param {string} areaId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getArea(areaId: string, options?: any) {
        return ContentApiFp(this.configuration).getArea(areaId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * エリア一覧を返す
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getAreas(options?: any) {
        return ContentApiFp(this.configuration).getAreas(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カテゴリー一覧を返す
     * @summary Your GET endpoint
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getCategories(query?: string, options?: any) {
        return ContentApiFp(this.configuration).getCategories(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * カテゴリを取得する。
     * @summary Your GET endpoint
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getCategory(categoryId: string, options?: any) {
        return ContentApiFp(this.configuration).getCategory(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿を返す
     * @summary Your GET endpoint
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContent(contentId: string, options?: any) {
        return ContentApiFp(this.configuration).getContent(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 投稿一覧を返す
     * @summary Your GET endpoint
     * @param {string} [range] 座標範囲をカンマ区切りで指定 例　range&#x3D;36.56234723572857,138.0301165403568,35.608792611587035,139.4455926280639
     * @param {string} [sort] 
     * @param {string} [query] 
     * @param {number} [page] ページ位置
     * @param {string} [pageSize] 
     * @param {number} [category] (実装しない)
     * @param {string} [tags] tag_idをカンマ区切りで指定(実装しない)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContents(range?: string, sort?: string, query?: string, page?: number, pageSize?: string, category?: number, tags?: string, options?: any) {
        return ContentApiFp(this.configuration).getContents(range, sort, query, page, pageSize, category, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したエリアに含まれる投稿一覧を返す
     * @summary Your GET endpoint
     * @param {string} areaId 
     * @param {string} [sort] 
     * @param {string} [query] 
     * @param {number} [page] ページ位置
     * @param {number} [pageSize] 
     * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentsByArea(areaId: string, sort?: string, query?: string, page?: number, pageSize?: number, range?: string, options?: any) {
        return ContentApiFp(this.configuration).getContentsByArea(areaId, sort, query, page, pageSize, range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証済みユーザーが投稿した投稿一覧を返す
     * @summary get-user-contents
     * @param {string} [range] 座標範囲をカンマ区切りで指定
     * @param {string} [sort] 
     * @param {string} [query] 
     * @param {number} [page] ページ位置
     * @param {number} [pageSize] 
     * @param {number} [category] (実装しない)
     * @param {string} [tags] tag_idをカンマ区切りで設定(実装しない)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentsByAuthenticatedUser(range?: string, sort?: string, query?: string, page?: number, pageSize?: number, category?: number, tags?: string, options?: any) {
        return ContentApiFp(this.configuration).getContentsByAuthenticatedUser(range, sort, query, page, pageSize, category, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したカテゴリが付与された投稿一覧を返す（実装保留）
     * @summary Your GET endpoint
     * @param {string} categoryId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentsByCategory(categoryId: string, options?: any) {
        return ContentApiFp(this.configuration).getContentsByCategory(categoryId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したタグが付与された投稿一覧を返す
     * @summary Your GET endpoint
     * @param {string} tagName 
     * @param {string} [sort] 
     * @param {string} [query] 
     * @param {number} [page] ページ位置
     * @param {number} [pageSize] 
     * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentsByTag(tagName: string, sort?: string, query?: string, page?: number, pageSize?: number, range?: string, options?: any) {
        return ContentApiFp(this.configuration).getContentsByTag(tagName, sort, query, page, pageSize, range, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したユーザーが投稿した投稿一覧を返す
     * @summary Your GET endpoint
     * @param {string} username 
     * @param {string} [range] 座標範囲をカンマ区切りで指定
     * @param {string} [sort] 
     * @param {string} [query] 
     * @param {number} [page] ページ位置
     * @param {number} [pageSize] 
     * @param {number} [category] (実装しない)
     * @param {string} [tags] tag_idをカンマ区切りで設定(実装しない)
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getContentsByUser(username: string, range?: string, sort?: string, query?: string, page?: number, pageSize?: number, category?: number, tags?: string, options?: any) {
        return ContentApiFp(this.configuration).getContentsByUser(username, range, sort, query, page, pageSize, category, tags, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証済みユーザーがお気に入りに追加した投稿一覧を返す
     * @summary Your GET endpoint
     * @param {number} [category] (実装しない)
     * @param {string} [tags] (実装しない)
     * @param {string} [sort] 
     * @param {string} [query] 
     * @param {string} [range] 座標範囲をカンマ区切りで指定(実装しない)
     * @param {number} [page] ページ位置
     * @param {number} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getFavoriteContentsByAuthenticatedUser(category?: number, tags?: string, sort?: string, query?: string, range?: string, page?: number, pageSize?: number, options?: any) {
        return ContentApiFp(this.configuration).getFavoriteContentsByAuthenticatedUser(category, tags, sort, query, range, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * タグを取得する。
     * @summary Your GET endpoint
     * @param {string} tagName 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getTag(tagName: string, options?: any) {
        return ContentApiFp(this.configuration).getTag(tagName, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * タグ一覧を返す
     * @summary Your GET endpoint
     * @param {string} [query] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public getTags(query?: string, options?: any) {
        return ContentApiFp(this.configuration).getTags(query, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿に「いいね」をつける
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public likeContent(contentId: string, options?: any) {
        return ContentApiFp(this.configuration).likeContent(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * Search Place and Contents
     * @summary Your GET endpoint
     * @param {string} [query] 
     * @param {number} [page] ページ位置
     * @param {string} [pageSize] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public search(query?: string, page?: number, pageSize?: string, options?: any) {
        return ContentApiFp(this.configuration).search(query, page, pageSize, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した記事をお気に入りから外す
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public takeFavorite(contentId: string, options?: any) {
        return ContentApiFp(this.configuration).takeFavorite(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿から「いいね」を外す
     * @param {string} contentId 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public unlikeContent(contentId: string, options?: any) {
        return ContentApiFp(this.configuration).unlikeContent(contentId, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿を更新
     * @param {string} contentId 
     * @param {RequestContent} [requestContent] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public updateContent(contentId: string, requestContent?: RequestContent, options?: any) {
        return ContentApiFp(this.configuration).updateContent(contentId, requestContent, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定した投稿に対し、画像をアップロード（置き換え）する
     * @param {string} contentId 
     * @param {any} file 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ContentApi
     */
    public uploadContentImage(contentId: string, file: any, options?: any) {
        return ContentApiFp(this.configuration).uploadContentImage(contentId, file, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * ReportApi - axios parameter creator
 * @export
 */
export const ReportApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * 報告する
         * @param {RequestReport1} [requestReport1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport: async (requestReport1?: RequestReport1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/reports`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestReport1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * ReportApi - functional programming interface
 * @export
 */
export const ReportApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = ReportApiAxiosParamCreator(configuration)
    return {
        /**
         * 報告する
         * @param {RequestReport1} [requestReport1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createReport(requestReport1?: RequestReport1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<Report1>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createReport(requestReport1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * ReportApi - factory interface
 * @export
 */
export const ReportApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = ReportApiFp(configuration)
    return {
        /**
         * 報告する
         * @param {RequestReport1} [requestReport1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createReport(requestReport1?: RequestReport1, options?: any): AxiosPromise<Report1> {
            return localVarFp.createReport(requestReport1, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * ReportApi - object-oriented interface
 * @export
 * @class ReportApi
 * @extends {BaseAPI}
 */
export class ReportApi extends BaseAPI {
    /**
     * 報告する
     * @param {RequestReport1} [requestReport1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof ReportApi
     */
    public createReport(requestReport1?: RequestReport1, options?: any) {
        return ReportApiFp(this.configuration).createReport(requestReport1, options).then((request) => request(this.axios, this.basePath));
    }
}


/**
 * UserApi - axios parameter creator
 * @export
 */
export const UserApiAxiosParamCreator = function (configuration?: Configuration) {
    return {
        /**
         * ユーザーを登録
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'POST', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証済み（ログイン済み）ユーザーのプロフィールを取得
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUser: async (options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 指定したユーザーのプロフィールを取得
         * @summary Your GET endpoint
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser: async (username: string, options: any = {}): Promise<RequestArgs> => {
            // verify required parameter 'username' is not null or undefined
            assertParamExists('getUser', 'username', username)
            const localVarPath = `/users/{username}`
                .replace(`{${"username"}}`, encodeURIComponent(String(username)));
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'GET', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証済みユーザーのプロフィールを更新
         * @param {RequestUser1} [requestUser1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthenticatedUser: async (requestUser1?: RequestUser1, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PATCH', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;


    
            localVarHeaderParameter['Content-Type'] = 'application/json';

            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = serializeDataIfNeeded(requestUser1, localVarRequestOptions, configuration)

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
        /**
         * 認証済み（ログイン済み）ユーザーのプロフィール画像をアップロード（置き換え）する。 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAuthenticatedUserImage: async (file?: any, options: any = {}): Promise<RequestArgs> => {
            const localVarPath = `/user/image`;
            // use dummy base URL string because the URL constructor only accepts absolute URLs.
            const localVarUrlObj = new URL(localVarPath, DUMMY_BASE_URL);
            let baseOptions;
            if (configuration) {
                baseOptions = configuration.baseOptions;
            }

            const localVarRequestOptions = { method: 'PUT', ...baseOptions, ...options};
            const localVarHeaderParameter = {} as any;
            const localVarQueryParameter = {} as any;
            const localVarFormParams = new ((configuration && configuration.formDataCtor) || FormData)();


            if (file !== undefined) { 
                localVarFormParams.append('file', file as any);
            }
    
    
            localVarHeaderParameter['Content-Type'] = 'multipart/form-data';
    
            setSearchParams(localVarUrlObj, localVarQueryParameter, options.query);
            let headersFromBaseOptions = baseOptions && baseOptions.headers ? baseOptions.headers : {};
            localVarRequestOptions.headers = {...localVarHeaderParameter, ...headersFromBaseOptions, ...options.headers};
            localVarRequestOptions.data = localVarFormParams;

            return {
                url: toPathString(localVarUrlObj),
                options: localVarRequestOptions,
            };
        },
    }
};

/**
 * UserApi - functional programming interface
 * @export
 */
export const UserApiFp = function(configuration?: Configuration) {
    const localVarAxiosParamCreator = UserApiAxiosParamCreator(configuration)
    return {
        /**
         * ユーザーを登録
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async createUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<RequestUser>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.createUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 認証済み（ログイン済み）ユーザーのプロフィールを取得
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getAuthenticatedUser(options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getAuthenticatedUser(options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 指定したユーザーのプロフィールを取得
         * @summary Your GET endpoint
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async getUser(username: string, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.getUser(username, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 認証済みユーザーのプロフィールを更新
         * @param {RequestUser1} [requestUser1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async updateAuthenticatedUser(requestUser1?: RequestUser1, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.updateAuthenticatedUser(requestUser1, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
        /**
         * 認証済み（ログイン済み）ユーザーのプロフィール画像をアップロード（置き換え）する。 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        async uploadAuthenticatedUserImage(file?: any, options?: any): Promise<(axios?: AxiosInstance, basePath?: string) => AxiosPromise<User>> {
            const localVarAxiosArgs = await localVarAxiosParamCreator.uploadAuthenticatedUserImage(file, options);
            return createRequestFunction(localVarAxiosArgs, globalAxios, BASE_PATH, configuration);
        },
    }
};

/**
 * UserApi - factory interface
 * @export
 */
export const UserApiFactory = function (configuration?: Configuration, basePath?: string, axios?: AxiosInstance) {
    const localVarFp = UserApiFp(configuration)
    return {
        /**
         * ユーザーを登録
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        createUser(options?: any): AxiosPromise<RequestUser> {
            return localVarFp.createUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 認証済み（ログイン済み）ユーザーのプロフィールを取得
         * @summary Your GET endpoint
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getAuthenticatedUser(options?: any): AxiosPromise<User> {
            return localVarFp.getAuthenticatedUser(options).then((request) => request(axios, basePath));
        },
        /**
         * 指定したユーザーのプロフィールを取得
         * @summary Your GET endpoint
         * @param {string} username 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        getUser(username: string, options?: any): AxiosPromise<User> {
            return localVarFp.getUser(username, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証済みユーザーのプロフィールを更新
         * @param {RequestUser1} [requestUser1] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        updateAuthenticatedUser(requestUser1?: RequestUser1, options?: any): AxiosPromise<User> {
            return localVarFp.updateAuthenticatedUser(requestUser1, options).then((request) => request(axios, basePath));
        },
        /**
         * 認証済み（ログイン済み）ユーザーのプロフィール画像をアップロード（置き換え）する。 
         * @param {any} [file] 
         * @param {*} [options] Override http request option.
         * @throws {RequiredError}
         */
        uploadAuthenticatedUserImage(file?: any, options?: any): AxiosPromise<User> {
            return localVarFp.uploadAuthenticatedUserImage(file, options).then((request) => request(axios, basePath));
        },
    };
};

/**
 * UserApi - object-oriented interface
 * @export
 * @class UserApi
 * @extends {BaseAPI}
 */
export class UserApi extends BaseAPI {
    /**
     * ユーザーを登録
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public createUser(options?: any) {
        return UserApiFp(this.configuration).createUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証済み（ログイン済み）ユーザーのプロフィールを取得
     * @summary Your GET endpoint
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getAuthenticatedUser(options?: any) {
        return UserApiFp(this.configuration).getAuthenticatedUser(options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 指定したユーザーのプロフィールを取得
     * @summary Your GET endpoint
     * @param {string} username 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public getUser(username: string, options?: any) {
        return UserApiFp(this.configuration).getUser(username, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証済みユーザーのプロフィールを更新
     * @param {RequestUser1} [requestUser1] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public updateAuthenticatedUser(requestUser1?: RequestUser1, options?: any) {
        return UserApiFp(this.configuration).updateAuthenticatedUser(requestUser1, options).then((request) => request(this.axios, this.basePath));
    }

    /**
     * 認証済み（ログイン済み）ユーザーのプロフィール画像をアップロード（置き換え）する。 
     * @param {any} [file] 
     * @param {*} [options] Override http request option.
     * @throws {RequiredError}
     * @memberof UserApi
     */
    public uploadAuthenticatedUserImage(file?: any, options?: any) {
        return UserApiFp(this.configuration).uploadAuthenticatedUserImage(file, options).then((request) => request(this.axios, this.basePath));
    }
}


