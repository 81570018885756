import { Tag } from 'components/atoms/Tag';
import { IconButton } from 'components/molecules/IconButton';
import { PostProfile } from 'components/molecules/PostProfile';
import React from 'react';
import { BiFilterAlt as Filter } from 'react-icons/bi';
import './style.css';

export interface TagProps {
  Map: JSX.Element;
  PostList: JSX.Element;
  area: string;
  postNum: number;
  tags: {
    text: string;
    path: string;
  }[];
  searchValue?: string;
  handleClickFilter?: () => void;
  handleResetSearchValue?: () => void;
}

export const TagPage: React.FC<TagProps> = ({
  Map,
  PostList,
  area,
  postNum,
  tags,
  searchValue,
  handleClickFilter,
  handleResetSearchValue,
}: TagProps) => {
  const cn = 'tag';
  return (
    <div className={cn}>
      <div id="main-map" className={`${cn}__map page__map`}>
        {Map}
      </div>
      <div id="main-contents" className={`page__contents`}>
        <div className={`${cn}__tags`}>
          <PostProfile area={area} postNum={postNum} tags={tags} />
        </div>
        <div className={`page__filter`}>
          <div className={`page__btn-group`}>
            <IconButton
              onClick={() => {
                handleClickFilter && handleClickFilter();
              }}
              Icon={Filter}
              text={'絞り込む'}
              variant="outline-gray"
            />
          </div>
          <div className={`page__tag-group`}>
            {searchValue ? (
              <Tag text={searchValue} handleDelete={handleResetSearchValue} />
            ) : null}
          </div>
        </div>
        <div id="content-list" className={`${cn}__post-list`}>
          {PostList}
        </div>
      </div>
    </div>
  );
};
