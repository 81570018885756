import { AxiosResponse } from 'axios';
import { AreaContents, Content } from 'models/Content';
import { ContentApiFactory } from 'services/api';
import { Area } from 'services/api/api';
import { action, ActionTypes } from './action';
import { types } from './actionTypes';

export type AreaTypes = Area;
export type AreasTypes = AreaTypes[];

interface ApiFetchSuccess {
  type: typeof types.API_FETCH_SUCCESS;
  data: AreasTypes;
  areaContents: AreaContents[] | null;
}

export type AreaActionTypes = ActionTypes | ApiFetchSuccess;

// 全てのタグを取得する。（子のタグも含めて取得する）
export const getAreas = async (): Promise<AreaActionTypes> => {
  const actionCallBack = async () => {
    const { getAreas } = ContentApiFactory();
    const tmp = await getAreas();
    const data = tmp.data as AreasTypes;
    return { type: types.API_FETCH_SUCCESS, data, areaContents: null };
  };

  return await action<AreaActionTypes>(actionCallBack);
};

/**
 * Todo:
 * PostWithCommentsコンポーネントに反映するコメントデータを、
 * CommentApiFactory().getComment()から取得後、最新のコメントデータを抽出して
 * UI上に表示する必要あり。これらの処理をクライアント側で対応するのか？
 */
export const getAreaContents = async (): Promise<AreaActionTypes> => {
  const actionCallBack = async () => {
    const { getContentsByTag } = ContentApiFactory();
    // 親タグと子データをマッピングするための配列
    // 親タグ(ex.北海道、東北、...)の一覧を取得 ※決め打ちで指定可能なら省略
    const { getAreas } = ContentApiFactory();
    const resAreas = await getAreas();
    const areas = resAreas.data as AreasTypes;

    // 取得したタグに紐づく投稿一覧を取得
    const promiseList: Promise<AxiosResponse<Content[]>>[] = [];
    areas.forEach((area) => {
      const { name } = area as AreaTypes;
      const contents = async () => await getContentsByTag(String(name));
      promiseList.push(contents());
    });

    const tmp = await Promise.all(promiseList);
    // 扱いやすいようデータを加工
    const res: AreaContents[] = areas.map((area: Area, i) => {
      const contents = tmp[i].data;
      return { area, contents };
    });
    return { type: types.API_FETCH_SUCCESS, data: areas, areaContents: res };
  };
  return await action<AreaActionTypes>(actionCallBack);
};
