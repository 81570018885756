import { types } from 'modules/actions/actionTypes';
import { TagTypes, ActionTypes } from 'modules/actions/tag';

type State = TagTypes | null;

//TODO stateの構成を変更,Userと同じにする
export default (state: State, action: ActionTypes): State => {
  const { type, data } = action;
  switch (type) {
    case types.API_TAG:
      return Object.assign([], state, data);
    default:
      return state;
  }
};
