import React, { useState } from 'react';
import {
  CommentCard,
  CommentCardProps,
} from 'components/molecules/CommentCard';

import './style.css';

// extends CommentCardProps
export interface CommentCardWithImageProps {
  size?: 'sm' | 'lg' | 'xl';
  img: string;
  alt?: string;
  commentCardProps: CommentCardProps;
}

export const CommentCardWithImage: React.FC<CommentCardWithImageProps> = ({
  size = 'xl',
  img,
  alt = '',
  commentCardProps,
}: CommentCardWithImageProps) => {
  const cn = 'comment-img';

  return (
    <div className={`${cn} ${cn}--${size}`}>
      <div className={`${cn}__img ${cn}__img--${size}`}>
        <img src={img} alt={alt} />
      </div>
      <div className={`${cn}__comment ${cn}__comment--${size}`}>
        <CommentCard {...commentCardProps} />
      </div>
    </div>
  );
};
