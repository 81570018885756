import { Content } from 'models/Content';
import moment from 'moment';

import { RequestContent } from 'modules/actions/content';

export interface FormValuesProps {
  uploadImg?: string;
  file?: File;
  comment: string;
  areaTags: string[];
  tags: string[];
  latitude: string;
  longitude: string;
  postedTime: string;
  mapCode: string;
  checked: boolean;
}

export const useForm = () => {
  const changeToInitValues = (content: Content) => {
    const { latitude, longitude, content_image_url, tags, posted_time } =
      content;
    const areaTags =
      tags != null
        ? tags.filter((tag) => tag.is_area).map((tag) => tag.name)
        : [];
    const _tags =
      tags != null
        ? tags.filter((tag) => !tag.is_area).map((tag) => tag.name)
        : [];
    console.log(tags, 'tags');

    const initValues: FormValuesProps = {
      uploadImg: content_image_url,
      file: undefined, //TODO 初期値はundefined?
      comment: content.body,
      areaTags: areaTags,
      tags: _tags,
      latitude: String(latitude),
      longitude: String(longitude),
      postedTime: posted_time,
      mapCode: `${latitude}, ${longitude}`,
      checked: true,
    };

    console.log(initValues.comment, 'changeToInitValues');
    return initValues;
  };

  const getInitValues = (position?: string | null) => {
    const { latitude, longitude, mapCode } = getInitMapCode(position);
    const initValues: FormValuesProps = {
      uploadImg: '',
      file: undefined, //TODO 初期値はundefined?
      areaTags: [],
      tags: [],
      comment: '',
      postedTime: moment().format(),
      latitude: latitude,
      longitude: longitude,
      mapCode: mapCode,
      checked: true,
    };
    return initValues;
  };
  // PostFormコンポーネントのFormデータを、リクエスト用に加工
  const makePostFormData = (values: FormValuesProps): RequestContent => {
    const { comment, postedTime } = values;
    const latitude = Number(values.latitude);
    const longitude = Number(values.longitude);
    const tags = values.areaTags.concat(values.tags).map((tag: string) => {
      return { name: tag };
    });
    // リクエストを構築
    const formVals: RequestContent = {
      body: comment,
      tags,
      latitude,
      longitude,
      posted_time: postedTime,
    };
    return formVals;
  };

  // マップコードを{'緯度', '経度'}の形式に加工
  const makeMapCode = (mapCode: string) => {
    const base = 1000000;
    const tmp = mapCode.split(',');
    const latNum = Number(tmp[0].trim());
    const lngNum = Number(tmp[1].trim());
    const latitude = Math.floor(latNum * base) / base;
    const longitude = Math.floor(lngNum * base) / base;
    return {
      latitude: String(latitude),
      longitude: String(longitude),
      mapCode: `${latitude}, ${longitude}`,
    };
  };

  // マップコードを{'緯度', '経度'}の形式に加工
  const getInitMapCode = (mapCode?: string | null) => {
    return mapCode && mapCodeReg.test(mapCode)
      ? makeMapCode(mapCode)
      : { latitude: '', longitude: '', mapCode: '' };
  };

  // マップコードの正規表現(小数第6位まで有効桁数とする)
  const mapCodeRegStr =
    '^[-]?([1-8]?\\d(\\.\\d+)?|90(\\.0+)?),\\s*[-]?(180(\\.0+)?|((1[0-7]\\d)|([1-9]?\\d))(\\.\\d+)?)\\s*$';
  const mapCodeReg = new RegExp(mapCodeRegStr);

  return {
    makePostFormData,
    makeMapCode,
    getInitMapCode,
    mapCodeRegStr,
    mapCodeReg,
    changeToInitValues,
    getInitValues,
  };
};
