import React, { useState, useRef, useEffect } from 'react';
import { Form } from 'react-bootstrap';
import { Button } from 'components/atoms/Button';
import { LoadingButton } from 'components/molecules/LoadingButton';
import { RequestUserTypes } from 'modules/actions/authenticated-user';
import { MainTitle } from 'components/atoms/MainTitle';

import './style.css';

export interface StartFormValues {
  file?: File;
  userID?: string;
  username: string;
  nickname: string;
}

export interface StartFormProps {
  width?: string | number;
  values: StartFormValues;
  isLoading?: boolean;
  submit?: (data: RequestUserTypes) => void;
  logout?: () => void;
}

interface State {
  values: StartFormValues;
  isValid: boolean;
}

type Input = React.ChangeEvent<HTMLInputElement>;

const regStr = '[a-zA-Z0-9]*';
const reg = new RegExp(regStr);

export const StartForm: React.FC<StartFormProps> = ({
  width = '100%',
  values,
  isLoading = false,
  submit,
  logout,
}: StartFormProps) => {
  const cn = 'start-form';
  const formEl = useRef<HTMLFormElement | null>(null);
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const [state, setState] = useState<State>({
    values,
    isValid: false,
  });

  console.log(values, 'valu');

  const onClickSubmit = () => {
    const form = formEl.current;
    if (!form) throw new Error('Form要素のRef参照を確認');
    // 各入力項目のバリデーション
    if (!form.checkValidity()) {
      setState({ ...state, isValid: true });
      return;
    }

    const data = {
      nickname: state.values.nickname,
      username: state.values.username,
    };
    submit && submit(data);

    const values = { ...state.values };
    setState({ ...state, values });
  };

  const onClickCancel = () => {
    logout && logout();
  };

  const ButtonGroup = isLoading ? (
    <div className={`${cn}__btn-group`}>
      <div className={`${cn}__btn`}>
        <LoadingButton text="登録中" />
        <div className={`${cn}__cancel-btn`}>登録を中断する</div>
      </div>
    </div>
  ) : (
    <div className={`${cn}__btn-group`}>
      <div className={`${cn}__btn`}>
        <Button onClick={onClickSubmit}>登録する</Button>
        <div
          className={`${cn}__cancel-btn`}
          onClick={() => {
            logout && logout();
          }}
        >
          登録を中断する
        </div>
      </div>
    </div>
  );

  return (
    <section className={cn} style={{ width }}>
      <div className={`${cn}__title`}>
        <MainTitle alt="DigLee" src="/diglee.png" />
      </div>
      <div className={`${cn}__text-components`}>
        <p>次にユーザー登録をしましょう。</p>
      </div>
      <div className={`${cn}__form`}>
        <Form ref={formEl} noValidate validated={state.isValid}>
          <Form.Group className={`${cn}__group`}>
            <Form.Label className={`${cn}__label`}>ユーザーID</Form.Label>
            <Form.Control
              required
              type="text"
              name="username"
              placeholder=""
              value={state.values.username}
              maxLength={20}
              minLength={4}
              pattern={regStr}
              onChange={(e: Input) => {
                const values = { ...state.values, username: e.target.value };
                setState({ ...state, values });
              }}
            />
            {state.isValid ? (
              <>
                <Form.Control.Feedback type="invalid">
                  4〜20文字の半角英数字入力してください
                </Form.Control.Feedback>
                <Form.Control.Feedback>
                  4〜20文字 (半角英数字)
                </Form.Control.Feedback>
              </>
            ) : (
              <Form.Label className={`${cn}__label--sub`}>
                4〜20文字 (半角英数字)
              </Form.Label>
            )}
          </Form.Group>
          <Form.Group className={`${cn}__group`}>
            <Form.Label className={`${cn}__label`}>ニックネーム</Form.Label>
            <Form.Control
              type="text"
              name="nickname"
              placeholder="20文字まで"
              value={state.values.nickname}
              maxLength={20}
              onChange={(e: Input) => {
                const values = { ...state.values, nickname: e.target.value };
                setState({ ...state, values });
              }}
            />
            <Form.Label className={`${cn}__label--sub`}>20文字まで</Form.Label>
          </Form.Group>
        </Form>
      </div>
      {ButtonGroup}
    </section>
  );
};
