import React from 'react';

import './style.css';

export interface KeywordListProps {
  children: React.ReactNode;
}

export const KeywordList: React.FC<KeywordListProps> = ({
  children,
}: KeywordListProps) => {
  const cn = 'keyword-list';
  const items = React.Children.map(children, (child, i) => (
    <li key={i} className={`${cn}__item`}>
      {child}
    </li>
  ));
  return <ul className={cn}>{items}</ul>;
};
