import React from 'react';
import { SVGPostText } from 'components/atoms/SVGPostText';

import './style.css';

export interface SnapIconProps {
  width?: string | number;
  handleClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
}

export const SnapIcon: React.FC<SnapIconProps> = ({
  width = '100%',
  handleClick,
}: SnapIconProps) => {
  const cn = 'snap-icon';
  return (
    <div className={cn} onClick={handleClick}>
      <img
        className={`${cn}__img`}
        src="/snap.png"
        alt="SnapIcon"
        style={{ width }}
      />
    </div>
  );
};
