import React, { useState, useReducer, useEffect } from 'react';
import reducer, { ContentState } from 'modules/reducers/content';
import { types } from 'modules/actions/actionTypes';
import {
  getContent,
  createContent,
  updateContent,
  RequestContent,
} from 'modules/actions/content';

import {
  resetRequest as _resetRequest,
  startRequest as _startRequest,
} from 'modules/actions/action';
/* import { checkLikeContent,likeContent, unlikeContent,checkFavorite,addFavorite,takeFavorite} from 'modules/actions/extension' */
/* import {likeContentReducer,favoriteReducer} from 'modules/reducers/content-extension' */

export const useContent = (initialStatus: string = 'init') => {
  const [contentState, dispatch] = useReducer(reducer, {
    content: null,
    status: initialStatus,
  } as ContentState);

  const update = async (data: RequestContent, file?: File) => {
    if (contentState?.content?.id == null) {
      return '';
    }
    startRequest();
    const res = await updateContent(contentState.content.id, data, file);
    dispatch(res);
  };

  //投稿
  const create = async (data: RequestContent, file: File) => {
    startRequest();
    const res = await createContent(data, file);
    dispatch(res);
  };

  const get = async (contentId: string) => {
    startRequest();
    const res = await getContent(contentId);
    dispatch(res);
  };

  const incrimentLikeCount = () => {
    dispatch({ type: types.LIKE_COUNT_INCRIMENT });
  };

  const decrimentLikeCount = () => {
    dispatch({ type: types.LIKE_COUNT_DECRIMENT });
  };

  const resetRequest = () => {
    dispatch(_resetRequest());
  };

  const startRequest = () => {
    dispatch(_startRequest());
  };

  return {
    contentState,
    get,
    create,
    update,
    incrimentLikeCount,
    decrimentLikeCount,
    resetRequest,
  };
};
