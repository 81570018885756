import { useAreaContents } from 'components/api-hooks/use-areas';
import { Loader } from 'components/atoms/Loader';
import Map from 'components/containers/MapWithPinsContainer';
import { useAlert } from 'components/custom/use-alert';
import { usePostCarousel } from 'components/custom/use-post-carousel';
import { Area as AreaTemplate } from 'components/Templates/Area';
import { Content } from 'models/Content';
import React, { useEffect } from 'react';

export const AreaPage: React.FC = () => {
  // マップで表示対象のスナップ (ターゲットスナップ)
  // スナップページ (マップ)
  const { displayErrorAlert } = useAlert();

  const {
    areasState: { areas, areaContents, status: areaStatus, error },
  } = useAreaContents();

  //Carousel State
  const { modalState, handleClickPost, createImageCarouselModal } =
    usePostCarousel();

  const handleMoveZoom = (range: string) => {};

  // if api error, show Error Alert
  // fetch only
  // TODO only content
  useEffect(() => {
    (async () => {
      if (error != null) {
        await displayErrorAlert(error);
        return;
      }
    })();
  }, [error]);

  // target contentのcenterを取得するまえに、mapをレンダリングしないようにする。
  if (areaStatus == 'loading') {
    return (
      <div className={`page__loading`}>
        <Loader />
      </div>
    );
  }
  if (!areas || !areaContents) return <></>;

  let contents: Content[] = [];
  areaContents.forEach((d, i) => {
    contents = contents.concat(d.contents);
  });

  //Carousel
  const imageCarouselModal = createImageCarouselModal(
    contents.map((data: Content) => data.id)
  );

  const _Map = (
    <Map
      areas={areas}
      contents={contents}
      handleMoveZoom={handleMoveZoom}
      isAreaLinks={true}
      handleClickMapPin={handleClickPost}
    />
  );

  return (
    <>
      <AreaTemplate
        Map={_Map}
        areaContents={areaContents}
        handleClickPost={handleClickPost}
      />
      {imageCarouselModal}
    </>
  );
};
