import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

declare global {
  interface Window {
    gtag?: (
      key: string,
      measurementId: string,
      config?: { page_path: string }
    ) => void;
  }
}

export const useTracking = () => {
  const { listen } = useHistory();
  const measurementId: string | undefined =
    process.env.REACT_APP_GA_MEASUREMENT_ID;

  useEffect(() => {
    if (!measurementId) {
      console.log(
        'Tracking not enabled, as `trackingId` was not given and there is no `GA_MEASUREMENT_ID`.'
      );
      return;
    }
    initializeGA(measurementId);
    const unlisten = listen((location) => {
      if (!window.gtag) return;
      window.gtag('set', measurementId, { page_path: location.pathname });
      window.gtag('event', 'page_view');
    });

    return unlisten;
  }, []);
};

function initializeGA(measurementId: string) {
  // load gtag.js:  https://developers.google.com/analytics/devguides/collection/gtagjs
  let script1 = document.createElement('script');
  script1.src = 'https://www.googletagmanager.com/gtag/js?id=' + measurementId;
  script1.async = true;
  document.body.appendChild(script1);

  let script2 = document.createElement('script');
  // プレーンテキスト、グローバルな window.gtag 関数を定義するため
  script2.text = `window.dataLayer = window.dataLayer || [];
	  function gtag(){dataLayer.push(arguments);}
	  gtag('js', new Date());
	  gtag('config', '${measurementId}');`;
  document.body.appendChild(script2);
}
