import React from 'react';
import { Button } from '../../atoms/Button';
import { Tooltip, OverlayTrigger, TooltipProps } from 'react-bootstrap';
import { Icon as IconFC } from '../../atoms/Icon';
import { IconType } from 'react-icons';

import './style.css';

export interface IconTooltipProps {
  id: string;
  text: string;
  placement: 'auto' | 'top' | 'bottom' | 'left' | 'right';
}

export interface MapIconProps {
  Icon: IconType;
  onClick?: () => void;
  variant?: string;
  fill?: string;
  iconTooltipProps?: IconTooltipProps;
}

export const MapIcon: React.FC<MapIconProps> = ({
  Icon,
  onClick,
  fill = '#FFF',
  variant = 'main',
  iconTooltipProps,
}: MapIconProps) => {
  const cn = 'map-icon';

  const IconComp = (
    <div className={cn} onClick={onClick}>
      <IconFC Icon={Icon} fill={fill} variant={variant} />
    </div>
  );
  if (iconTooltipProps != null) {
    const { id, text, placement } = iconTooltipProps;
    //OverlayTriggeのoverlayはany型
    const renderTooltip = (props: any) => (
      <Tooltip id={`icon-tooltip-${id}`} {...props}>
        {text}
      </Tooltip>
    );
    return (
      <OverlayTrigger
        placement={placement}
        delay={{ show: 250, hide: 400 }}
        overlay={renderTooltip}
      >
        {IconComp}
      </OverlayTrigger>
    );
  }

  return IconComp;
};
