import React from 'react';
import { Link } from 'react-router-dom';

import './style.css';

export interface MainTitleProps {
  width?: string | number;
  src?: string;
  alt?: string;
  path?: string;
  subTitle?: string;
  isReactRouter?: boolean;
}

export const MainTitle: React.FC<MainTitleProps> = ({
  width = 'auto',
  src,
  alt = '',
  path,
  subTitle,
  isReactRouter,
}: MainTitleProps) => {
  const cn = 'main-title';
  let logo = src ? (
    <img className={`${cn}__img`} src={src} alt={alt} />
  ) : (
    'Logo'
  );
  const title = <h1 className={`${cn}__main`}>{logo}</h1>;
  const sub = subTitle && <h2 className={`${cn}__sub`}>{subTitle}</h2>;
  let main = (
    <>
      {title}
      {sub}
    </>
  );
  if (path)
    main = isReactRouter ? (
      <Link to={path} className={`${cn}__link`}>
        {main}
      </Link>
    ) : (
      <a href={path} className={`${cn}__link`}>
        {main}
      </a>
    );

  return (
    <section className={cn} style={{ width }}>
      {main}
    </section>
  );
};
