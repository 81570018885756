export type ErrorLevel = 'info' | 'error' | 'fatal';

type Code =
  | 'username_conflict'
  | 'not_found'
  | 'no_session'
  | 'network_error'
  | 'login_failed'
  | 'internal'
  | 'illegal'
  | 'page_not_found'
  | 'page_illegal'
  | 'geo_permissionDenied'
  | 'geo_unavailable'
  | 'geo_timeout'
  | 'geo_illegal'
  | 'upload_invalid_extension'
  | 'upload_small_image'
  | 'upload_large_image'
  | 'upload_illegal';

type DisplayMessageCode =
  | 'page.errors.notFound'
  | 'api.errors.loginFailed'
  | 'page.errors.illegal'
  | 'api.errors.noSession'
  | 'api.errors.networkError'
  | 'api.errors.usernameConflict'
  | 'api.errors.notFound'
  | 'api.errors.internal'
  | 'api.errors.illegal'
  | 'geo.errors.permissionDenied'
  | 'geo.errors.unavailable'
  | 'geo.errors.timeout'
  | 'geo.errors.illegal'
  | 'upload.errors.invalidExtension'
  | 'upload.errors.smallImage'
  | 'upload.errors.largeImage'
  | 'upload.errors.illegal';

export interface ErrorType {
  code: Code;
  message: string;
  displayMessageCode: DisplayMessageCode;
  level: ErrorLevel;
}

export class ApiError extends Error {
  constructor(errorType: ErrorType, err?: unknown) {
    super(errorType.message);
    this.error = err;
    this.code = errorType.code;
    this.level = errorType.level;
    this.displayMessageCode = errorType.displayMessageCode;
  }

  //ベースエラー、子のエラーをラップ
  error?: unknown;
  //エラーコード
  code: Code;
  //エラーレベル
  level: ErrorLevel;

  displayMessageCode: DisplayMessageCode;
}

const PAGE_NOT_FOUND: ErrorType = {
  code: 'page_not_found',
  message: 'page not found',
  displayMessageCode: 'page.errors.notFound',
  level: 'info',
};

const LOGIN_FAILED: ErrorType = {
  code: 'login_failed',
  message: 'user sign in failed',
  displayMessageCode: 'api.errors.loginFailed',
  level: 'error',
};

const PAGE_ILLEGAL_ERROR: ErrorType = {
  code: 'page_illegal',
  message: 'page illegal',
  displayMessageCode: 'page.errors.illegal',
  level: 'fatal',
};

export function buildLoginFailedError(error: unknown) {
  return new ApiError(LOGIN_FAILED, error);
}

export function buildPageNotFoundError() {
  return new ApiError(PAGE_NOT_FOUND);
}

export function buildPageIllegalError(error: unknown) {
  return new ApiError(PAGE_ILLEGAL_ERROR, error);
}

export const hasProblem = (error: ApiError): boolean => {
  return error.code == 'internal' || error.code == 'illegal';
};

export const isNotFoundError = (error: ApiError): boolean => {
  return error.code == 'page_not_found' || error.code == 'not_found';
};
