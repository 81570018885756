import { types } from './actionTypes';
import { ActionTypes, action, actionWithAuth } from './action';
import { UserApiFactory, User, RequestUser } from 'services/api/api';
import { Configuration } from 'services/api/configuration';

export type UserTypes = User;

/* interface LogoutSuccess  { */
/*   type: typeof types.API_LOGOUT_SUCCESS */
/* } */

interface ApiFetchSuccess {
  type: typeof types.API_FETCH_SUCCESS;
  data: UserTypes;
}

export type UserActionTypes = ActionTypes | ApiFetchSuccess;

export const getUser = async (username: string): Promise<UserActionTypes> => {
  const actionCallBack = async () => {
    const res = await UserApiFactory().getUser(username);
    const data = res.data as UserTypes;
    return { type: types.API_FETCH_SUCCESS, data };
  };

  return action<UserActionTypes>(actionCallBack);
};
