import { ChakraProvider } from '@chakra-ui/react';
import { Button } from 'components/atoms/Button';
import { MainTitle } from 'components/atoms/MainTitle';
import { UserThumbnail } from 'components/atoms/UserThumbnail';
import { usePageLink } from 'components/custom/use-page-link';
import { IconButton } from 'components/molecules/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { AiFillCamera as CameraFill } from 'react-icons/ai';
import { BsGear as GearIcon, BsSearch as Search } from 'react-icons/bs';
import {
  MdLogout as LogoutIcon,
  MdOutlineAccountCircle as AccountIcon
} from 'react-icons/md';

import {
  Box,
  BoxProps,
  forwardRef,
  Menu,
  MenuButton,
  MenuItem,
  MenuList
} from '@chakra-ui/react';

import './style.css';

export interface HeaderProps {
  width?: string | number;
  handleLogin?: () => void;
  handleLogout?: () => void;
  isLogin?: boolean;
  Thumbnail?: JSX.Element | SVGElement;
  handleOpenSearch?: () => void;
  pathname: string;
}

type RefProps = {
  onClick: (e: React.MouseEvent<HTMLSpanElement>) => void;
  children: React.ReactNode;
};

export const Header: React.FC<HeaderProps> = ({
  width = '100%',
  handleLogin,
  handleLogout,
  isLogin = false,
  Thumbnail,
  handleOpenSearch,
  pathname,
}: HeaderProps) => {
  const cn = 'header';
  const { t } = useTranslation();
  const { getUrl, pushPath } = usePageLink();

  const _Box = forwardRef<BoxProps, 'div'>((props, ref) => (
    <Box ref={ref} {...props} cursor="pointer" />
  ));
  // const ThumbnailComponent= () => {
  //   return <div className={`${cn}__thumbnail`}>
  //     <UserThumbnail Thumbnail={Thumbnail} />
  //   </div>
  // }

  // const ThumbnailComponent:any = <div className={`${cn}__thumbnail`}>
  //     <UserThumbnail Thumbnail={Thumbnail} />
  //   </div>

  const ThumbnailMenu = (
    <Menu placement="bottom-end">
      <MenuButton as={_Box}>
        <div className={`${cn}__thumbnail`}>
          <UserThumbnail Thumbnail={Thumbnail} />
        </div>
      </MenuButton>
      <MenuList zIndex={'sticky'}>
        <MenuItem icon={<AccountIcon />} onClick={() => pushPath('profile')}>
          マイページ
        </MenuItem>
        <MenuItem icon={<GearIcon />} onClick={() => pushPath('setting')}>
          設定
        </MenuItem>
        <MenuItem
          icon={<LogoutIcon />}
          onClick={() => {
            handleLogout && handleLogout();
          }}
        >
          ログアウト
        </MenuItem>
      </MenuList>
    </Menu>
  );

  const ButtonsComponent = isLogin ? (
    <>
      <div className={`${cn}__post-btn`}>
        <IconButton
          onClick={() => {
            const query = new URLSearchParams(window.location.search);
            const position = query.get('position') || undefined;
            const positionQuery =
              position != null ? `?position=${position}` : '';
            pushPath(`post/create${positionQuery}`);
          }}
          Icon={CameraFill}
          text="スナップ"
        />
      </div>
      <div className={`${cn}__dropdown`}>
        <ChakraProvider>{ThumbnailMenu}</ChakraProvider>
      </div>
    </>
  ) : (
    <div className={`${cn}__sign-in`}>
      <Button
        onClick={() => {
          handleLogin && handleLogin();
        }}
      >
        {t('SignIn')}
      </Button>
    </div>
  );

  return (
    <header className={cn} style={{ width }}>
      <div className={`${cn}__main`}>
        <div className={`${cn}__left-items`}>
          <div className={`${cn}__title`}>
            <MainTitle
              alt="DigLee"
              path={'/'}
              src="/diglee.png"
            />
          </div>
          {/* <div className={`${cn}__btn-group`}>
            {MainButtons}
          </div> */}
        </div>
        <div className={`${cn}__menu`}>
          <div
            className={`${cn}__search`}
            onClick={() => handleOpenSearch && handleOpenSearch()}
          >
            <Search />
          </div>
          {ButtonsComponent}
        </div>
      </div>
    </header>
  );
};
