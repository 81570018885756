import { MapPin } from 'components/molecules/MapPin';
import Leaflet, { LatLng } from 'leaflet';
import { useMemo } from 'react';
import { renderToString } from 'react-dom/server';

export type AreaNameProps =
  | '北海道'
  | '東北'
  | '甲信越'
  | '関東'
  | '中部'
  | '近畿'
  | '四国'
  | '中国'
  | '九州'
  | '沖縄';

export type AreaKeysProps =
  | 'hokkaido'
  | 'tohoku'
  | 'koshinetsu'
  | 'kanto'
  | 'chubu'
  | 'kinki'
  | 'shikoku'
  | 'sanin_sanyo'
  | 'kyusyu'
  | 'okinawa';

export type Position = {
  lat: number;
  lng: number;
};

/**
 *
 * @export
 * @interface Bounds
 */
export interface Bounds {
  /**
   *
   * @type {Position}
   * @memberof Bounds
   */
  northeast?: Position;
  /**
   *
   * @type {Position}
   * @memberof Bounds
   */
  southwest?: Position;
}

export interface MapStateProps {
  size: 's' | 'm' | 'l';
  zoom: number;
  bounds: Leaflet.LatLngBounds | null;
  forcusPosition: Position | null;
}

export interface AreaLinkInfoProps {
  label: string;
  path: string;
  position: [number, number];
}

export interface AreaInfoProps extends Position {
  // lat: number
  // lng: number
  maxClusterRadius: number;
}

// export interface Position {
//   lat: number
//   lng: number
// }

export const useLeafletUtils = () => {
  // const [mapState, setMapState] = useState<MapStateProps>({
  //   size: 's',
  //   zoom: 5,
  //   bounds: null,
  //   forcusPosition: null
  // })
  // 地図の倍率(Zoom)に応じたMapPinサイズを管理
  // const [sizeState, setSizeState] = useState<SizeStateProps>('s')
  {
    /* const [zoomState, setZoomState] = useState<number>(5) */
  }
  {
    /* const [centerState, setCenterState] = useState<Position>({ lat: 36, lng: 140 }) */
  }
  // const [boundsState, setBoundsState] = useState<Leaflet.LatLngBounds>()

  // const [forcusPosition, setForcusPosition ]= useState<Position>()

  // MapPinコンポーネントを配置するための処理
  // 一度の描画で戻り値は全て同じため、配置場所をメモ化する
  const memoizedMapPinPosition = (size?: 's' | 'm' | 'l') => {
    switch (size) {
      case 's':
        return { top: -47, left: -20 };
      case 'm':
        return { top: -90, left: -40 };
      case 'l':
        return { top: -160, left: -70 };
      default:
        return { top: -30, left: -30 };
    }
  };

  // MarkerClusterGroupコンポーネントのiconCreateFunctionプロパティを拡張
  // leafletマップを拡張、縮小する度にクラスター(MarkerClusterGroup)を再生成する
  const iconCreateExFunction = (cluster: Leaflet.MarkerCluster) => {
    // 各クラスター配下にある先頭要素のMarkerを取得
    // Todo: 直近でアップロードされた画像を表示するようロジックの変更もあり
    const clusterIcon: Leaflet.DivIcon = cluster
      .getAllChildMarkers()[0]
      .getIcon();
    // HTMLElement → DOM要素 → JSX.Elementに変換後、MapPinコンポーネントを再描画
    const dom = document.createElement('div');
    dom.innerHTML = clusterIcon.options.html as string;
    const divDom = dom.querySelector('div');
    const size = divDom?.dataset.size as 's' | 'm' | 'l' | undefined;
    const imgDom = dom.querySelector('img');

    return createIconMapPin(
      <MapPin
        Img={<img src={imgDom?.src} alt={imgDom?.alt} />}
        size={size}
        counterVal={cluster.getChildCount()}
      />,
      size
    );
  };

  // MarkerClusterGroup, Markerコンポーネントに適用するアイコンを構築
  const createIconMapPin = useMemo(() => {
    return (elem: JSX.Element, size?: 's' | 'm' | 'l') => {
      const _elem = (
        <div
          style={{ ...memoizedMapPinPosition(size), position: 'absolute' }}
          data-size={size}
        >
          {elem}
        </div>
      );
      return Leaflet.divIcon({
        // JSX.Element型 → HTMLElement型に変換
        html: renderToString(_elem),
        // デフォルトのアイコンを非表示
        iconSize: Leaflet.point(0, 0),
      });
    };
  }, []);

  // エリア(親タグ)のリンクラベル情報を定義
  const memoizedAreaLinkInfo = useMemo(() => {
    return new Map<AreaKeysProps, AreaLinkInfoProps>([
      // 北海道
      [
        'hokkaido',
        { label: '北海道', path: './tags/北海道', position: [44, 137] },
      ],
      // 東北
      ['tohoku', { label: '東北', path: './tags/東北', position: [41, 143] }],
      // 関東
      ['kanto', { label: '関東', path: './tags/関東', position: [36.5, 142] }],
      // 近畿
      [
        'kinki',
        { label: '近畿', path: './tags/近畿', position: [33.5, 136.5] },
      ],
      // 中部
      ['chubu', { label: '中部', path: './tags/中部', position: [39, 136] }],
      // 四国
      [
        'shikoku',
        { label: '四国', path: './tags/四国', position: [32.5, 133] },
      ],
      // 中国
      [
        'sanin_sanyo',
        { label: '中国', path: './tags/中国', position: [36, 130] },
      ],
      // 九州
      ['kyusyu', { label: '九州', path: './tags/九州', position: [34, 127.5] }],
      // 沖縄
      [
        'okinawa',
        { label: '沖縄', path: './tags/沖縄', position: [27, 129.5] },
      ],
    ]);
  }, []);

  // エリア(親タグ)の中心地となる緯度、経度、クラスター範囲の半径を定義
  const memoizedAreaMarkersInfo = useMemo(() => {
    return new Map<AreaKeysProps, AreaInfoProps>([
      // 北海道
      [
        'hokkaido',
        {
          lat: 43.49623742898749,
          lng: 142.7000086703695,
          maxClusterRadius: 100,
        },
      ],
      // 東北
      [
        'tohoku',
        {
          lat: 38.76774548524595,
          lng: 140.64011729603746,
          maxClusterRadius: 50,
        },
      ],
      // 甲信越
      [
        'koshinetsu',
        {
          lat: 36.834217611449176,
          lng: 138.21210558636918,
          maxClusterRadius: 50,
        },
      ],
      // 関東
      [
        'kanto',
        {
          lat: 36.456495064020906,
          lng: 139.53765696871966,
          maxClusterRadius: 50,
        },
      ],
      // 近畿
      [
        'kinki',
        {
          lat: 35.670447441908415,
          lng: 139.78075752910505,
          maxClusterRadius: 50,
        },
      ],
      // 中部
      [
        'chubu',
        {
          lat: 35.72398020495126,
          lng: 137.04516182598005,
          maxClusterRadius: 50,
        },
      ],
      // 四国
      [
        'shikoku',
        {
          lat: 33.688815996844525,
          lng: 133.53724380826762,
          maxClusterRadius: 50,
        },
      ],
      // 中国
      [
        'sanin_sanyo',
        {
          lat: 34.73487881886116,
          lng: 132.71481837392236,
          maxClusterRadius: 50,
        },
      ],
      // 九州
      [
        'kyusyu',
        {
          lat: 32.58380207469862,
          lng: 130.84589059018788,
          maxClusterRadius: 50,
        },
      ],
      // 沖縄
      [
        'okinawa',
        {
          lat: 27.353250641050394,
          lng: 128.74503733513976,
          maxClusterRadius: 50,
        },
      ],
    ]);
  }, []);

  // leafletのクラスターを識別するためにエリア名をキーに変換
  const convertAreaIntoKey = (area: string = ''): AreaKeysProps | '' => {
    switch (area) {
      case '北海道':
        return 'hokkaido';
      case '東北':
        return 'tohoku';
      case '甲信越':
        return 'koshinetsu';
      case '関東':
        return 'kanto';
      case '中部':
        return 'chubu';
      case '近畿':
        return 'kinki';
      case '中国':
        return 'sanin_sanyo';
      case '四国':
        return 'shikoku';
      case '九州':
        return 'kyusyu';
      case '沖縄':
        return 'okinawa';
      default:
        return '';
    }
  };

  function getRange(bounds: Leaflet.LatLngBounds): string {
    const sw = getJoinLatlng(bounds.getSouthWest());
    const ne = getJoinLatlng(bounds.getNorthEast());
    return `${sw},${ne}`;
  }

  function getJoinLatlng(latlng: LatLng): string {
    const { lat, lng } = latlng;
    return `${lat},${lng}`;
  }

  function buildBounds(bounds: Bounds): Leaflet.LatLngBounds {
    const northeast = bounds.northeast as Position;
    const southwest = bounds.southwest as Position;
    return Leaflet.latLngBounds([
      [northeast.lat, northeast.lng],
      [southwest.lat, southwest.lng],
    ]);
  }
  function isNullPosition(position: Position | null) {
    if (position == null) {
      return true;
    }

    return position.lat === 0 && position.lng === 0;
  }

  function isNullBounds(bounds: Bounds | null) {
    if (bounds == null) {
      return true;
    }

    const { northeast, southwest } = bounds;

    if (northeast == null || southwest == null) {
      return true;
    }
    return isNullPosition(northeast) && isNullPosition(southwest);
  }

  return {
    // mapState,
    // setMapState,
    // boundsState,
    // setBoundsState,
    // sizeState,
    // setSizeState,
    // forcusPosition,
    // setForcusPosition,
    createIconMapPin,
    iconCreateExFunction,
    memoizedAreaLinkInfo,
    memoizedAreaMarkersInfo,
    convertAreaIntoKey,
    getRange,
    buildBounds,
    isNullPosition,
    isNullBounds,
  };
};
