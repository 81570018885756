import React from 'react';

import './style.css';

export interface CounterCircleProps {
  val: number;
  width?: string | number;
  fontSize?: string;
  variant?: 'main' | 'sub' | 'black';
}

export const CounterCircle: React.FC<CounterCircleProps> = ({
  val,
  width = 'auto',
  fontSize,
  variant = 'main',
}: CounterCircleProps) => {
  const cn = 'counter-circle';
  return (
    <div className={`${cn} ${cn}--${variant}`} style={{ width, fontSize }}>
      {val.toLocaleString()}
    </div>
  );
};
