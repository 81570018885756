import { useEffect, useRef } from 'react';

export const useTimer = (delay: number) => {
  const timerRef = useRef<number | null>(null);
  {
    /* const [callback,setCallback] = useState<Function>(() => {}) */
  }

  const execCallback = (callback: Function) => {
    if (timerRef.current != null) {
      clearTimeout(timerRef.current);
    }

    timerRef.current = window.setTimeout(callback, delay);
  };

  const clear = () => {
    if (timerRef.current != null) {
      clearTimeout(timerRef.current);
    }
  };

  return { execCallback, clear };
};
