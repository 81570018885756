import { useContent } from 'components/api-hooks/use-content';
import { Loader } from 'components/atoms/Loader';
import { useAlert } from 'components/custom/use-alert';
import { usePageLink } from 'components/custom/use-page-link';
import { ErrorTemplate } from 'components/Templates/Error';
import { PostPage } from 'components/Templates/PostPage';
import { buildPageNotFoundError, isNotFoundError } from 'models/ApiError';
import { UserContexts } from 'modules/contexts';
import React, { useContext, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

export const EditPostPage: React.FC = () => {
  const history = useHistory();
  const { getUrl, pushEnter } = usePageLink();
  const { displayPostSuccessAlert, displayErrorAlert } = useAlert();

  const query = new URLSearchParams(useLocation().search);
  const contentId = query.get('id');

  const {
    userState: { isLogin },
  } = useContext(UserContexts);
  const {
    contentState: { content, status, error },
    get,
    update,
    resetRequest,
  } = useContent();

  useEffect(() => {
    (async () => {
      if (contentId) {
        await get(contentId);
      }
    })();
  }, []);

  // if api error, show Error Alert
  useEffect(() => {
    (async () => {
      if (error != null && !isNotFoundError(error)) {
        await displayErrorAlert(error);
        return;
      }
    })();
  }, [error]);

  // リクエスト結果の処理
  useEffect(() => {
    (async () => {
      if (status == 'completed' && content != null) {
        await displayPostSuccessAlert('更新されました', content);
        resetRequest();
      }
    })();
  }, [status]);

  if (!isLogin) {
    pushEnter('/post/edit');
  }

  if (error != null && isNotFoundError(error)) {
    return <ErrorTemplate error={error} />;
  }

  // notfoundのときはエラーページを表示
  if (contentId == null) {
    return <ErrorTemplate error={buildPageNotFoundError()} />;
  }

  //Loading
  if (status == 'loading' && !content) {
    return (
      <div className={`loading`}>
        <Loader />
      </div>
    );
  }

  //TODO error status
  if (!content) return <></>;

  return (
    <PostPage
      mode={'edit'}
      submit={update}
      content={content}
      isLoading={status == 'loading'}
    />
  );
};
