import { useAreas } from 'components/api-hooks/use-areas';
import { useContents } from 'components/api-hooks/use-contents';
import { useUser } from 'components/api-hooks/use-user';
import { Loader } from 'components/atoms/Loader';
import Map from 'components/containers/MapWithPinsContainer';
import { useAlert } from 'components/custom/use-alert';
import { useComponents } from 'components/custom/use-components';
import { usePostCarousel } from 'components/custom/use-post-carousel';
import { useSearchModal } from 'components/custom/use-search-modal';
import { InfinityPostList } from 'components/organisms/InfinityPostList';
import { ErrorTemplate } from 'components/Templates/Error';
import { UserPage as UserPageTemplate } from 'components/Templates/UserPage';
import { isNotFoundError } from 'models/ApiError';
import { Content } from 'models/Content';
import { UserTypes } from 'modules/actions/authenticated-user';
import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';

export const UserPage: React.FC = () => {
  const { username = '' } = useParams<{ username: string }>();
  const { displayErrorAlert } = useAlert();

  const {
    areasState: { areas },
  } = useAreas();

  const {
    contentsState: { contents, status, hasMore },
    params,
    setParams,
    fetchUserContents: fetch,
  } = useContents(undefined, 12);

  const {
    userState: { user, status: userStatus, error },
    fetch: fetchUser,
  } = useUser();

  const fetchMore = () => {
    fetch(username);
  };
  const { createPostListWithComments } = useComponents();
  const { handleClickPost, createImageCarouselModal } = usePostCarousel();
  // 検索モーダル
  const { createSearchModal, handleOpen } = useSearchModal();
  const search = (value: string) => {
    const params = { query: value };
    setParams(params);
    fetch(username, params, 1);
  };
  const handleResetSearchValue = () => {
    search('');
  };
  const searchModal = createSearchModal(search);
  //############

  const PostList = useMemo(() => {
    if (contents == null) {
      return <></>;
    }

    const PostList =
      contents.length != 0 ? (
        createPostListWithComments(contents, false, handleClickPost)
      ) : (
        <div>スナップが存在しません</div>
      );

    const InfinityPostListProps = {
      PostList,
      fetchMore,
      hasMore,
      status,
    };

    return <InfinityPostList {...InfinityPostListProps} />;
  }, [contents, status]);

  useEffect(() => {
    (async () => {
      fetchUser(username);
      fetch(username);
    })();
  }, [username]);

  // if api error, show Error Alert
  useEffect(() => {
    (async () => {
      if (error != null && !isNotFoundError(error)) {
        await displayErrorAlert(error);
        return;
      }
    })();
  }, [error]);

  if (userStatus == 'loading') {
    return (
      <div className={`loading`}>
        <Loader />
      </div>
    );
  }

  // notfoundのときはエラーページを表示
  if (error != null && isNotFoundError(error)) {
    return <ErrorTemplate error={error} />;
  }

  if (user == null || areas == null || contents == null) {
    return <></>;
  }

  // ユーザー情報は全て同じため、インデックスは決め打ち指定
  const {
    nickname = '名無し',
    profile_image_url = '',
    content_count = 0,
  } = user as UserTypes;
  const Thumbnail = profile_image_url ? (
    <img src={profile_image_url} alt={'ユーザーサムネイル'} />
  ) : undefined;

  //Carousel
  const imageCarouselModal =
    contents != null
      ? createImageCarouselModal(contents.map((data: Content) => data.id))
      : null;

  const handleMoveZoom = (range: string) => {};
  const _Map = (
    <Map
      areas={areas}
      contents={contents}
      handleMoveZoom={handleMoveZoom}
      handleClickMapPin={handleClickPost}
      current={nickname}
      Thumbnail={Thumbnail}
    />
  );

  return (
    <>
      <UserPageTemplate
        name={nickname}
        Thumbnail={Thumbnail}
        postNum={content_count}
        Map={_Map}
        PostList={PostList}
        handleClickFilter={handleOpen}
        handleResetSearchValue={handleResetSearchValue}
        searchValue={params?.query}
      />
      {searchModal}
      {imageCarouselModal}
    </>
  );
};
