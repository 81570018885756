import { Position } from 'models/Content';
import {
  RequestUserTypes,
  UserTypes,
} from 'modules/actions/authenticated-user';
import { UserState } from 'modules/reducers/authenticated-user';
import { createContext } from 'react';

export interface UserContextsValues {
  userState: UserState;
  getUser: () => Promise<void>;
  setUser: (data: UserTypes) => void;
  updateUser: (data: RequestUserTypes) => Promise<void>;
  uploadUserImage: (file: File) => Promise<void>;
  logout: () => void;
  login: (redirectPath: string) => void;
}
export const UserContexts = createContext({} as UserContextsValues);
