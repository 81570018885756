import { types } from './actionTypes';
import { ActionTypes, actionWithAuth } from './action';
import {
  UserApiFactory,
  User,
  RequestUser,
  Configuration,
  isHTTPNotFoundError,
} from 'services/api';
import { getAuthConfigurations } from './auth';

export type UserTypes = User;
export type RequestUserTypes = RequestUser;

/* interface LogoutSuccess  { */
/*   type: typeof types.API_LOGOUT_SUCCESS */
/* } */

interface ApiFetchSuccess {
  type: typeof types.API_FETCH_SUCCESS;
  data: UserTypes;
}

interface ApiCreateSuccess {
  type: typeof types.API_CREATE_SUCCESS;
  data: UserTypes;
}

interface ApiUpdateSuccess {
  type: typeof types.API_UPDATE_SUCCESS;
  data: UserTypes;
}

interface ApiDeleteSuccess {
  type: typeof types.API_DELETE_SUCCESS;
  deletedId?: string;
}

export type UserActionTypes =
  | ActionTypes
  | ApiFetchSuccess
  | ApiCreateSuccess
  | ApiUpdateSuccess
  | ApiDeleteSuccess;

// 認証済み（ログイン済み）ユーザーのプロフィールを取得
// 404 ユーザーが存在しない場合、signUpメソッドを呼び出す
// ログインしているかも確認する。ログインしていない場合、API_NO_SESSIONを発行
export const getAuthenticatedUser = async (): Promise<UserActionTypes> => {
  const { error } = await getAuthConfigurations();
  if (error) {
    return { type: types.API_NO_SESSION };
  }

  const actionCallBack = async (configurations: Configuration) => {
    try {
      const res = await UserApiFactory(
        configurations as Configuration
      ).getAuthenticatedUser();
      const data = res.data as UserTypes;
      return { type: types.API_FETCH_SUCCESS, data };
    } catch (err: any) {
      if (isHTTPNotFoundError(err)) {
        return signUp();
      }
      // actionWithAuthでcatchする
      throw err;
    }
  };
  return actionWithAuth<UserActionTypes>(actionCallBack);
};

export const signUp = async (): Promise<UserActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const tmp = await UserApiFactory(
      configurations as Configuration
    ).createUser();
    const data = tmp.data as UserTypes;
    return { type: types.API_CREATE_SUCCESS, data };
  };
  return actionWithAuth<UserActionTypes>(actionCallBack);
};

// 認証済み（ログイン済み）ユーザーのプロフィールを取得
export const updateAuthenticatedUser = async (
  data: RequestUserTypes
): Promise<UserActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const _data = data as RequestUser;
    const res = await UserApiFactory(
      configurations as Configuration
    ).updateAuthenticatedUser(_data);
    const resUser = res.data as UserTypes;
    return { type: types.API_UPDATE_SUCCESS, data: resUser };
  };

  return actionWithAuth<UserActionTypes>(actionCallBack);
};

export const setAuthenticatedUser = (data: UserTypes): UserActionTypes => {
  return { type: types.API_FETCH_SUCCESS, data: data };
};

/* 認証済み（ログイン済み）ユーザーのプロフィール画像をアップロード（置き換え）する。*/
export const uploadAuthenticatedUserImage = async (
  file: File
): Promise<UserActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const res = await UserApiFactory(
      configurations as Configuration
    ).uploadAuthenticatedUserImage(file);
    const data = res.data as UserTypes;
    return { type: types.API_UPDATE_SUCCESS, data };
  };

  return actionWithAuth<UserActionTypes>(actionCallBack);
};

// export const getUser = async ( username: string): Promise<UserActionTypes> => {
//   const actionCallBack = async ()=>{
//     const res = await UserApiFactory().getUser(username)
//     const data = res.data as UserTypes
//     return { type: types.API_FETCH_SUCCESS, data }
//   }

//   return action<UserActionTypes>(actionCallBack)
// }
