import { useHistory } from 'react-router-dom';
export const usePageLink = () => {
  const history = useHistory();
  // クエリパラメータ(code)を引き継いだパス上を取得
  const getUrl = (path: string = '') => {
    const origin = window.location.origin;
    {
      /* return `${origin}/${path}${query}` */
    }
    return `${origin}/${path}`;
  };

  const pushPath = (path: string = '') => {
    history.push(`/${path}`);
  };

  const pushEnter = (path?: string) => {
    const query = path ? `?redirect_to=${path}` : '';
    history.replace(`/enter${query}`);
  };

  const replaceQuery = (key: string, query: string) => {
    const path = window.location.pathname;
    const searchParams = new URLSearchParams(window.location.search);
    searchParams.set(key, query);
    const paramsString = searchParams.toString();

    history.replace(`${path}?${paramsString}`);
  };

  return { getUrl, pushPath, pushEnter, replaceQuery };
};
