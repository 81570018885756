import React from 'react';

import './style.css';

export interface PostListWithCommentsProps {
  width?: string | number;
  Cards: {
    PostCard: JSX.Element;
    CommentBalloon: JSX.Element;
  }[];
}

export const PostListWithComments: React.FC<PostListWithCommentsProps> = ({
  width = '100%',
  Cards,
}: PostListWithCommentsProps) => {
  const cn = 'post-comment';
  return (
    <div className={cn} style={{ width }}>
      {Cards.map((Card, i) => (
        <div>
          <div className={`${cn}__post-card`}>{Card.PostCard}</div>
          <div className={`${cn}__comment-balloon`}>{Card.CommentBalloon}</div>
        </div>
      ))}
    </div>
  );
};
