import {
  ImageCarousel,
  ImageCarouselProps,
} from 'components/organisms/ImageCarousel';
import React from 'react';
import { Modal } from 'react-bootstrap';
import { BsX as X } from 'react-icons/bs';
import './style.css';

export type ImageCarouselWithModalProps = {
  isShow?: boolean;
  imageCarouselProps?: ImageCarouselProps;
  handleClickClose?: () => void;
};

export const ImageCarouselWithModal: React.FC<ImageCarouselWithModalProps> = ({
  isShow = false,
  imageCarouselProps,
  handleClickClose,
}: ImageCarouselWithModalProps) => {
  const size = imageCarouselProps?.size || 'xl';
  const iconSize = size == 'sm' ? 40 : 60;

  const cn = 'img-carousel-modal';
  const cnIcon = 'img-carousel-modal-icon';

  return (
    <Modal
      size={size}
      show={isShow}
      keyboard={false}
      className={cn}
      onHide={() => {
        handleClickClose && handleClickClose();
      }}
    >
      <a
        className={`${cnIcon} ${cnIcon}__close ${cnIcon}__close--${size}`}
        onClick={handleClickClose}
      >
        <X className={`${cnIcon}`} size={iconSize} />
      </a>
      <Modal.Body>
        {imageCarouselProps && <ImageCarousel {...imageCarouselProps} />}
      </Modal.Body>
    </Modal>
  );
};
