import React, { useState, useReducer, useEffect } from 'react';
import reducer, { SearchState } from 'modules/reducers/search';
import { types } from 'modules/actions/actionTypes';
import { search, getContents, Params } from 'modules/actions/search';

export const useSearch = (_query: string | null | undefined) => {
  const [searchState, dispatch] = useReducer(reducer, {
    contents: null,
    bounds: null,
    location: null,
    status: 'loading',
  } as SearchState);
  /* const [rangeState, setRangeState] = useState<string|null>(null) */
  const [query, setQueryState] = useState<string>('');
  const _search = async (searchQuery: string) => {
    /* dispatch({type:types.API_START}) */
    const res = await search(searchQuery);
    dispatch(res);
  };

  const reset = () => {
    dispatch({ type: types.REQUEST_RESET });
  };

  const _getContents = async (range?: string, page?: number) => {
    const res = await getContents({ range, query });
    dispatch(res);
  };

  useEffect(() => {
    (async () => {
      if (_query == null || _query == '') {
        reset();
        setQueryState('');
        return;
      }
      setQueryState(_query);
      await _search(_query);
    })();
  }, [query]);

  /*   useEffect(() => { */
  /*     (async () => { */
  /*       if(range==null|| range==''){ */
  /*         return */
  /*       } */
  /*       reset() */
  /*       await _getContents(range) */
  /*     })() */
  /*   }, [range]) */

  return {
    searchState,
    search: _search,
    getContents: _getContents,
    searchQuery: query,
  };
};
