import React from 'react';
import { MenuModal } from 'components/molecules/MenuModal';
import { KeywordLink } from 'components/atoms/KeywordLink';

import './style.css';

export interface CommentMenuModalProps {
  isShow?: boolean;
  canEdit?: boolean;
  handleDelete?: () => void;
  handleCopyLink?: () => void;
  handleReport?: () => void;
  handleCloseMenu?: () => void;
}

export const CommentMenuModal: React.FC<CommentMenuModalProps> = ({
  isShow = false,
  canEdit = false,
  handleDelete,
  handleCopyLink,
  handleReport,
  handleCloseMenu,
}: CommentMenuModalProps) => {
  const cn = 'comment-menu-modal';
  return (
    <>
      {canEdit ? (
        <MenuModal isShow={isShow} onHide={handleCloseMenu}>
          <p
            className={`${cn}__item-danger`}
            onClick={() => handleDelete && handleDelete()}
          >
            削除
          </p>
          <p
            className={`${cn}__item-cancel`}
            onClick={() => handleCloseMenu && handleCloseMenu()}
          >
            キャンセル
          </p>
        </MenuModal>
      ) : (
        <MenuModal isShow={isShow} onHide={handleCloseMenu}>
          <p
            className={`${cn}__item-danger`}
            onClick={() => handleReport && handleReport()}
          >
            不適切なコメントを報告
          </p>
          <p
            className={`${cn}__item-cancel`}
            onClick={() => handleCloseMenu && handleCloseMenu()}
          >
            キャンセル
          </p>
        </MenuModal>
      )}
    </>
  );
};
