import React from 'react';
import { IconType } from 'react-icons';
import { AiFillCloseCircle as CloseIcon } from 'react-icons/ai';

import './style.css';

export interface TagProps {
  Icon?: IconType;
  text: string;
  variant?: string;
  handleDelete?: () => void;
}

export const Tag: React.FC<TagProps> = ({
  Icon,
  text,
  variant = 'main',
  handleDelete,
}: TagProps) => {
  const cn = 'tag';

  return (
    <div className={`${cn} ${cn}--${variant}`}>
      {Icon ? <Icon className={`${cn}__icon`} /> : null}
      <span className={`${cn}__label`}>{text}</span>
      {handleDelete ? (
        <div
          onClick={() => {
            handleDelete && handleDelete();
          }}
        >
          <CloseIcon className={`${cn}__icon-close`} />
        </div>
      ) : null}
    </div>
  );
};
