import React from 'react';
import { StartForm, StartFormProps } from 'components/organisms/StartForm';

import './style.css';

export interface StartProps {
  startFormProps: StartFormProps;
}

export const Start: React.FC<StartProps> = ({ startFormProps }: StartProps) => {
  const cn = 'start';
  return (
    <div className={cn}>
      <div className={`${cn}__contents`}>
        <StartForm {...startFormProps} />
      </div>
    </div>
  );
};
