import { useAuthenticatedUser } from 'components/api-hooks/use-authenticated-user';
import { HeaderContainer } from 'components/containers/HeaderContainer';
import { usePageLink } from 'components/custom/use-page-link';
import { Footer } from 'components/organisms/Footer';
import { AreaPage } from 'components/pages/AreaPage';
import { EditPostPage } from 'components/pages/EditPost';
import { ErrorPage } from 'components/pages/Error';
import { LoginPage } from 'components/pages/LoginPage';
import { CompanyPage } from 'components/pages/MarkDownPage/CompanyPage';
import { GuideLinesPage } from 'components/pages/MarkDownPage/GuideLinesPage';
import { MyPage } from 'components/pages/MyPage';
import { NewPostPage } from 'components/pages/NewPost';
import { Privacy } from 'components/pages/Privacy';
import { SearchPage } from 'components/pages/SearchPage';
import { SettingPage } from 'components/pages/SettingPage';
import { Start } from 'components/pages/Start';
import { TagPage } from 'components/pages/Tag';
import { Terms } from 'components/pages/Terms';
import { TopPage } from 'components/pages/Top';
import { UserPage } from 'components/pages/UserPage';
import {
  buildLoginFailedError,
  buildPageIllegalError,
  buildPageNotFoundError,
} from 'models/ApiError';
import { UserContexts } from 'modules/contexts';
import React, { useContext, useEffect } from 'react';
import { useMediaQuery } from 'react-responsive';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import 'bootstrap/dist/css/bootstrap.min.css';
import { Loader } from 'components/atoms/Loader';
import { useAlert } from 'components/custom/use-alert';
import { useTracking } from 'components/custom/use-tracking';
import { ErrorBoundary } from 'react-error-boundary';
import './App.css';
import ScrollToTop from './ScrollToTop';

import { CognitoHostedUIIdentityProvider } from '@aws-amplify/auth/lib/types';
import { HubCallback } from '@aws-amplify/core/lib/Hub';
import Amplify, { Auth, Hub } from 'aws-amplify';
import awsconfig from './wrapper-aws-exports';
export const AUTH_DOMAIN = process.env.REACT_APP_AUTH_DOMAIN;
if (AUTH_DOMAIN != null) {
  awsconfig.oauth.domain = AUTH_DOMAIN;
}
Amplify.configure(awsconfig);

interface ErrorFallbackArgs {
  error: any;
  resetErrorBoundary: () => void;
}

function ErrorFallback({ error, resetErrorBoundary }: ErrorFallbackArgs) {
  //TODO レンダリングに失敗したときの場合、Digleeのページだと分からない
  const _error = buildPageIllegalError(error);
  return <ErrorPage error={_error} />;
}

function NotFoundPage() {
  const error = buildPageNotFoundError();
  return <ErrorPage error={error} />;
}

const App: React.FC = () => {
  useTracking();
  const {
    userState: { isLogin, user, status },
  } = useContext(UserContexts);

  const cn = 'app';
  const isMinWidth768 = useMediaQuery({ query: '(min-width: 768px)' });

  const { getUrl } = usePageLink();

  //toBlobのpolyfillを読み込む
  useEffect(() => {
    const script1 = document.createElement('script');
    script1.src = '/js/canvas-to-blob.min.js';
    script1.async = true;
    document.body.appendChild(script1);
    return () => {
      document.body.removeChild(script1);
    };
  }, []);

  if (!isLogin && status === 'loading') {
    return (
      <div className={`${cn}__loading`}>
        <Loader />
      </div>
    );
  }

  if (
    isLogin &&
    user?.username == null &&
    window.location.pathname != '/start'
  ) {
    window.location.href = getUrl('start');
  }

  const GeneralPage = () => {
    return (
      <>
        <div className={`${cn}__header-outer`}>
          <div className={`${cn}__header-inner`}>
            <HeaderContainer />
          </div>
        </div>
        <div className={`${cn}__body`}>
          <div className={`${cn}__contents`}>
            <Switch>
              <Route exact path="/" component={TopPage} />
              <Route exact path="/area" component={AreaPage} />
              <Route exact path="/search" component={SearchPage} />
              <Route path="/profile" component={MyPage} />
              <Route path="/setting" component={SettingPage} />
              <Route path="/post/create" component={NewPostPage} />
              <Route path="/post/edit" component={EditPostPage} />
              <Route path="/tags/:tag" component={TagPage} />
              <Route exact path="/terms" component={Terms} />
              <Route path="/privacy" component={Privacy} />
              <Route path="/company" component={CompanyPage} />
              <Route path="/guidelines" component={GuideLinesPage} />
              <Route exact path="/:username" component={UserPage} />
              <Route path="/:other" component={NotFoundPage} />
            </Switch>
          </div>
        </div>
        <div className={`${cn}__footer`}>
          <Footer />
        </div>
      </>
    );
  };

  return (
    <div className={cn}>
      <Switch>
        <Route exact path="/enter" component={LoginPage} />
        <Route exact path="/start" component={Start} />
        <Route path="/" component={GeneralPage} />
      </Switch>
    </div>
  );
};

const AppWrapper: React.FC = () => {
  const { displayErrorAlert } = useAlert();
  const { userState, getUser, setUser, updateUser, uploadUserImage } =
    useAuthenticatedUser('loading');
  const { error } = userState;

  const logout = () => {
    Auth.signOut();
  };
  const login = (redirectPath: string) => {
    Auth.federatedSignIn({
      provider: CognitoHostedUIIdentityProvider.Google,
      customState: JSON.stringify(redirectPath),
    });
  };

  const contextsValues = {
    userState,
    getUser,
    setUser,
    updateUser,
    uploadUserImage,
    logout,
    login,
  };

  // Amplify Hub
  // Add Listener for Login Event
  useEffect(() => {
    const authListener: HubCallback = async ({ payload: { event, data } }) => {
      switch (event) {
        case 'signIn':
          console.log('user signed in');
          getUser();
          break;

        case 'signUp':
          console.log('user signed up');
          //TODO signUP
          getUser();
          break;

        case 'signIn_failure':
          displayErrorAlert(buildLoginFailedError(data));
          break;

        case 'customOAuthState':
          console.log('customOAuthState');
          const path = JSON.parse(data);
          if (path) {
            window.location.href = window.location.origin + path;
          }
          break;

        default:
          console.log('default');
          break;
      }
    };

    Hub.listen('auth', authListener);

    return () => {
      console.log('remove hub listener');
      Hub.remove('auth', authListener);
    };
  }, []);

  useEffect(() => {
    (async () => {
      getUser();
    })();
  }, []);

  useEffect(() => {
    if (error != null) {
      displayErrorAlert(error);
    }
  }, [error]);

  return (
    <ErrorBoundary
      FallbackComponent={ErrorFallback}
      onReset={() => {
        // reset the state of your app so the error doesn't happen again
      }}
    >
      {/* <Bomb /> */}
      <Router>
        <ScrollToTop />
        <UserContexts.Provider value={contextsValues}>
          <App />
        </UserContexts.Provider>
      </Router>
    </ErrorBoundary>
  );
};

export default AppWrapper;
