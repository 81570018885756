import { ContentApiFactory, Tag } from 'services/api/api';
import { types } from './actionTypes';

export type TagTypes = Tag & { child_tags?: Tag[] };

export interface ActionTypes {
  type: typeof types.API_TAG;
  data: TagTypes;
}

// タグを取得する。（子のタグも含めて取得する）
// TODO Error Actionの実装 404
export const getTag = async (tagName: string): Promise<ActionTypes> => {
  const tmp = await ContentApiFactory().getTag(tagName);
  const data = tmp.data as TagTypes;
  return { type: types.API_TAG, data };
};
