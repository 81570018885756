import React from 'react';

import './style.css';

export interface PostSummaryProps {
  width?: string | number;
  dateTime: string;
  name: string;
}

export const PostSummary: React.FC<PostSummaryProps> = ({
  width = '100%',
  dateTime,
  name,
}: PostSummaryProps) => {
  const cn = 'post-summary';
  return (
    <p className={`${cn}`} style={{ width }}>
      <time className={`${cn}__time`} dateTime={dateTime}>
        {dateTime}
      </time>
      <span className={`${cn}__name`}>by {name} さん</span>
    </p>
  );
};
