import { ApiError, ErrorType } from 'models/ApiError';

const options = {
  enableHighAccuracy: true,
  timeout: 5000,
  maximumAge: 0,
};

export const geoLocation = async () => {
  try {
    let pos = await new Promise<GeolocationPosition>(
      (
        resolve: (position: GeolocationPosition) => void,
        reject: (positionError: GeolocationPositionError) => void
      ) => {
        navigator.geolocation.getCurrentPosition(resolve, reject);
      }
    );
    var crd = pos.coords;
    return { position: { lat: crd.latitude, lng: crd.longitude }, error: null };
  } catch (err: any) {
    return {
      position: null,
      error: buildApiError(err as GeolocationPositionError),
    };
  }
};

const GEO_PERMISSIONDENIED: ErrorType = {
  code: 'geo_permissionDenied',
  message: 'geo permissionDenied',
  displayMessageCode: 'geo.errors.permissionDenied',
  level: 'info',
};

const GEO_UNAVAILABLE: ErrorType = {
  code: 'geo_unavailable',
  message: 'geo_unavailable',
  displayMessageCode: 'geo.errors.unavailable',
  level: 'error',
};

const GEO_TIMEOUT: ErrorType = {
  code: 'geo_timeout',
  message: 'geo timeout',
  displayMessageCode: 'geo.errors.timeout',
  level: 'info',
};

const GEO_ILLEGAL_ERROR: ErrorType = {
  code: 'geo_illegal',
  message: 'geo illegal',
  displayMessageCode: 'geo.errors.illegal',
  level: 'fatal',
};

/**
 *
 * BuildApiError build ApiError
 * @typeParam T - ActionTypes
 */
const buildApiError = (err: GeolocationPositionError): ApiError => {
  return new ApiError(buildApiErrorType(err.code), err);
};

const buildApiErrorType = (code: number) => {
  switch (code) {
    case 1:
      return GEO_PERMISSIONDENIED;
    case 2:
      return GEO_UNAVAILABLE;
    case 3:
      return GEO_TIMEOUT;
    default:
      return GEO_ILLEGAL_ERROR;
  }
};
