import { types } from 'modules/actions/actionTypes';
import { UserTypes, UserActionTypes } from 'modules/actions/authenticated-user';
import { ApiStatus } from 'models/ApiStatus';
import { ApiError } from 'models/ApiError';

export type UserState = {
  user: UserTypes | null;
  isLogin: boolean;
  status: ApiStatus;
  error?: ApiError | null;
};

export default (state: UserState, action: UserActionTypes): UserState => {
  switch (action.type) {
    case types.API_START:
      return {
        ...state,
        status: 'loading',
      };

    case types.API_FETCH_SUCCESS:
    case types.API_CREATE_SUCCESS:
      return {
        ...state,
        user: action.data as UserTypes,
        isLogin: true,
        status: 'init',
      };

    case types.API_UPDATE_SUCCESS:
      return {
        ...state,
        user: action.data,
        isLogin: true,
        status: 'completed',
      };
    case types.REQUEST_RESET:
      return {
        ...state,
        user: null,
        isLogin: false,
        error: null,
        status: 'init',
      };

    case types.API_NO_SESSION:
      return {
        ...state,
        user: null,
        isLogin: false,
        status: 'init',
      };

    case types.API_ERROR:
      const nextErr = action.error;
      return {
        ...state,
        status: 'error',
        error: nextErr,
      };

    default:
      return state;
  }
};
