import { KeywordLink } from 'components/atoms/KeywordLink';
import { UserThumbnail } from 'components/atoms/UserThumbnail';
import React from 'react';
import { BsThreeDots as ThreeDots } from 'react-icons/bs';

import './style.css';

export interface PostingCommentProps {
  id: string;
  width?: string | number;
  Thumbnail?: JSX.Element | SVGElement;
  username?: string;
  nickname: string;
  comment: string;
  diffDay: number;
  isLoginUserComment?: boolean;
  handleClickMenu?: (id: string, isLoginUserComment: boolean) => void;
  handleClickUser?: () => void;
}

export const PostingComment: React.FC<PostingCommentProps> = ({
  id,
  width = '100%',
  Thumbnail,
  username,
  nickname,
  comment,
  diffDay,
  isLoginUserComment = false,
  handleClickMenu,
  handleClickUser,
}: PostingCommentProps) => {
  const cn = 'posting-comment';
  const diffDayStr = diffDay > 0 ? diffDay + '日前' : '今日';

  const onClickMenu = () => {
    handleClickMenu && handleClickMenu(id, isLoginUserComment);
  };

  const userLink = username || '';
  return (
    <div className={cn} style={{ width }}>
      <div className={`${cn}__thumbnail`}>
        <UserThumbnail
          Thumbnail={Thumbnail}
          handleClickUser={handleClickUser}
        />
      </div>
      <div className={`${cn}__body`}>
        <div>
          <p className={`${cn}__name`}>
            <KeywordLink
              text={nickname}
              path={`/${userLink}`}
              variant="black"
            />
          </p>
          <p className={`${cn}__comment`}>{comment}</p>
        </div>
        <p className={`${cn}__posted-diff-day`}>{diffDayStr}</p>
        {/* {isLoginUserComment?<p onClick={onClickDeleteComment} className={`${cn}__delete`}>削除</p>:null} */}
      </div>
      <div className={`${cn}__menu`}>
        <ThreeDots className={`${cn}__menu-bar`} onClick={onClickMenu} />
      </div>
    </div>
  );
};
