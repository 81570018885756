import { types } from './actionTypes';
import { ActionTypes, actionWithAuth } from './action';
import {
  ContentApiFactory,
  Configuration,
  isHTTPNotFoundError,
} from 'services/api';
import { getAuthConfigurations } from './auth';

export interface LikeActionTypes {
  type: typeof types.API_LIKE_CONTENT | typeof types.API_UNLIKE_CONTENT;
}

export interface AddFavoriteActionTypes {
  type: typeof types.API_ADD_FAVORITE;
}

export interface TakeFavoriteActionTypes {
  type: typeof types.API_TAKE_FAVORITE;
}

export interface CheckFavoriteActionTypes {
  type: typeof types.API_CHECK_FAVORITE;
  isFavorite: boolean;
}

export type FavoriteActionTypes =
  | ActionTypes
  | AddFavoriteActionTypes
  | TakeFavoriteActionTypes
  | CheckFavoriteActionTypes;

export const checkLikeContent = async (
  contentId: string
): Promise<LikeActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    try {
      const res = await ContentApiFactory(
        configurations as Configuration
      ).checkLikeContent(contentId);
      return { type: types.API_LIKE_CONTENT };
    } catch (err: any) {
      if (isHTTPNotFoundError(err)) {
        return { type: types.API_UNLIKE_CONTENT };
      }
      // actionWithAuthでcatchする
      throw err;
    }
  };
  return actionWithAuth<LikeActionTypes>(actionCallBack);
};

export const likeContent = async (
  contentId: string
): Promise<LikeActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const res = await ContentApiFactory(
      configurations as Configuration
    ).likeContent(contentId);
    return { type: types.API_LIKE_CONTENT };
  };
  return actionWithAuth<LikeActionTypes>(actionCallBack);
};

export const unlikeContent = async (
  contentId: string
): Promise<LikeActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const res = await ContentApiFactory(
      configurations as Configuration
    ).unlikeContent(contentId);
    return { type: types.API_UNLIKE_CONTENT };
  };
  return actionWithAuth<LikeActionTypes>(actionCallBack);
};

export const checkFavorite = async (
  contentId: string
): Promise<FavoriteActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    try {
      const res = await ContentApiFactory(
        configurations as Configuration
      ).checkFavoriteContent(contentId);
      return { type: types.API_CHECK_FAVORITE, isFavorite: true };
    } catch (err: any) {
      if (isHTTPNotFoundError(err)) {
        return { type: types.API_CHECK_FAVORITE, isFavorite: false };
      }
      // actionWithAuthでcatchする
      throw err;
    }
  };
  return actionWithAuth<FavoriteActionTypes>(actionCallBack);
};

export const addFavorite = async (
  contentId: string
): Promise<FavoriteActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const res = await ContentApiFactory(
      configurations as Configuration
    ).addFavorite(contentId);
    return { type: types.API_ADD_FAVORITE };
  };
  return actionWithAuth<FavoriteActionTypes>(actionCallBack);
};

export const takeFavorite = async (
  contentId: string
): Promise<FavoriteActionTypes> => {
  const actionCallBack = async (configurations: Configuration) => {
    const res = await ContentApiFactory(
      configurations as Configuration
    ).takeFavorite(contentId);
    return { type: types.API_TAKE_FAVORITE };
  };
  return actionWithAuth<FavoriteActionTypes>(actionCallBack);
};
