import React from 'react';
import { KeywordLink } from 'components/atoms/KeywordLink';

import './style.css';

export interface TagsProps {
  title: string;
  tags: {
    text: string;
    path: string;
  }[];
  isReactRouter?: boolean;
}

export const Tags: React.FC<TagsProps> = ({
  title,
  tags,
  isReactRouter = false,
}: TagsProps) => {
  const cn = 'tags';
  const items = tags.map((tag, i) => (
    <li key={i} className={`${cn}__item`}>
      <KeywordLink {...tag} isReactRouter={isReactRouter} />
    </li>
  ));
  return (
    <div className={cn}>
      <h3 className={`${cn}__title`}>{title}</h3>
      <ul className={`${cn}__list`}>{items}</ul>
    </div>
  );
};
