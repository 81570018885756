import React from 'react';
import { IoPersonCircleOutline as PersonCircle } from 'react-icons/io5';

import './style.css';

export interface UserThumbnailProps {
  width?: string | number;
  Thumbnail?: JSX.Element | SVGElement;
  handleClickUser?: () => void;
}

export const UserThumbnail: React.FC<UserThumbnailProps> = ({
  width = '100%',
  Thumbnail,
  handleClickUser,
}: UserThumbnailProps) => {
  const cn = 'user-thumbnail';
  return (
    <div
      className={cn}
      style={{ width }}
      onClick={() => {
        handleClickUser && handleClickUser();
      }}
    >
      {Thumbnail || <PersonCircle className={`${cn}__default`} />}
    </div>
  );
};
