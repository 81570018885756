import React, { useEffect } from 'react';
import { useAlert } from 'components/custom/use-alert';
import { ApiError } from 'models/ApiError';

export const useErrorMessage = (error?: ApiError | null) => {
  const { displayErrorAlert } = useAlert();

  // if api error, show Error Alert
  useEffect(() => {
    (async () => {
      if (error != null) {
        await displayErrorAlert(error);
        return;
      }
    })();
  }, [error]);
};
