import axios,{ AxiosError } from 'axios';

export type HTTPError = AxiosError
export interface HTTPErrorResponse {
  code: string;
  message: string;
}

export const isHTTPError = (err: unknown): err is HTTPError => {
  return axios.isAxiosError(err)
}

export const isHTTPErrorResponse = (arg: any): arg is HTTPErrorResponse => {
  return (
    arg !== null &&
    typeof arg === 'object' &&
    typeof arg.code === 'string' &&
    typeof arg.message === 'string'
  );
};

export const isHTTPNotFoundError = (err: unknown):boolean => {
  if (!isHTTPError(err)) {
    return false
  }
  const httpStatus = err?.response?.status;
  return httpStatus === 404
}