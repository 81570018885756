import React from 'react';
import { Button } from '../../atoms/Button';
import { Spinner, SpinnerProps } from 'react-bootstrap';

import './style.css';

export interface LoadingButtonProps {
  text: string;
  variant?: string;
}

export const LoadingButton: React.FC<LoadingButtonProps> = ({
  text,
  variant = 'main',
}: LoadingButtonProps) => {
  const cn = 'loading-button';
  return (
    <Button variant={variant}>
      <span className={cn}>
        <span className={`${cn}__icon`}>
          <Spinner
            size="sm"
            as="span"
            animation="grow"
            role="status"
            aria-hidden="true"
          />
        </span>
        <span className={`${cn}__text`}>{text}</span>
      </span>
    </Button>
  );
};
