import React from 'react';
import { MenuModal } from 'components/molecules/MenuModal';
import { KeywordLink } from 'components/atoms/KeywordLink';

import './style.css';

export interface PostMenuModalProps {
  isShow?: boolean;
  canEdit?: boolean;
  isLogin?: boolean;
  handleEditContent?: () => void;
  handleDelete?: () => void;
  handleCopyLink?: () => void;
  handleReport?: () => void;
  handleCloseMenu?: () => void;
}

export const PostMenuModal: React.FC<PostMenuModalProps> = ({
  isShow = false,
  canEdit = false,
  isLogin = false,
  handleEditContent,
  handleDelete,
  handleCopyLink,
  handleReport,
  handleCloseMenu,
}: PostMenuModalProps) => {
  const cn = 'post-menu-modal';
  return (
    <>
      {canEdit ? (
        <MenuModal isShow={isShow} onHide={handleCloseMenu}>
          <p
            className={`${cn}__item`}
            onClick={() => handleEditContent && handleEditContent()}
          >
            投稿を編集
          </p>
          <p
            className={`${cn}__item`}
            onClick={() => handleCopyLink && handleCopyLink()}
          >
            リンクをコピー
          </p>
          <p
            className={`${cn}__item-danger`}
            onClick={() => handleDelete && handleDelete()}
          >
            削除
          </p>
          <p
            className={`${cn}__item-cancel`}
            onClick={() => handleCloseMenu && handleCloseMenu()}
          >
            キャンセル
          </p>
        </MenuModal>
      ) : (
        <MenuModal isShow={isShow} onHide={handleCloseMenu}>
          <p
            className={`${cn}__item`}
            onClick={() => handleCopyLink && handleCopyLink()}
          >
            リンクをコピー
          </p>
          <p
            className={`${cn}__item-danger`}
            onClick={() => handleReport && handleReport()}
          >
            不適切な投稿を報告
          </p>
          <p
            className={`${cn}__item-cancel`}
            onClick={() => handleCloseMenu && handleCloseMenu()}
          >
            キャンセル
          </p>
        </MenuModal>
      )}
    </>
  );
};
