import React from 'react';
import { Loader } from 'components/atoms/Loader';
import { Button } from 'components/atoms/Button';

import './style.css';

export interface InfinityPostListProps {
  status?: string;
  fetchMore: () => void;
  hasMore: boolean;
  PostList: JSX.Element;
}

export const InfinityPostList: React.FC<InfinityPostListProps> = ({
  status,
  fetchMore,
  hasMore,
  PostList,
}: InfinityPostListProps) => {
  const cn = 'infinity-post-list';

  const MoreButton =
    status == 'loading' ? (
      <Loader />
    ) : hasMore ? (
      <Button
        variant="outline-main"
        onClick={() => {
          fetchMore();
        }}
      >
        もっと見る
      </Button>
    ) : null;

  return (
    <div className={cn}>
      {PostList}
      <div className={`${cn}-btn`}>{MoreButton}</div>
    </div>
  );
};
