import React, { useState, useEffect } from 'react';
import { AiFillCamera as CameraFill } from 'react-icons/ai';
import { Form } from 'react-bootstrap';
import { useImageUpload } from 'components/custom/use-image-upload';
import { useAlert } from 'components/custom/use-alert';

import './style.css';

export interface FileUploadProps {
  height?: string | number;
  url?: string;
  name?: string;
  handleChange?: (url?: string, file?: File) => void;
}

export const FileUpload: React.FC<FileUploadProps> = ({
  height = 300,
  url,
  name = 'file',
  handleChange,
}: FileUploadProps) => {
  const [state, setState] = useState<string | undefined>(url);
  const [fileState, setFileState] = useState<string>('');
  const { upload } = useImageUpload();
  const { displayErrorAlert } = useAlert();

  // アップロード画像(バイナリ)を管理するStateを更新
  const updateImage = async (file: File) => {
    const res = await upload(file);
    if (res.error != null) {
      await displayErrorAlert(res.error);
      return;
    }
    handleChange && res.image && handleChange(res.image.url, res.image.file);
    {
      /* setState(res.url) */
    }
  };

  const cn = 'file-upload';
  // アップロード前後でコンテンツを切り替える
  let displayComp = null;
  if (state) {
    // アップロード済 → 表示範囲内に画像を表示
    displayComp = (
      <img src={state} className={`${cn}__img`} alt="アップロード画像" />
    );
  } else {
    // アップロード前 → アップロード可能である旨を伝えるコンテンツを表示
    displayComp = (
      <>
        <CameraFill className={`${cn}__icon`} />
        <span className={`${cn}__text`}>
          ドラッグアンドドロップ
          <br />
          またはクリックしてファイルをアップロード
        </span>
      </>
    );
  }

  useEffect(() => {
    setState(url);
  }, [url]);

  return (
    <label
      className={cn}
      style={{ height }}
      onDragOver={(e) => {
        e.preventDefault();
      }}
      onDrop={(e) => {
        e.preventDefault();
        updateImage(e.dataTransfer.files[0]);
        setFileState('');
      }}
    >
      {displayComp}
      {/* クリック経由でのアップロードを管理するコンポーネント */}
      <Form.File
        className={`${cn}__file`}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const fileList = e.target.files;
          if (!fileList || fileList.length == 0) return;
          updateImage(fileList[0]);
          setFileState('');
        }}
        value={fileState}
      />
      {/* ドラッグアンドドロップ経由でのアップロードを管理するコンポーネント */}
      <Form.Control
        required
        type="text"
        name={name}
        className={`${cn}__file`}
        defaultValue={state}
      />
      <Form.Control.Feedback type="invalid">
        ファイルをアップロードして下さい
      </Form.Control.Feedback>
    </label>
  );
};
