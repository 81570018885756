import React, { useState, useReducer, useEffect } from 'react';
import reducer, { CommentsState } from 'modules/reducers/comments';
import { types } from 'modules/actions/actionTypes';
import {
  getCommentsByContent,
  createComment as _createComment,
  deleteComment as _deleteComment,
  CommentsTypes,
  RequestCommentTypes,
} from 'modules/actions/comments';

import {
  resetRequest as _resetRequest,
  startRequest as _startRequest,
} from 'modules/actions/action';

export const useComments = () => {
  const [contentIdState, setContentIdState] = useState<string | null>(null);
  const [commentsState, dispatch] = useReducer(reducer, {
    comments: [] as CommentsTypes,
    status: 'completed',
  } as CommentsState);

  const fetch = async (contentId: string) => {
    startRequest();
    const res = await getCommentsByContent(contentId);
    dispatch(res);
  };

  /* const fetchMore=async (contentId: string) => { */
  /*   startRequest() */
  /*   const res = await getCommentsByContent(contentId || contentIdState) */
  /*   res.type = types.API_FETCH_MORE_SUCCESS */
  /*   dispatch(res) */
  /* } */

  //createComment
  const createComment = async (
    commentId: string,
    comment: RequestCommentTypes
  ) => {
    startRequest();
    const res = await _createComment(commentId, comment);
    dispatch(res);
  };

  //deleteComment
  const deleteComment = async (commentId: string) => {
    startRequest();
    const res = await _deleteComment(commentId);
    dispatch(res);
  };

  const startRequest = () => {
    dispatch(_startRequest());
  };

  const reset = () => {
    dispatch({ type: types.REQUEST_RESET });
  };

  useEffect(() => {
    (async () => {
      reset();
      if (contentIdState != null) {
        fetch(contentIdState);
      }
    })();
  }, [contentIdState]);

  return {
    commentsState,
    fetch,
    createComment,
    deleteComment,
    reset: reset,
  };
};
