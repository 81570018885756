import React from 'react';
import { Card } from 'react-bootstrap';
import { KeywordList } from 'components/molecules/KeywordList';
import { IconCounter } from 'components/atoms/IconCounter';
import { BsHeart as Heart, BsChat as Chat } from 'react-icons/bs';
import './style.css';

export interface PostCardProps {
  img: string;
  title: string;
  width?: string | number;
  keywords: string[];
  userName?: string;
  niceCount: number;
  commentsCount: number;
}

export const PostCard: React.FC<PostCardProps> = ({
  img,
  title,
  width = '100%',
  keywords,
  userName,
  niceCount,
  commentsCount,
}: PostCardProps) => {
  const cn = 'post-card';
  // KeyWordListコンポーネント配下の子要素を構築
  const KeyWordItems = keywords.slice(0, 4).map((text, i) => {
    return (
      <p key={i} className={`${cn}__item`}>
        {text}
      </p>
    );
  });
  return (
    <Card className={cn} style={{ width }}>
      <Card.Img className={`${cn}__img`} src={img} alt={title} />
      <Card.ImgOverlay className={`${cn}__body`}>
        <div className={`${cn}__tags`}>
          <div className={`${cn}__list`}>
            <p className={`${cn}__title`}>{title}</p>
            <KeywordList>{KeyWordItems}</KeywordList>
            {userName && <p className={`${cn}__user-name`}>by {userName}</p>}
          </div>
        </div>
        <ul className={`${cn}__icons`}>
          <li className={`${cn}__icon`}>
            <IconCounter Icon={Heart} val={niceCount} />
          </li>
          <li className={`${cn}__icon`}>
            <IconCounter Icon={Chat} val={commentsCount} />
          </li>
        </ul>
      </Card.ImgOverlay>
    </Card>
  );
};
