import React, { useState, useRef, useEffect } from 'react';
import { Form, Alert } from 'react-bootstrap';
import { RequestState } from 'modules/util/request-state';
import { Button } from 'components/atoms/Button';

import { RequestUserTypes } from 'modules/actions/authenticated-user';
import { UserThumbnail } from 'components/atoms/UserThumbnail';
import { useImageUpload } from 'components/custom/use-image-upload';

import { CropModal, ImageCropperProps } from 'components/molecules/CropModal';

import './style.css';

export interface SettingFormValues {
  file?: File;
  profileImageUrl?: string;
  userID?: string;
  name: string;
}

export interface SettingFormProps {
  width?: string | number;
  values: SettingFormValues;
  status?: 'init' | 'loading' | 'completed' | 'error';
  postNum?: number;
  submit?: (data: RequestUserTypes) => void;
  upload?: (file: File) => void;
}

interface State {
  values: SettingFormValues;
  cropImage: string;
  status: 'init' | 'loading' | 'completed' | 'error';
  modal: {
    isCropping: boolean;
  };
}

type Input = React.ChangeEvent<HTMLInputElement>;

export const SettingForm: React.FC<SettingFormProps> = ({
  width = '100%',
  values,
  status,
  postNum,
  submit,
  upload,
}: SettingFormProps) => {
  const hiddenFileInput = useRef<HTMLInputElement | null>(null);

  const func = (values: SettingFormValues) => {
    return values;
  };

  const [state, setState] = useState<State>({
    values: func(values),
    cropImage: '',
    status: 'init',
    modal: {
      isCropping: false,
    },
  });

  useEffect(() => {
    (async () => {
      setState({ ...state, values });
    })();
  }, []);

  // 同じ画像をアップしたとき、編集できるようにするため、初期化用
  const [fileState, setFileState] = useState<string>('');

  const { upload: _upload } = useImageUpload();

  const onClickSaveName = () => {
    const data = {
      nickname: state.values.name,
    };
    submit && submit(data);

    const values = { ...state.values };
    setState({ ...state, values, status: 'completed' });
  };

  const handleCropImage = (profileImageUrl: string, file: File) => {
    const values = { ...state.values, profileImageUrl, file };
    const modal = { ...state.modal, isCropping: false, status: 'completed' };
    setState({ ...state, values, modal });
    upload && upload(file);
  };

  const handleClickCancel = () => {
    const modal = { ...state.modal, isCropping: false };
    setState({ ...state, modal });
  };
  const imageCropperProps: ImageCropperProps = {
    image: state.cropImage,
    cropShape: 'round',
    size: 's',
    handleCropImage,
    handleClickCancel,
  };

  const onClickChangeImage = () => {
    if (!hiddenFileInput.current) throw new Error('Form要素のRef参照を確認');
    hiddenFileInput.current.click();
  };

  // アップロード画像(バイナリ)を管理するStateを更新
  const updateImage = async (file: File) => {
    const res = await _upload(file);
    if (res.error != null) {
      alert(res.error);
      return;
    }
    handleChangeImage && handleChangeImage(res.image?.url);
    {
      /* setState(res.url) */
    }
  };

  const handleChangeImage = (cropImage: string = '') => {
    const modal = { ...state.modal, isCropping: true };
    setState({ ...state, modal, cropImage });
  };

  const buildAlert = () => {
    switch (state.status) {
      case 'init':
        return null;
      case 'completed':
        return (
          <Alert variant="success" className={`${cn}__alert`}>
            保存しました。
          </Alert>
        );
    }
  };

  const Thumbnail = state.values.profileImageUrl ? (
    <img src={state.values.profileImageUrl} alt={'ユーザーサムネイル'} />
  ) : undefined;
  const cn = 'setting-form';

  return (
    <section className={cn}>
      <CropModal
        imageCropperProps={imageCropperProps}
        isShow={state.modal.isCropping}
        size={'s'}
      />
      <div className={cn} style={{ width }}>
        <div
          className={`${cn}__thumbnail-group`}
          onDrop={(e) => {
            e.preventDefault();
            updateImage(e.dataTransfer.files[0]);
            setFileState('');
          }}
          onClick={onClickChangeImage}
        >
          <div className={`${cn}__thumbnail`}>
            <UserThumbnail Thumbnail={Thumbnail} />
          </div>
          <div className={`${cn}__thumbnail-btn`}>変更する</div>
          <Form.File
            className={`${cn}__file`}
            ref={hiddenFileInput}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              const fileList = e.target.files;
              if (!fileList || fileList.length == 0) return;
              updateImage(fileList[0]);
              setFileState('');
            }}
            value={fileState}
          />
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
          }}
        >
          <Form.Group className={`${cn}__group`}>
            <Form.Label className={`${cn}__label`}>ニックネーム</Form.Label>
            <Form.Control
              type="text"
              name="nickname"
              placeholder="20文字まで"
              value={state.values.name}
              maxLength={20}
              onChange={(e: Input) => {
                const values = { ...state.values, name: e.target.value };
                setState({ ...state, values });
              }}
            />
          </Form.Group>

          <div className={`${cn}__btn`}>
            <Button onClick={onClickSaveName}>保存する</Button>
          </div>
        </Form>
      </div>
      {buildAlert()}
    </section>
  );
};
