import {
  Configuration,
  isHTTPError,
  isHTTPErrorResponse,
  HTTPErrorResponse,
} from 'services/api';
import { getAuthConfigurations } from './auth';
import { types } from './actionTypes';
import { ApiError, ErrorType } from 'models/ApiError';

interface ApiStart {
  type: typeof types.API_START;
}

interface ApiNoSession {
  type: typeof types.API_NO_SESSION;
  error?: ApiError;
}

interface RequestReset {
  type: typeof types.REQUEST_RESET;
}

interface ApiErrorAction {
  type: typeof types.API_ERROR;
  error?: ApiError;
}

export type ActionTypes =
  | ApiStart
  | ApiNoSession
  | ApiErrorAction
  | RequestReset;

export interface AbstractActionTypes {
  type: string;
  error?: ApiError;
  data?: any;
}

export const resetRequest = () => {
  return { type: types.REQUEST_RESET };
};

export const startRequest = () => {
  return { type: types.API_START };
};

const NETWORK_ERROR: ErrorType = {
  code: 'network_error',
  message: 'Network Error',
  level: 'error',
  displayMessageCode: 'api.errors.networkError',
};

const NO_SESSION: ErrorType = {
  code: 'no_session',
  message: 'No Session',
  displayMessageCode: 'api.errors.noSession',
  level: 'info',
};

const USERNAME_CONFLICT: ErrorType = {
  code: 'username_conflict',
  message: 'username conflict',
  displayMessageCode: 'api.errors.usernameConflict',
  level: 'info',
};

const NOT_FOUND: ErrorType = {
  code: 'not_found',
  message: 'not found',
  displayMessageCode: 'api.errors.notFound',
  level: 'info',
};

const INTERNAL_FOUND: ErrorType = {
  code: 'internal',
  message: 'internal',
  displayMessageCode: 'api.errors.internal',
  level: 'error',
};

const ILLEGAL_TYPE_ERROR: ErrorType = {
  code: 'illegal',
  message: 'Illegal',
  displayMessageCode: 'api.errors.illegal',
  level: 'fatal',
};

const buildApiErrorType = (response: HTTPErrorResponse) => {
  switch (response.code) {
    case 'username_conflict':
      return USERNAME_CONFLICT;
    case 'not_found':
      return NOT_FOUND;
    case 'internal':
      return INTERNAL_FOUND;
    default:
      return ILLEGAL_TYPE_ERROR;
  }
};

const buildIllegalError = (err: unknown): ApiError => {
  return new ApiError(ILLEGAL_TYPE_ERROR, err);
};

/**
 *
 * buildOtherApiError
 * example network error
 * @typeParam T - ActionTypes
 */
const buildOtherError = (err: Error): ApiError => {
  switch (err.message) {
    case 'Network Error':
      return new ApiError(NETWORK_ERROR, err);
    default:
      return buildIllegalError(err);
  }
};

/**
 *
 * BuildApiError build ApiError
 * @typeParam T - ActionTypes
 */
const buildApiError = (err: unknown): ApiError => {
  // is Axios
  if (isHTTPError(err)) {
    const response = err?.response?.data;
    const httpStatus = err?.response?.status;

    // check response data
    if (isHTTPErrorResponse(response) && httpStatus != null) {
      return new ApiError(buildApiErrorType(response), err);
    } else {
      return buildIllegalError(err);
    }
  } else if (err instanceof Error) {
    return buildOtherError(err);
  } else {
    return buildIllegalError(err);
  }
};

/**
 *
 * action
 * @typeParam T - ActionTypes
 */
export const action = async <T extends AbstractActionTypes>(
  actionCallBack: () => Promise<T>
): Promise<T> => {
  try {
    return await actionCallBack();
  } catch (err: unknown) {
    return { type: types.API_ERROR, error: buildApiError(err) } as T;
  }
};

/**
 *
 * actionWithAuth Action With Authentication
 * @typeParam T - ActionTypes
 */
export const actionWithAuth = async <T extends AbstractActionTypes>(
  actionCallBack: (configurations: Configuration) => Promise<T>
): Promise<T> => {
  const { configurations, error: authError } = await getAuthConfigurations();

  if (authError) {
    return {
      type: types.API_ERROR,
      error: new ApiError(NO_SESSION, authError),
    } as T;
  }

  try {
    return await actionCallBack(configurations);
  } catch (error: unknown) {
    return { type: types.API_ERROR, error: buildApiError(error) } as T;
  }
};
