import { usePageLink } from 'components/custom/use-page-link';
import { SearchModal2 } from 'components/molecules/SearchModal2';
import { Header } from 'components/organisms/Header';
import { UserContexts } from 'modules/contexts';
import React, { useContext, useState } from 'react';

export interface HeaderContainerProps {}

export const HeaderContainer: React.FC<HeaderContainerProps> =
  ({}: HeaderContainerProps) => {
    const { getUrl, pushPath } = usePageLink();
    const {
      userState: { user, isLogin },
      logout,
    } = useContext(UserContexts);

    const [isOpenModal, setIsOpenModal] = useState<boolean>(false);
    const handleOpen = () => {
      setIsOpenModal(true);
    };
    const handleClose = () => {
      setIsOpenModal(false);
    };

    const search = (value: string) => {
      //TODO  ページ遷移せず、検索できるようにしておきたい。
      window.location.href = getUrl(`search?query=${value}`);
    };

    const searchInputProps = {
      placeholder: '住所または場所名または座標または # キーワードで検索',
      ariaLabel: 'キーワード検索',
      search: search,
    };

    const handleLogin = () => {
      pushPath('enter');
    };

    const handleLogout = () => {
      logout();
    };

    const _isLogin = isLogin ? true : false;

    const Thumbnail = user?.profile_image_url ? (
      <img src={user.profile_image_url} alt={'ユーザーサムネイル'} />
    ) : undefined;
    const headerProps = {
      isLogin: _isLogin,
      handleLogin,
      handleLogout,
      Thumbnail,
      handleOpenSearch: handleOpen,
      pathname: window.location.pathname,
    };

    const searchModalProps = {
      searchFormProps: searchInputProps,
      isShow: isOpenModal,
      handleClose: handleClose,
    };

    return (
      <>
        <Header {...headerProps} />
        <SearchModal2 {...searchModalProps} />
      </>
    );
  };
