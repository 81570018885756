import React, { useState, useRef } from 'react';
import { UserThumbnail } from 'components/atoms/UserThumbnail';

import './style.css';

export interface UserProfileProps {
  width?: string | number;
  Thumbnail?: JSX.Element | SVGElement;
  name: string;
  postNum: number;
}

type Input = React.ChangeEvent<HTMLInputElement>;

export const UserProfile: React.FC<UserProfileProps> = ({
  width = '100%',
  Thumbnail,
  name,
  postNum,
}: UserProfileProps) => {
  const cn = 'user-profile';

  return (
    <div className={cn} style={{ width }}>
      <div className={`${cn}__thumbnail`}>
        <UserThumbnail Thumbnail={Thumbnail} />
      </div>
      <div className={`${cn}__body`}>
        <p className={`${cn}__name`}>{name}</p>
        <p className={`${cn}__post-num`}>投稿 {postNum}件</p>
      </div>
    </div>
  );
};
