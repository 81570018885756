import React, { useState, useReducer, useEffect, useContext } from 'react';
import { SettingPage as TemplateSettingPage } from 'components/Templates/SettingPage';
import { UserTypes } from 'modules/actions/authenticated-user';
import contentsReducer from 'modules/reducers/contents';
import { UserContexts } from 'modules/contexts';
import { useAlert } from 'components/custom/use-alert';
import { usePageLink } from 'components/custom/use-page-link';
import { useAuthenticatedUser } from 'components/api-hooks/use-authenticated-user';

export const SettingPage: React.FC = () => {
  const { getUrl, pushEnter } = usePageLink();
  const { displayErrorAlert } = useAlert();
  const {
    userState: { isLogin, user },
    setUser,
  } = useContext(UserContexts);
  const {
    userState: { user: localUser, status, error },
    updateUser,
    uploadUserImage,
  } = useAuthenticatedUser();

  const [activeIndex, setActiveIndex] = useState<number>(0);
  const displaySwithingMethods = { activeIndex, handleClick: setActiveIndex };

  // if api error, show Error Alert
  useEffect(() => {
    (async () => {
      if (error != null) {
        await displayErrorAlert(error);
        {
          /* resetRequest() */
        }
        return;
      }
    })();
  }, [error]);

  useEffect(() => {
    (async () => {
      if (status == 'completed' && localUser != null) {
        setUser(localUser);
      }
      return;
    })();
  }, [status]);

  const upload = async (file: File) => {
    uploadUserImage(file);
  };

  if (!isLogin) {
    pushEnter('/setting');
  }

  if (user == null) {
    return <></>;
  }

  const { nickname = '名無し', profile_image_url = '' } = user as UserTypes;
  const values = { name: nickname, profileImageUrl: profile_image_url };

  const profileSettingFormProps = {
    submit: updateUser,
    values,
    status,
    upload,
  };

  return (
    <TemplateSettingPage
      displaySwithingMethods={displaySwithingMethods}
      profileSettingFormProps={profileSettingFormProps}
    />
  );
};
